import { is_mobile, reset_scroll } from './../app/controller.js'
import { build as nodes_build } from './../components/nodes.js'
import { nodes as nodes } from './../components/nodes.js'
import * as utils from './../components/utils.js'
import * as world from './world.js'
import * as overlay from './overlay.js'

import { toggle_blendmode, prep_page_header } from './../app/controller.js'


export let build = () => {

	// COLLECT nodes within template
	nodes_build( 'projects' )

	// toggle_blendmode( true )

	prep_page_header()

	if ( !is_mobile ) {

		nodes.projects.section.addEventListener( 'mousemove', mousemove_handler, { passive: false } )
	
	}
	else {

		nodes.projects.section.addEventListener( 'touchmove', touchmove_handler, { passive: false } )
		nodes.projects.section.addEventListener( 'touchstart', touchmove_handler, { passive: false } )
	
	}

	overlay.set_particle_colors()

	world.set_video_bounds( 'home' )

}


let touchmove_handler = ( e ) => {

	if ( e.touches[ 0 ] && e.touches[ 0 ].target ) {
		parse_helper( e.touches[ 0 ].target )
	}

}


let mousemove_handler = ( e ) => {

	parse_helper( e.composedPath()[ 0 ] )

}


let parse_helper = ( target ) => {

	let el = utils.walking_class_test( target, 'project-module' )

	if ( el ) {

		world.set_video_bounds( el.dataset['slug'] )
		clear_hovers()
		el.classList.add('hover')

	}
	else {

		world.set_video_bounds( 'home' )
		clear_hovers()
		
	}

}

let clear_hovers = () => {

	nodes.projects.modules.forEach( el => {
		el.classList.remove( 'hover' )
	})

}