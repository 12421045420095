import { gsap, CustomEase, SteppedEase, Power0, Power1, Power2, Power3 } from 'gsap'

import { Vec2 } from 'ogl'

import {
	state,
	reset_scroll,
	finalize_page_header,
	sync_heights,
	clip_heights,
	toggle_blendmode
} from './../app/controller.js'

import * as utils from './utils.js'
import { finalize_page_header,sync_heights } from './../app/controller.js'
import { nodes } from './nodes.js'
import * as lazyload from './lazyload.js'
import * as navigation from './navigation.js'
import * as world from './../sections/world.js' 
import * as overlay from './../sections/overlay.js'
import { close_modal } from './image-modal.js'

// - - - Default & Custom transitions between pages - - - //

export let eases = {
	zip_out: CustomEase.create("custom", "M0,0 C0.3,0 0.3,1 1,1"),
	zip_in: CustomEase.create("custom", "M0,0,C0.4,0,0.2,1,1,1"),
	zip_in_hard: CustomEase.create("custom", "M0,0,C0.8,0,0.7,1,1,1"),
	slide_in: CustomEase.create("custom", "M0,0,C0.3,0.6,0.4,1,1,1"),
	slide_out: CustomEase.create("custom", "M0,0,C0.3,0,0.6,0.2,1,1"),
	snap_12: SteppedEase.config( 12 ),
	snap_8: SteppedEase.config( 8 ),
	snap_6: SteppedEase.config( 6 ),
	snap_5: SteppedEase.config( 5 ),
	snap_4: SteppedEase.config( 4 ),
	snap_3: SteppedEase.config( 3 ),
	snap_2: SteppedEase.config( 2 ),
	snap: SteppedEase.config( 0 )
}

let config = {
	t: 333,
	views: [ null, 'home', 'projects', 'project' ]
}

export let status = {
	transitioning: false,
	active_tl: null
}


let stitch_scroll = () => {

	reset_scroll()

	sync_heights( true )

	// clip_heights()

}


let shapes_helper_backward = ( tl, d ) => {

	// - - - SHAPES OVERLAYS
	d = d ? d : 0
	let t = 6 / 60
	let svg_shapes = overlay.create_transition_nodes_A()

	// LINES
	svg_shapes.lines.forEach( ( line, i ) => {
		let x = Math.round( Math.random() * 40 )
		let y = 100 - x
		gsap.set( line.node, { drawSVG: `${ y }% ${ y }%` } )
		tl.add( gsap.to( line.node, t * 3, { drawSVG: `${ y }% ${ x }%`, ease: eases.zip_out } ), t*0 + t*(i/svg_shapes.lines.length)*10 + d)
		tl.add( gsap.to( line.node, t * 3, { drawSVG: `${ x }% ${ x }%`, ease: eases.zip_in } ), t*1.5 + t*(i/svg_shapes.lines.length)*10 + d)
	} )

	// SQUARE SETS OF 3
	svg_shapes.squares.forEach( ( square_set, i ) => {

		// top left => 0, center => 1, bottom right => 0
		let corner_damp = 1 - ( Math.abs( ( new Vec2( square_set[ 0 ].x, square_set[ 0 ].y ).len() ) / state.window_size.d - 0.5 ) * 2 )
		let x = Math.round( ( Math.random() * state.window_size.d * 0.1 + state.window_size.d * 0.2 ) * corner_damp )

		// SQUARE
		square_set.forEach( ( s, j ) => {

			gsap.set( s.node, { scale: 0, transformOrigin: "50% 50%" } )

			tl.add( gsap.fromTo( 
				s.node, 
				t * 10, 
				{ x: `+=${ x * ( j + 1 ) * ( 0.2 * ( j + 1 ) ) }`, y: `-=${ x * ( j + 1 ) * ( 0.2 * ( j + 1 ) ) }` },
				{ x: `-=${ x * ( j + 1 ) * ( 0.4 * ( j + 1 ) ) }`, y: `+=${ x * ( j + 1 ) * ( 0.4 * ( j + 1 ) ) }`, ease: eases.zip_out } ),
				t*0 + t*(i)*(1/svg_shapes.squares.length)*5 + t*(j)*0.25 + d
			)

			tl.add( gsap.to( s.node, t * 5, { scale: 1, transformOrigin: "50% 50%", ease: eases.zip_out } ), t*0 + t*(i)*(1/svg_shapes.squares.length)*5 + t*(j)*0.25 + d )
			tl.add( gsap.to( s.node, t * 5, { scale: 0, transformOrigin: "50% 50%", ease: eases.zip_in } ), t*5 + t*(i)*(1/svg_shapes.squares.length)*5 + t*(j)*0.25 + d )
		} )

	} )

}

let shapes_helper_forward = ( tl, d ) => {

	// - - - SHAPES OVERLAYS
	d = d ? d : 0
	let t = 6 / 60
	let svg_shapes = overlay.create_transition_nodes_A()

	// LINES
	svg_shapes.lines.forEach( ( line, i ) => {
		let x = Math.round( Math.random() * 40 )
		let y = 100 - x
		gsap.set( line.node, { drawSVG: `${ x }% ${ x }%` } )
		tl.add( gsap.to( line.node, t * 3, { drawSVG: `${ x }% ${ y }%`, ease: eases.zip_out } ), t*0 + t*(i/svg_shapes.lines.length)*10 + d)
		tl.add( gsap.to( line.node, t * 3, { drawSVG: `${ y }% ${ y }%`, ease: eases.zip_in } ), t*1.5 + t*(i/svg_shapes.lines.length)*10 + d)
	} )

	// SQUARE SETS OF 3
	svg_shapes.squares.forEach( ( square_set, i ) => {

		// top left => 0, center => 1, bottom right => 0
		let corner_damp = 1 - ( Math.abs( ( new Vec2( square_set[ 0 ].x, square_set[ 0 ].y ).len() ) / state.window_size.d - 0.5 ) * 2 )
		let x = Math.round( ( Math.random() * state.window_size.d * 0.1 + state.window_size.d * 0.2 ) * corner_damp )

		// SQUARE
		square_set.forEach( ( s, j ) => {

			gsap.set( s.node, { scale: 0, transformOrigin: "50% 50%" } )

			tl.add( gsap.fromTo( 
				s.node, 
				t * 10, 
				{ x: `-=${ x * ( j + 1 ) * ( 0.2 * ( j + 1 ) ) }`, y: `+=${ x * ( j + 1 ) * ( 0.2 * ( j + 1 ) ) }` },
				{ x: `+=${ x * ( j + 1 ) * ( 0.4 * ( j + 1 ) ) }`, y: `-=${ x * ( j + 1 ) * ( 0.4 * ( j + 1 ) ) }`, ease: eases.zip_out } ),
				t*0 + t*(i)*(1/svg_shapes.squares.length)*5 + t*(j)*0.25 + d
			)

			tl.add( gsap.to( s.node, t * 5, { scale: 1, transformOrigin: "50% 50%", ease: eases.zip_out } ), t*0 + t*(i)*(1/svg_shapes.squares.length)*5 + t*(j)*0.25 + d )
			tl.add( gsap.to( s.node, t * 5, { scale: 0, transformOrigin: "50% 50%", ease: eases.zip_in } ), t*5 + t*(i)*(1/svg_shapes.squares.length)*5 + t*(j)*0.25 + d )
		} )

	} )


}






let transitions = {


	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  META

	map: ( _f, _t ) => {

		let f = ( _f ) ? _f.split( '/' )[ 0 ] : null
		let t = _t.split( '/' )[ 0 ]
		let action = `from_${ f }_to_${ t }`
		if ( transitions[ action ] !== undefined ) {
			return action
		}
		else {
			// console.error( 'cant find transition: "' + action + '". Using default' )
			return 'default'
		}
	},

	end_early: () => {
		status.active_tl.progress( 1 ).pause()
	},

	end: () => {
		status.active_tl.invalidate()
		status.active_tl.clear()
		status.active_tl.kill()
		status.active_tl = null
		status.transitioning = false
		nodes.blend_mode_clip.style.removeProperty('pointer-events')
		sync_heights()
	},

	start: () => {
		nodes.blend_mode_clip.style.pointerEvents = 'none'
		status.active_tl.play( 0 )
	},

	default: (_parent, _old, _new, callback, delay) => {

		console.log( 'default transition' )

		// Animate out
		_new.classList.remove('animate-in')
		if ( _old !== null ) _old.remove()
		lazyload.update()
		navigation.links_add()
		finalize_page_header()

		if ( callback ) callback()
	},

	



	// - - - - - - - - - - - - - - - - - - - - - - - - - - - ANIMATE OUT AND IN


	// literally a timeline for each possible page navigation. including ones
	// there are not buttons for. wow. oh well. next time build like a state
	// machine and lag the values ? a la touchdesigner ;)


	from_null_to_home: (_parent, _old, _new, callback, delay) => {
		
		// delay = delay ? delay : 0

		if ( status.transitioning && status.active_tl ) {
			transitions.end_early()
		}

		status.transitioning = true
		stitch_scroll()

		let t = 0.5

		let tl = gsap.timeline({
			paused: true,
			onStart: () => {
				
			},
			onComplete: () => {
				if ( _old ) _old.remove()
				sync_heights()
				lazyload.update()
				navigation.links_add()
				finalize_page_header()
				if ( callback ) callback()
					
				transitions.end()

			}
		} )

		let offset = utils.remap( state.window_size.w, 720.0, 1920.0, -15.0, -50, true )


		world.uniforms.background.u_homepage.value = 1.0
		world.uniforms.background.u_projects.value = 0.0
		world.uniforms.background.u_project.value = 0.0
		world.uniforms.background.u_curtain.value = 0.0
		toggle_blendmode( true )

		tl.add( gsap.fromTo( 
			nodes.home.page_blakerutledge, 
			t, 
			{ opacity: 0, y: -offset, x: offset }, 
			{ opacity: 1, y: 0, x: 0, ease: eases.zip_in }
		), t * 0.125 )

		tl.add( gsap.fromTo( 
			nodes.home.page_header, 
			t,
			{ opacity: 0, y: -offset, x: offset }, 
			{ opacity: 1, y: 0, x: 0, ease: eases.zip_in, clearProps: 'all' }
		), t * 0.25 )

		tl.add( gsap.fromTo( 
			nodes.home.header, 
			t,
			{ opacity: 0, y: -offset, x: offset }, 
			{ opacity: 1, y: 0, x: 0, ease: eases.zip_in, clearProps: 'all' }
		), t * 0.5 )

		// tl.add( gsap.fromTo( 
		// 	nodes.home.blurb, 
		// 	t,
		// 	{ opacity: 0, y: -offset, x: offset }, 
		// 	{ opacity: 1, y: 0, x: 0, ease: eases.zip_in, clearProps: 'all' }
		// ), t * 0.5 )
		
		tl.add( gsap.fromTo( 
			nodes.home.projects_link, 
			t,
			{ opacity: 0, y: -offset, x: offset }, 
			{ opacity: 1, y: 0, x: 0, ease: eases.zip_in, clearProps: 'all' }
		), t * 1.125 )

		tl.add( gsap.fromTo( 
			nodes.footer, 
			t,
			{ opacity: 0 }, 
			{ opacity: 1, clearProps: 'all' }
		), t * 1.25 )

		tl.add( gsap.fromTo( 
			world.uniforms.background.u_border_tween, 
			t * 3.0, 
			{ value: 0 }, 
			{ value: 1, ease: CustomEase.create("custom", "M0,0,C0.4,0,0,1,1,1") }
		), t * 0.5 )

		shapes_helper_forward( tl )

		_new.classList.remove('animate-in')

		status.active_tl = tl
		
		tl.seek( 0 )
		setTimeout( transitions.start, delay )

	},

	from_null_to_projects: (_parent, _old, _new, callback, delay) => {

		if ( status.transitioning && status.active_tl ) {
			transitions.end_early()
		}

		status.transitioning = true
		stitch_scroll()

		let t = 6 / 60

		world.uniforms.background.u_homepage.value = 0.0
		world.uniforms.background.u_projects.value = 1.0
		world.uniforms.background.u_project.value = 0.0
		world.uniforms.background.u_curtain.value = 0.0
		toggle_blendmode( true )

		let tl = gsap.timeline({
			paused: true,
			onStart: () => {
				
			},
			onComplete: () => {
				if ( _old ) _old.remove()
				sync_heights()
				lazyload.update()
				navigation.links_add()
				finalize_page_header()
				if ( callback ) callback()
					
				transitions.end()

			}
		} )

		let offset = utils.remap( state.window_size.w, 720.0, 1920.0, -15.0, -50, true )


		// tl.add( gsap.from( new_page_header, t * 3, { x: -20, y: 20, opacity: 0, ease: eases.zip_out } ), t * 7 )

		tl.add( gsap.fromTo( 
			nodes.home.page_blakerutledge, 
			t * 6.0, 
			{ opacity: 0, y: -offset * 0.4, x: offset * 0.4 }, 
			{ opacity: 1, y: 0, x: 0, ease: eases.zip_in }
		), t * 5.0 )

		tl.add( gsap.fromTo( 
			nodes.home.page_header, 
			t * 6.0,
			{ opacity: 0, y: -offset * 0.5, x: offset * 0.5 }, 
			{ opacity: 1, y: 0, x: 0, ease: eases.zip_in, clearProps: 'all' }
		), t * 6.0 )


		tl.add( gsap.fromTo(
			nodes.projects.modules,
			t * 8.0,
			{ y: -offset, x: offset },
			{ y: 0, x: 0, stagger: t * 0.28, ease: eases.slide_in, clearProps: 'all' }
		), t * 6.0 )

		tl.add( gsap.fromTo(
			nodes.projects.modules,
			t * 6.0,
			{ scaleY: 0.0 },
			{ scaleY: 1.0, transformOrigin: "50% 100%", stagger: t * 0.28, ease: eases.zip_in, clearProps: 'all' }
		), t * 6.0 )

		tl.add( gsap.fromTo(
			nodes.projects.names,
			t * 4.0,
			{ opacity: 0.0 },
			{ opacity: 1.0, stagger: t * 0.18, ease: eases.slide_in, clearProps: 'all' }
		), t * 11.0 )

		tl.add( gsap.fromTo(
			nodes.projects.descs,
			t * 4.0,
			{ opacity: 0.0 },
			{ opacity: 1.0, stagger: t * 0.11, ease: eases.slide_in, clearProps: 'all' }
		), t * 12.0 )

		tl.add( gsap.fromTo( 
			nodes.footer, 
			t * 5.0,
			{ opacity: 0 }, 
			{ opacity: 1, clearProps: 'all' }
		), t * 6.0 + t * nodes.projects.modules.length * 0.28 * t + t * 6.0 )


		tl.add( gsap.fromTo( 
			world.uniforms.background.u_border_tween, 
			t * 10.0, 
			{ value: 0 }, 
			{ value: 1, ease: CustomEase.create("custom", "M0,0,C0.4,0,0,1,1,1") }
		), t * 6.0 )


		shapes_helper_forward( tl )

		_new.classList.remove('animate-in')

		status.active_tl = tl
		
		tl.seek( 0 )
		setTimeout( transitions.start, delay )

	},

	from_null_to_project: (_parent, _old, _new, callback, delay) => {

		if ( status.transitioning && status.active_tl ) {
			transitions.end_early()
		}

		status.transitioning = true
		stitch_scroll()

		let t = 6 / 60

		world.uniforms.background.u_homepage.value = 0.0
		world.uniforms.background.u_projects.value = 0.0
		world.uniforms.background.u_project.value = 1.0
		world.uniforms.background.u_curtain.value = 1.0
		toggle_blendmode( false )

		let tl = gsap.timeline({
			paused: true,
			onStart: () => {
				// tl.seek( 0 )
				// setTimeout( transitions.start, delay )
			},
			onComplete: () => {
				if ( _old ) _old.remove()
				lazyload.update()
				navigation.links_add()
				finalize_page_header()
				if ( callback ) callback()
					
				transitions.end()
			}
		} )

		let offset = utils.remap( state.window_size.w, 720.0, 1920.0, -10.0, -30, true )

		// shapes_helper_forward( tl )

		tl.add( gsap.fromTo( 
			nodes.home.page_blakerutledge, 
			t * 4.0, 
			{ opacity: 0, y: -offset * 0.5, x: offset * 0.5 }, 
			{ opacity: 1, y: 0, x: 0, ease: eases.zip_in }
		), t * 4.0 )

		tl.add( gsap.fromTo( 
			nodes.home.page_header, 
			t * 4.0,
			{ opacity: 0, y: -offset * 0.5, x: offset * 0.5 }, 
			{ opacity: 1, y: 0, x: 0, ease: eases.zip_in, clearProps: 'all' }
		), t * 5.0 )

		tl.add( gsap.fromTo( 
			world.uniforms.background.u_border_tween, 
			t * 10.0, 
			{ value: 0 }, 
			{ value: 1, ease: CustomEase.create("custom", "M0,0,C0.4,0,0,1,1,1") }
		), t * 6.0 )


		// PROJECT CONTENT IN
		tl.add( gsap.fromTo(
			[
				nodes.project.hero_wrap,
				nodes.project.info_wrap,
				nodes.project.intro_wrap,
				nodes.project.body,
				nodes.project.next_wrap
			],
			t * 5.0,
			{ y: 40, x: -40, },
			{ y: 0, x: 0, stagger: t * 0.28, ease: eases.zip_out, clearProps: 'all' }
		), t * 5 )

		tl.add( gsap.fromTo(
			[
				nodes.project.hero_wrap,
				nodes.project.info_wrap,
				nodes.project.intro_wrap,
				nodes.project.body,
				nodes.project.next_wrap
			],
			t * 5.0,
			{ opacity: 0 },
			{ opacity: 1, stagger: t * 0.28 }
		), t * 7.0 )

		tl.add( gsap.fromTo( 
			nodes.footer, 
			t * 5.0,
			{ opacity: 0 }, 
			{ opacity: 1, clearProps: 'all' }
		), t * 5.0 + t * 0.28 * 5 )


		_new.classList.remove('animate-in')

		status.active_tl = tl

		tl.seek( 0 )
		setTimeout( transitions.start, delay )

	},

	from_null_to_about: (_parent, _old, _new, callback, delay) => {

		if ( status.transitioning && status.active_tl ) {
			transitions.end_early()
		}

		status.transitioning = true
		stitch_scroll()

		let t = 6 / 60

		world.uniforms.background.u_homepage.value = 1.0
		world.uniforms.background.u_projects.value = 0.0
		world.uniforms.background.u_project.value = 0.0
		world.uniforms.background.u_curtain.value = 1.0
		toggle_blendmode( false )

		let tl = gsap.timeline({
			paused: true,
			onStart: () => {
				
			},
			onComplete: () => {
				if ( _old ) _old.remove()
				sync_heights()
				lazyload.update()
				navigation.links_add()
				finalize_page_header()
				if ( callback ) callback()
					
				transitions.end()

			}
		} )

		let offset = utils.remap( state.window_size.w, 720.0, 1920.0, -10.0, -30, true )


		// tl.add( gsap.from( new_page_header, t * 3, { x: -20, y: 20, opacity: 0, ease: eases.zip_out } ), t * 7 )

		tl.add( gsap.fromTo( 
			nodes.home.page_blakerutledge, 
			t * 6.0, 
			{ opacity: 0, y: -offset * 0.4, x: offset * 0.4 }, 
			{ opacity: 1, y: 0, x: 0, ease: eases.zip_in }
		), t * 5.0 )

		tl.add( gsap.fromTo( 
			nodes.home.page_header, 
			t * 6.0,
			{ opacity: 0, y: -offset * 0.5, x: offset * 0.5 }, 
			{ opacity: 1, y: 0, x: 0, ease: eases.zip_in, clearProps: 'all' }
		), t * 6.0 )


		// ABOUT CONTENT IN
		tl.add( gsap.fromTo(
			[
				nodes.about.wrap,
				nodes.about.image,
				nodes.about.intro,
				nodes.about.blurb,
				nodes.about.lists
			],
			t * 5.0,
			{ y: 40, x: -40, },
			{ y: 0, x: 0, stagger: t * 0.28, ease: eases.zip_out, clearProps: 'all' }
		), t * 5 )

		tl.add( gsap.fromTo(
			[
				nodes.about.wrap,
				nodes.about.image,
				nodes.about.intro,
				nodes.about.blurb,
				nodes.about.lists
			],
			t * 5.0,
			{ opacity: 0 },
			{ opacity: 1, stagger: t * 0.24 }
		), t * 7.0 )


		tl.add( gsap.fromTo( 
			nodes.footer, 
			t * 5.0,
			{ opacity: 0 }, 
			{ opacity: 1, clearProps: 'all' }
		), t * 7.0 + 5 * t * 0.24 )

		tl.add( gsap.fromTo( 
			world.uniforms.background.u_border_tween, 
			t * 10.0, 
			{ value: 0 }, 
			{ value: 1, ease: CustomEase.create("custom", "M0,0,C0.4,0,0,1,1,1") }
		), t * 6.0 )


		shapes_helper_forward( tl )

		_new.classList.remove('animate-in')

		status.active_tl = tl
		
		tl.seek( 0 )
		setTimeout( transitions.start, delay )

	},

	from_home_to_projects: (_parent, _old, _new, callback, delay) => {

		if ( status.transitioning && status.active_tl ) {
			transitions.end_early()
		}

		status.transitioning = true

		let	t = 6 / 60

		let tl = gsap.timeline({
			paused: true,
			onStart: () => {

			},
			onComplete: () => {
				if ( _old ) _old.remove()
				lazyload.update()
				navigation.links_add()
				finalize_page_header()
				if ( callback ) callback()
					
				transitions.end()
			}
		} )

		// SET initial states
		let old_page_header = utils.qs('.page-header-inner.existing')
		let new_page_header = utils.qs('.page-header-inner.incoming')
	

		shapes_helper_forward( tl )

		let offset = utils.remap( state.window_size.w, 720.0, 1920.0, -10.0, -60, true )

		// HEADER out
		tl.add( gsap.to( old_page_header, t * 3, { x: 10, y: -10, ease: eases.slide_out } ), t * 0 )
		tl.add( gsap.to( old_page_header, t * 2, { opacity: 0, ease: Power0.easeNone } ), t * 0 )

		// BLURB header
		tl.add( gsap.to( nodes.home.header, t * 3, { opacity: 0.5, x: 20, y: -20, opacity: 0, ease: eases.slide_out } ), t * 1.0 )

		// BLURB body
		// tl.add( gsap.to( nodes.home.blurb, t * 3, { opacity: 0.0, x: 30, y: -30, ease: eases.slide_out } ), t * 1.333 )

		// PROJECTS button out
		tl.add( gsap.to( nodes.home.projects_link, t * 3, { opacity: 0.0, x: 30, y: -30, ease: eases.slide_out } ), t * 2.0 )

		// FOOTER out
		tl.add( gsap.to( nodes.footer, t * 3, { opacity: 0.0 } ), t * 2.0 )

		// HEADER in
		tl.add( gsap.from( new_page_header, t * 3, { x: -20, y: 20, opacity: 0, ease: eases.slide_in } ), t * 4.5 )

		tl.call( stitch_scroll, null, t * 6.0 )

		tl.add( gsap.to( 
			world.uniforms.background.u_homepage, 
			t * 12, 
			{ value: 0.0 }
		), 0.0  )
		
		tl.add( gsap.fromTo(
			nodes.projects.modules,
			t * 6.0,
			{ y: -offset, x: offset },
			{ y: 0, x: 0, stagger: t * 0.28, ease: eases.slide_in, clearProps: 'all' }
		), t * 6.0 )

		tl.add( gsap.fromTo(
			nodes.projects.modules,
			t * 6.0,
			{ scaleY: 0.0 },
			{ scaleY: 1.0, transformOrigin: "50% 100%", stagger: t * 0.28, ease: eases.zip_in, clearProps: 'all' }
		), t * 6.0 )

		tl.add( gsap.fromTo(
			nodes.projects.names,
			t * 4.0,
			{ opacity: 0.0 },
			{ opacity: 1.0, stagger: t * 0.18, ease: eases.slide_in, clearProps: 'all' }
		), t * 11.0 )

		tl.add( gsap.fromTo(
			nodes.projects.descs,
			t * 4.0,
			{ opacity: 0.0 },
			{ opacity: 1.0, stagger: t * 0.11, ease: eases.slide_in, clearProps: 'all' }
		), t * 12.0 )


		// FOOTER in
		tl.add( gsap.to( nodes.footer, t * 3, { opacity: 1.0 } ), t * 6.0 + t * 0.28 * nodes.projects.modules.length + t * 5.0 )

		_new.classList.remove('animate-in')

		status.active_tl = tl

		tl.seek( 0 )
		setTimeout( transitions.start, delay )

		world.uniforms.background.u_projects.value = 1.0


	},

	from_projects_to_home: (_parent, _old, _new, callback, delay) => {

		if ( status.transitioning && status.active_tl ) {
			transitions.end_early()
		}

		status.transitioning = true

		let t = 6 / 60

		let tl = gsap.timeline({
			paused: true,
			onStart: () => {
				// tl.seek( 0 )
				// setTimeout( transitions.start, delay )
			},
			onComplete: () => {
				if ( _old ) _old.remove()
				lazyload.update()
				navigation.links_add()
				finalize_page_header()
				if ( callback ) callback()
					
				world.uniforms.background.u_projects.value = 0.0

				transitions.end()
			}
		} )


		shapes_helper_backward( tl )


		// SET initial states
		
		let old_page_header = utils.qs('.page-header-inner.existing')
		let new_page_header = utils.qs('.page-header-inner.incoming')

		let b = nodes.projects.names.length * t * 0.28 + t * 2.0

		let offset = utils.remap( state.window_size.w, 720.0, 1920.0, -10.0, -40, true )


		// HEADER out
		tl.add( gsap.to( old_page_header, t * 3, { opacity: 0.5, x: -10, y: 10, opacity: 0, ease: eases.zip_in } ), t * 0 )

		// BLURB header in
		tl.add( gsap.from( nodes.home.header, t * 3, { opacity: 0.0, x: 20, y: -20, ease: eases.zip_in } ), b + t * 2.5 )

		// BLURB body in
		// tl.add( gsap.from( nodes.home.blurb, t * 3, { opacity: 0.0, x: 30, y: -30, ease: eases.zip_in } ), b + t * 3.0 )

		// PROJECTS button in
		tl.add( gsap.from( nodes.home.projects_link, t * 3, { x: -offset, y: offset, opacity: 0, ease: eases.zip_in } ), b + t * 4.5 )

		// HEADER in
		tl.add( gsap.from( new_page_header, t * 3, { opacity: 0, x: 10, y: -10, ease: eases.zip_in } ), t * 7 )

		// FOOTER in
		tl.add( gsap.to( nodes.footer, t * 3.0, { opacity: 1.0 } ), t * 8.0 )


		tl.add( gsap.to( 
			world.uniforms.background.u_homepage, 
			t * 8, 
			{ value: 1.0 }
		), 0.0  )

		// tl.add( gsap.fromTo(
		// 	nodes.projects.modules.slice().reverse(),
		// 	t * 3.0,
		// 	{ y: 0, x: -0, opacity: 1 },
		// 	{ y: 20, x: -20, opacity: 0, stagger: t * 0.24, ease: eases.slide_out }
		// ), t * 0.0 )

		tl.add( gsap.fromTo(
			nodes.projects.names,
			t * 2.0,
			{ opacity: 1.0 },
			{ opacity: 0.0, stagger: t * 0.12, ease: eases.slide_in }
		), t * 0.0 )

		tl.add( gsap.fromTo(
			nodes.projects.descs,
			t * 2.0,
			{ opacity: 1.0 },
			{ opacity: 0.0, stagger: t * 0.08, ease: eases.slide_in }
		), t * 0.0 )


		tl.add( gsap.fromTo(
			nodes.projects.modules,
			t * 3.0,
			{ y: 0, x: 0 },
			{ y: -offset, x: offset, stagger: t * 0.18, ease: eases.slide_in }
		), t * 2.0 )

		tl.add( gsap.fromTo(
			nodes.projects.modules,
			t * 3.0,
			{ scaleY: 1.0 },
			{ scaleY: 0.0, transformOrigin: "50% 100%", stagger: t * 0.18, ease: eases.zip_in }
		), t * 2.0 )



		// FOOTER out
		tl.add( gsap.to( nodes.footer, t * 3.0, { opacity: 0.0 } ), t * 0.0 )

		tl.call( stitch_scroll, null, t * 6.0 )

		_new.classList.remove('animate-in')

		status.active_tl = tl
		// status.active_tl.play( 0 )
		tl.seek( 0 )
		setTimeout( transitions.start, delay )

	},

	from_projects_to_project: (_parent, _old, _new, callback, delay) => {

		if ( status.transitioning && status.active_tl ) {
			transitions.end_early()
		}

		status.transitioning = true

		let t = 6 / 60

		let tl = gsap.timeline({
			paused: true,
			onStart: () => {
				// tl.seek( 0 )
				// setTimeout( transitions.start, delay )
			},
			onComplete: () => {
				if ( _old ) _old.remove()
				lazyload.update()
				navigation.links_add()
				finalize_page_header()
				if ( callback ) callback()
				
				world.uniforms.background.u_projects.value = 0.0

				transitions.end()
			}
		} )

		// SET initial states
		
		let old_page_header = utils.qs('.page-header-inner.existing')
		let new_page_header = utils.qs('.page-header-inner.incoming')


		// shapes_helper_forward( tl )

		let offset = utils.remap( state.window_size.w, 720.0, 1920.0, -10.0, -20, true )


		let b = nodes.projects.names.length * t * 0.28 + t * 2.0

		// HEADER out
		tl.add( gsap.to( old_page_header, t * 3, { opacity: 0.0, x: 10, y: -10, ease: eases.slide_out } ), t * 0 )

		// FOOTER out
		tl.add( gsap.to( nodes.footer, t * 3.0, { opacity: 0.0 } ), t * 0.0 )

		// HEADER in
		tl.add( gsap.from( new_page_header, t * 3, { x: -10, y: 10, opacity: 0, ease: eases.zip_in } ), t * 3 )

		tl.add( gsap.to( 
			world.uniforms.background.u_homepage, 
			t * 12, 
			{ value: 0.0 }
		), 0.0  )

		tl.add( gsap.to( 
			world.uniforms.background.u_curtain, 
			t * 12, 
			{ value: 1.0 }
		), 0.0  )

		// PROJECT MODULES OUT
		tl.add( gsap.fromTo(
			nodes.projects.names,
			t * 2.0,
			{ opacity: 1.0 },
			{ opacity: 0.0, stagger: t * 0.12, ease: eases.slide_in }
		), t * 0.0 )

		tl.add( gsap.fromTo(
			nodes.projects.descs,
			t * 2.0,
			{ opacity: 1.0 },
			{ opacity: 0.0, stagger: t * 0.08, ease: eases.slide_in }
		), t * 0.0 )


		tl.add( gsap.fromTo(
			nodes.projects.modules,
			t * 3.0,
			{ y: 0, x: 0 },
			{ y: offset, x: -offset, stagger: t * 0.22, ease: eases.slide_in }
		), t * 2.0 )

		tl.add( gsap.fromTo(
			nodes.projects.modules,
			t * 3.0,
			{ scaleY: 1.0 },
			{ scaleY: 0.0, transformOrigin: "50% 0%", stagger: t * 0.22, ease: eases.zip_in }
		), t * 2.0 )


		tl.call( () => { toggle_blendmode( false ) }, null, b )

		tl.call( stitch_scroll, null, b )



		// PROJECT CONTENT IN
		tl.add( gsap.fromTo(
			[
				nodes.project.hero_wrap,
				nodes.project.info_wrap,
				nodes.project.intro_wrap,
				nodes.project.body,
				nodes.project.next_wrap
			],
			t * 5.0,
			{ y: 40, x: -40, },
			{ y: 0, x: 0, stagger: t * 0.28, ease: eases.zip_out, clearProps: 'all' }
		), t * 5 )

		tl.add( gsap.fromTo(
			[
				nodes.project.hero_wrap,
				nodes.project.info_wrap,
				nodes.project.intro_wrap,
				nodes.project.body,
				nodes.project.next_wrap
			],
			t * 5.0,
			{ opacity: 0 },
			{ opacity: 1, stagger: t * 0.24 }
		), t * 7.0 )
		
		tl.add( gsap.fromTo(
			world.uniforms.background.u_project,
			t * 5.0,
			{ value: 0.0 },
			{ value: 1.0 }
		), t * 7.0 )

		// FOOTER in
		tl.add( gsap.to( nodes.footer, t * 3, { opacity: 1.0 } ), t * 8.0 )


		_new.classList.remove('animate-in')

		status.active_tl = tl
		// status.active_tl.play( 0 )
		tl.seek( 0 )
		setTimeout( transitions.start, delay )
	
	},

	from_project_to_projects: (_parent, _old, _new, callback, delay) => {

		close_modal()

		if ( status.transitioning && status.active_tl ) {
			transitions.end_early()
		}

		status.transitioning = true

		let t = 6 / 60

		let tl = gsap.timeline({
			paused: true,
			onStart: () => {
				// tl.seek( 0 )
				// setTimeout( transitions.start, delay )
			},
			onComplete: () => {
				if ( _old ) _old.remove()
				lazyload.update()
				navigation.links_add()
				finalize_page_header()
				if ( callback ) callback()
					
				transitions.end()
			}
		} )

		// SET initial states
		
		let old_page_header = utils.qs('.page-header-inner.existing')
		let new_page_header = utils.qs('.page-header-inner.incoming')


		shapes_helper_backward( tl, t * 0.5 )


		let offset = utils.remap( state.window_size.w, 720.0, 1920.0, -10.0, -20, true )

		let b = t * 0.28 * 5 + t * 3.0

		// HEADER out
		tl.add( gsap.to( old_page_header, t * 3, { x: -10, y: 10, opacity: 0, ease: eases.slide_out } ), t * 0 )

		// HEADER in
		tl.add( gsap.from( new_page_header, t * 3, { opacity: 0, x: 10, y: -10, ease: eases.zip_in } ), t * 3 )

		tl.add( gsap.to( 
			world.uniforms.background.u_homepage, 
			t * 12, 
			{ value: 0.0 }
		), 0.0  )

		tl.add( gsap.to( 
			world.uniforms.background.u_curtain, 
			t * 12, 
			{ value: 0.0 }
		), 0.0  )

		// FOOTER out
		tl.add( gsap.to( nodes.footer, t * 3, { opacity: 0.0 } ), t * 0.0 )

		tl.add( gsap.fromTo(
			world.uniforms.background.u_project,
			t * 3.0,
			{ value: 1.0 },
			{ value: 0.0 }
		), t * 0.0 )

		tl.add( gsap.fromTo(
			[
				nodes.project.hero_wrap,
				nodes.project.info_wrap,
				nodes.project.intro_wrap,
				nodes.project.body,
				nodes.project.next_wrap
			],
			t * 3.0,
			{ y: 0, x: 0, opacity: 1, },
			{ y: 20, x: -20, opacity: 0, stagger: t * 0.28, ease: eases.zip_out }
		), t * 0 )


		// STITCH

		tl.call( () => { toggle_blendmode( true ) }, null, b )

		tl.call( stitch_scroll, null, b )


		// PROJECT MODULES OUT
		tl.add( gsap.fromTo(
			nodes.projects.modules,
			t * 3.0,
			{ y: offset, x: -offset },
			{ y: 0, x: 0, stagger: t * 0.18, ease: eases.slide_in }
		), b + t * 0.0 )

		tl.add( gsap.fromTo(
			nodes.projects.modules,
			t * 3.0,
			{ scaleY: 0.0 },
			{ scaleY: 1.0, transformOrigin: "50% 0%", stagger: t * 0.18, ease: eases.zip_in }
		), b + t * 0.0 )

		tl.add( gsap.fromTo(
			nodes.projects.names,
			t * 2.0,
			{ opacity: 0.0 },
			{ opacity: 1.0, stagger: t * 0.12, ease: eases.slide_in }
		), b + t * 5.0 )

		tl.add( gsap.fromTo(
			nodes.projects.descs,
			t * 2.0,
			{ opacity: 0.0 },
			{ opacity: 1.0, stagger: t * 0.08, ease: eases.slide_in }
		), b + t * 6.0 )


		// FOOTER in
		tl.add( gsap.to( nodes.footer, t * 3, { opacity: 1.0 } ), t * 9.0 )

		_new.classList.remove('animate-in')

		status.active_tl = tl
		// status.active_tl.play( 0 )
		tl.seek( 0 )
		setTimeout( transitions.start, delay )

		world.uniforms.background.u_projects.value = 1.0

	},

	from_project_to_project: (_parent, _old, _new, callback, delay) => {
		
		close_modal()

		if ( status.transitioning && status.active_tl ) {
			transitions.end_early()
		}

		status.transitioning = true

		let t = 6 / 60

		let tl = gsap.timeline({
			paused: true,
			onStart: () => {
				// tl.seek( 0 )
				// setTimeout( transitions.start, delay )
			},
			onComplete: () => {
				if ( _old ) _old.remove()
				lazyload.update()
				navigation.links_add()
				finalize_page_header()
				if ( callback ) callback()
					
				transitions.end()
			}
		} )

		// SET initial states
		
		let old_page_header = utils.qs('.page-header-inner.existing')
		let new_page_header = utils.qs('.page-header-inner.incoming')


		let b = t * 5

		// HEADER out
		tl.add( gsap.to( old_page_header, t * 3, { x: 10, y: -10, opacity: 0, ease: eases.slide_out } ), t * 0 )

		// HEADER in
		tl.add( gsap.from( new_page_header, t * 3, { opacity: 0, x: -10, y: 10, ease: eases.zip_in } ), t * 6.0 )

		tl.add( gsap.fromTo(
			[
				nodes.project_outgoing.hero_wrap,
				nodes.project_outgoing.info_wrap,
				nodes.project_outgoing.intro_wrap,
				nodes.project_outgoing.body,
				nodes.project_outgoing.next_wrap
			],
			t * 4.0,
			{ y: 0, x: 0, opacity: 1, },
			{ y: -20, x: 20, opacity: 0, stagger: t * 0.28, ease: eases.zip_out }
		), t * 0 )

		// FOOTER out
		tl.add( gsap.to( nodes.footer, t * 3, { opacity: 0.0 } ), t * 0.28 * 5 )


		tl.call( stitch_scroll, null, t * 4.0 + t * 0.28 * 5 + t * 3 )


		// PROJECT CONTENT IN
		tl.add( gsap.fromTo(
			[
				nodes.project.hero_wrap,
				nodes.project.info_wrap,
				nodes.project.intro_wrap,
				nodes.project.body,
				nodes.project.next_wrap
			],
			t * 5.0,
			{ y: 40, x: -40, },
			{ y: 0, x: 0, stagger: t * 0.28, ease: eases.zip_out, clearProps: 'all' }
		), t * 5.5 )

		tl.add( gsap.to( 
			world.uniforms.background.u_homepage, 
			t * 12, 
			{ value: 0.0 }
		), 0.0  )

		tl.add( gsap.fromTo(
			[
				nodes.project.hero_wrap,
				nodes.project.info_wrap,
				nodes.project.intro_wrap,
				nodes.project.body,
				nodes.project.next_wrap
			],
			t * 5.0,
			{ opacity: 0 },
			{ opacity: 1, stagger: t * 0.24 }
		), t * 7.5 )

		// FOOTER in
		tl.add( gsap.to( nodes.footer, t * 3, { opacity: 1.0 } ), t * 7.5 + t * 0.24 * 5 )

		_new.classList.remove('animate-in')

		status.active_tl = tl
		// status.active_tl.play( 0 )
		tl.seek( 0 )
		setTimeout( transitions.start, delay )

	},

	from_project_to_home: (_parent, _old, _new, callback, delay) => {

		close_modal()

		if ( status.transitioning && status.active_tl ) {
			transitions.end_early()
		}

		status.transitioning = true

		let t = 6 / 60

		let tl = gsap.timeline({
			paused: true,
			onStart: () => {
				// tl.seek( 0 )
				// setTimeout( transitions.start, delay )
			},
			onComplete: () => {
				if ( _old ) _old.remove()
				lazyload.update()
				navigation.links_add()
				finalize_page_header()
				if ( callback ) callback()
					
				transitions.end()
			}
		} )

		// SET initial states
		
		let old_page_header = utils.qs('.page-header-inner.existing')
		let new_page_header = utils.qs('.page-header-inner.incoming')


		shapes_helper_backward( tl, t * 0.5 )


		let b = t * 5

		// HEADER out
		tl.add( gsap.to( old_page_header, t * 3, { x: -10, y: 10, opacity: 0, ease: eases.zip_out } ), t * 0 )

		// HEADER in
		tl.add( gsap.from( new_page_header, t * 3, { opacity: 0, x: 10, y: -10, ease: eases.zip_in } ), t * 5 )

		tl.add( gsap.fromTo(
			[
				nodes.project.hero_wrap,
				nodes.project.info_wrap,
				nodes.project.intro_wrap,
				nodes.project.body,
				nodes.project.next_wrap
			],
			t * 3.0,
			{ y: 0, x: 0, opacity: 1, },
			{ y: 20, x: -20, opacity: 0, stagger: t * 0.28, ease: eases.zip_out }
		), t * 0 )


		tl.add( gsap.fromTo(
			world.uniforms.background.u_project,
			t * 3.0,
			{ value: 1.0 },
			{ value: 0.0 }
		), t * 0.0 )

		tl.call( stitch_scroll, null, t * 8.0 )

		tl.add( gsap.to( 
			world.uniforms.background.u_homepage, 
			t * 8, 
			{ value: 1.0 }
		), 0.0  )

		tl.add( gsap.to( 
			world.uniforms.background.u_curtain, 
			t * 8, 
			{ value: 0.0 }
		), 0.0  )

		tl.call( () => { toggle_blendmode( true ) }, null, t * 0 )

		// BLURB header in
		tl.add( gsap.fromTo( nodes.home.header, t * 3, { opacity: 0.0, x: 20, y: -20 }, { opacity: 1, x: 0, y: 0, ease: eases.zip_in } ), b + t * 2.5 )

		// BLURB body in
		// tl.add( gsap.fromTo( nodes.home.blurb, t * 3, { opacity: 0.0, x: 30, y: -30 }, { opacity: 1, x: 0, y: 0, ease: eases.zip_in } ), b + t * 3.0 )

		// PROJECTS button in
		tl.add( gsap.fromTo( nodes.home.projects_link, t * 3, { x: 30, y: -30, opacity: 0 }, { opacity: 1, x: 0, y: 0, ease: eases.zip_in } ), b + t * 4.5 )

		_new.classList.remove('animate-in')

		status.active_tl = tl
		// status.active_tl.play( 0 )
		tl.seek( 0 )
		setTimeout( transitions.start, delay )

	},

	from_home_to_project: (_parent, _old, _new, callback, delay) => {

		if ( status.transitioning && status.active_tl ) {
			transitions.end_early()
		}

		status.transitioning = true

		let	t = 6 / 60

		let tl = gsap.timeline({
			paused: true,
			onStart: () => {

			},
			onComplete: () => {
				if ( _old ) _old.remove()
				lazyload.update()
				navigation.links_add()
				finalize_page_header()
				if ( callback ) callback()
					
				transitions.end()
			}
		} )

		// SET initial states
		let old_page_header = utils.qs('.page-header-inner.existing')
		let new_page_header = utils.qs('.page-header-inner.incoming')
	

		shapes_helper_forward( tl )


		// HEADER out
		tl.add( gsap.to( old_page_header, t * 3, { x: 10, y: -10, ease: eases.slide_out } ), t * 0 )
		tl.add( gsap.to( old_page_header, t * 2, { opacity: 0, ease: Power0.easeNone } ), t * 0 )

		// BLURB header
		tl.add( gsap.to( nodes.home.header, t * 3, { opacity: 0.5, x: 20, y: -20, opacity: 0, ease: eases.slide_out } ), t * 0.666 )

		// BLURB body
		// tl.add( gsap.to( nodes.home.blurb, t * 3, { opacity: 0.0, x: 30, y: -30, ease: eases.slide_out } ), t * 1.333 )

		// PROJECTS button out
		tl.add( gsap.to( nodes.home.projects_link, t * 3, { opacity: 0.0, x: 30, y: -30, ease: eases.slide_out } ), t * 2.0 )

		// FOOTER out
		tl.add( gsap.to( nodes.footer, t * 3, { opacity: 0.0 } ), t * 2.0 )

		// HEADER in
		tl.add( gsap.from( new_page_header, t * 3, { x: -20, y: 20, opacity: 0, ease: eases.slide_in } ), t * 4.5 )

		tl.call( stitch_scroll, null, t * 6.0 )

		tl.call( () => { toggle_blendmode( false ) }, null, t * 6 )

		tl.add( gsap.to( 
			world.uniforms.background.u_homepage, 
			t * 8, 
			{ value: 0.0 }
		), 0.0  )

		
		// PROJECT CONTENT IN
		tl.add( gsap.fromTo(
			[
				nodes.project.hero_wrap,
				nodes.project.info_wrap,
				nodes.project.intro_wrap,
				nodes.project.body,
				nodes.project.next_wrap
			],
			t * 5.0,
			{ y: 40, x: -40, },
			{ y: 0, x: 0, stagger: t * 0.28, ease: eases.zip_out, clearProps: 'all' }
		), t * 7.0 )

		tl.add( gsap.fromTo(
			world.uniforms.background.u_project,
			t * 5.0,
			{ value: 1.0 },
			{ value: 0.0 }
		), t * 7.0 )

		tl.add( gsap.fromTo(
			[
				nodes.project.hero_wrap,
				nodes.project.info_wrap,
				nodes.project.intro_wrap,
				nodes.project.body,
				nodes.project.next_wrap
			],
			t * 5.0,
			{ opacity: 0 },
			{ opacity: 1, stagger: t * 0.24 }
		), t * 8.0 )
		
		// FOOTER in
		tl.add( gsap.to( nodes.footer, t * 3, { opacity: 1.0 } ), t * 8.0 )


		_new.classList.remove('animate-in')

		status.active_tl = tl
		
		// status.active_tl.play( 0 )

		tl.seek( 0 )
		setTimeout( transitions.start, delay )

	},

	from_home_to_about: (_parent, _old, _new, callback, delay) => {

		if ( status.transitioning && status.active_tl ) {
			transitions.end_early()
		}

		status.transitioning = true

		let	t = 6 / 60

		let tl = gsap.timeline({
			paused: true,
			onStart: () => {

			},
			onComplete: () => {
				if ( _old ) _old.remove()
				lazyload.update()
				navigation.links_add()
				finalize_page_header()
				if ( callback ) callback()
					
				transitions.end()
			}
		} )

		// SET initial states
		let old_page_header = utils.qs('.page-header-inner.existing')
		let new_page_header = utils.qs('.page-header-inner.incoming')
	

		shapes_helper_forward( tl )

		let offset = utils.remap( state.window_size.w, 720.0, 1920.0, -10.0, -20, true )

		// HEADER out
		tl.add( gsap.to( old_page_header, t * 3, { x: 10, y: -10, ease: eases.slide_out } ), t * 0 )
		tl.add( gsap.to( old_page_header, t * 2, { opacity: 0, ease: Power0.easeNone } ), t * 0 )

		// BLURB header
		tl.add( gsap.to( nodes.home.header, t * 3, { opacity: 0.5, x: -offset, y: offset, opacity: 0, ease: eases.slide_out } ), t * 1.0 )

		// BLURB body
		// tl.add( gsap.to( nodes.home.blurb, t * 3, { opacity: 0.0, x: 30, y: -30, ease: eases.slide_out } ), t * 1.333 )

		// PROJECTS button out
		tl.add( gsap.to( nodes.home.projects_link, t * 3, { opacity: 0.0, x: -offset, y: offset, ease: eases.slide_out } ), t * 2.0 )

		// HEADER in
		tl.add( gsap.from( new_page_header, t * 3, { x: -20, y: 20, opacity: 0, ease: eases.slide_in } ), t * 4.5 )

		tl.call( stitch_scroll, null, t * 6.0 )

		tl.add( gsap.to( 
			world.uniforms.background.u_homepage, 
			t * 8, 
			{ value: 1.0 }
		), 0.0  )

		tl.add( gsap.to( 
			world.uniforms.background.u_curtain, 
			t * 8, 
			{ value: 1.0 }
		), 0.0  )

		tl.add( gsap.to( 
			nodes.footer, 
			t * 5.0,
			{ opacity: 0 }
		), t * 0.0 )


		tl.call( () => { toggle_blendmode( false ) }, null, t * 8 )

		// ABOUT CONTENT IN
		tl.add( gsap.fromTo(
			[
				nodes.about.wrap,
				nodes.about.image,
				nodes.about.intro,
				nodes.about.blurb,
				nodes.about.lists
			],
			t * 5.0,
			{ y: 20, x: -20, },
			{ y: 0, x: 0, stagger: t * 0.28, ease: eases.zip_out, clearProps: 'all' }
		), t * 5 )

		tl.add( gsap.fromTo(
			[
				nodes.about.wrap,
				nodes.about.image,
				nodes.about.intro,
				nodes.about.blurb,
				nodes.about.lists
			],
			t * 5.0,
			{ opacity: 0 },
			{ opacity: 1, stagger: t * 0.24 }
		), t * 7.0 )

		tl.add( gsap.to( 
			nodes.footer, 
			t * 5.0,
			{ opacity: 1 }
		), t * 7.0 + t * 5 * 0.24 )

		_new.classList.remove('animate-in')

		status.active_tl = tl

		tl.seek( 0 )
		setTimeout( transitions.start, delay )

	},

	from_about_to_home: (_parent, _old, _new, callback, delay) => {

		if ( status.transitioning && status.active_tl ) {
			transitions.end_early()
		}

		status.transitioning = true

		let t = 6 / 60

		let tl = gsap.timeline({
			paused: true,
			onStart: () => {
				// tl.seek( 0 )
				// setTimeout( transitions.start, delay )
			},
			onComplete: () => {
				if ( _old ) _old.remove()
				lazyload.update()
				navigation.links_add()
				finalize_page_header()
				if ( callback ) callback()
					
				transitions.end()
			}
		} )


		shapes_helper_backward( tl )


		// SET initial states
		
		let old_page_header = utils.qs('.page-header-inner.existing')
		let new_page_header = utils.qs('.page-header-inner.incoming')

		let b = t * 5


		let offset = utils.remap( state.window_size.w, 720.0, 1920.0, -10.0, -20, true )

		// HEADER out
		tl.add( gsap.to( old_page_header, t * 3, { opacity: 0.5, x: -10, y: 10, opacity: 0, ease: eases.zip_in } ), t * 0 )

		// BLURB header in
		tl.add( gsap.from( nodes.home.header, t * 3, { opacity: 0.0, x: -offset, y: offset, ease: eases.zip_in } ), b + t * 2.5 )

		// BLURB body in
		// tl.add( gsap.from( nodes.home.blurb, t * 3, { opacity: 0.0, x: 30, y: -30, ease: eases.zip_in } ), b + t * 3.0 )

		// PROJECTS button in
		tl.add( gsap.from( nodes.home.projects_link, t * 3, { x: -offset, y: offset, opacity: 0, ease: eases.zip_in } ), b + t * 3.5 )

		// HEADER in
		tl.add( gsap.from( new_page_header, t * 3, { opacity: 0, x: 10, y: -10, ease: eases.zip_in } ), t * 7 )

		tl.add( gsap.to( 
			nodes.footer, 
			t * 5.0,
			{ opacity: 1 }
		), t * 7.0 )

		tl.add( gsap.to( 
			world.uniforms.background.u_homepage, 
			t * 8, 
			{ value: 1.0 }
		), 0.0  )

		tl.add( gsap.to( 
			world.uniforms.background.u_curtain, 
			t * 8, 
			{ value: 0.0 }
		), 0.0  )

		tl.add( gsap.to( 
			nodes.footer, 
			t * 5.0,
			{ opacity: 0 }
		), t * 0.0 )

		tl.call( () => { toggle_blendmode( true ) }, null, t * 0 )

		tl.call( stitch_scroll, null, t * 6.0 )

		tl.add( gsap.fromTo(
			[
				nodes.about.wrap,
				nodes.about.image,
				nodes.about.intro,
				nodes.about.blurb,
				nodes.about.lists
			],
			t * 3.0,
			{ y: 0, x: 0, opacity: 1, },
			{ y: 10, x: -10, opacity: 0, stagger: t * 0.28, ease: eases.zip_out }
		), t * 0 )


		_new.classList.remove('animate-in')

		status.active_tl = tl
		// status.active_tl.play( 0 )
		tl.seek( 0 )
		setTimeout( transitions.start, delay )


	},


	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  INDIRECT LINKS

	from_projects_to_about: (_parent, _old, _new, callback, delay) => {
		// Animate out
		_new.classList.remove('animate-in')
		if ( _old !== null ) _old.remove()
		lazyload.update()
		navigation.links_add()
		finalize_page_header()
		
		world.uniforms.background.u_homepage.value = 0.0
		world.uniforms.background.u_curtain.value = 1.0

		if ( callback ) callback()
	
	},

	from_project_to_about: (_parent, _old, _new, callback, delay) => {
		// Animate out
		_new.classList.remove('animate-in')
		if ( _old !== null ) _old.remove()
		lazyload.update()
		navigation.links_add()
		finalize_page_header()
		
		world.uniforms.background.u_homepage.value = 1.0
		world.uniforms.background.u_curtain.value = 1.0
		world.uniforms.background.u_project.value = 0.0

		if ( callback ) callback()
	},

	from_about_to_projects: (_parent, _old, _new, callback, delay) => {
		// Animate out
		_new.classList.remove('animate-in')
		if ( _old !== null ) _old.remove()
		lazyload.update()
		navigation.links_add()
		finalize_page_header()

		world.uniforms.background.u_homepage.value = 0.0
		world.uniforms.background.u_curtain.value = 0.0
		world.uniforms.background.u_projects.value = 1.0

		if ( callback ) callback()
	},

	from_about_to_project: (_parent, _old, _new, callback, delay) => {
		// Animate out
		_new.classList.remove('animate-in')
		if ( _old !== null ) _old.remove()
		lazyload.update()
		navigation.links_add()
		finalize_page_header()

		world.uniforms.background.u_homepage.value = 0.0
		world.uniforms.background.u_curtain.value = 1.0
		world.uniforms.background.u_project.value = 1.0

		if ( callback ) callback()
	},

}

export { transitions as default }