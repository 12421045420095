export default () => {

	// Old
	// let usr = navigator.userAgentData
	// return ( usr && usr.mobile )


	let msTouchEnabled = window.navigator.msMaxTouchPoints
	let generalTouchEnabled = 'ontouchstart' in document.createElement( 'div' )
	
	// console.log(  )

	return ( msTouchEnabled || generalTouchEnabled )

}