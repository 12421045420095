// import { runtime } from 'handlebars'

import fourohfour from './../../templates/404.hbs'
import home from './../../templates/home.hbs'
import project from './../../templates/project.hbs'
import projects from './../../templates/projects.hbs'
import about from './../../templates/about.hbs'

let templates = {

	'404': fourohfour,
	'home': home,
	'project': project,
	'projects': projects,
	'about': about

}

export { templates as default }