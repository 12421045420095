import { gsap, CustomEase }		from 'gsap'

import { nodes }				from './../components/nodes.js'
import { all as content }		from './../components/content.js'
import { sync_heights }			from './../app/controller.js'
import is_mobile				from './mobile.js'
import * as utils				from  './utils.js'
import * as home				from './../sections/home.js'
import * as navigation			from './navigation.js'

export let video_out = null

export let videos_out = {}


export const USE_BUNDLE = true // is_mobile()

export const USE_BROADWAY = is_mobile()

export let DEFER_LOAD = true



let assets = {}

let downloads = []
 
export let meta = {
	parts: {},
	total_size: 0,
	downloaded: 0,
	completed: false
}

let s3

export let start = async ( callback ) => {

	let BOT_MODE = window.location.search.includes('bot_mode')
	let path = navigation.locate( true )
	DEFER_LOAD = ( path === 'home' )

	return new Promise( async ( resolve, reject ) => {

		if ( BOT_MODE ) {
			nodes.loading_modal.style.display = 'none'
				nodes.body.classList.add('loaded')

				resolve()
		}
		// USE_BUNDLE
		else if ( USE_BUNDLE ) {

			// SET url of video asset to pre-load
			let url = content.content.video.url
		
			// dev only, load from localserver
			if ( process.env.NODE_ENV === 'development' ) {
				url = '/assets/video.mp4'
			}

			// use portrait video on mobile
			if ( is_mobile() ) {
				url = url.replace( '.mp4', '-small-broadway.mp4' )
			}


			// FETCH the video asset

			var req = new XMLHttpRequest()
			req.open( 'GET', url, true )

			req.responseType = USE_BROADWAY ? 'arraybuffer' : 'blob'

			req.onload = function() {

				if ( this.status === 200 ) {
				
					// Video is now downloaded
					if ( USE_BROADWAY ) {
			
						// output for broadway webassembly mp4 decoding
						video_out = this.response;

					}
					else {
						
						// output for html5 decoding
						let v = this.response
						let vurl = URL.createObjectURL( v )  // IE10+
						video_out = vurl

					}
					
					if ( DEFER_LOAD ) {

						home.set_loader( 1 )

					}
					else {

						tween_out( resolve )

					}
						
				}
			}

			req.onerror = () => {
				// Error
			}

			req.onprogress = ( e ) => {

				if ( e.lengthComputable ) {
					
					let p = e.loaded / e.total

					if ( DEFER_LOAD ) {

						if ( p < 1 ) home.set_loader( p )

					}
					else {
						
						gsap.set(
							nodes.loading_bar,
							{ scaleX: p, transformOrigin: "0% 50%" }
						)

					}

				}
			
			}

			req.send()



			if ( DEFER_LOAD ) {

				// tween_out( resolve )
				nodes.loading_modal.style.display = 'none'
				nodes.body.classList.add('loaded')

				resolve()

			}
			else {

				tween_in()

			}

		}
		else {

			// DOWNLOAD separate videos per project
			// was better for desktop? but maybe not?

			for ( let a in content.content.video.scenes ) {

				downloads.push( download( content.content.video.scenes[ a ].url , a ) )

			}

			Promise.all( downloads ).then( () => {

				tween_out( resolve )

			} )

		}

	} )

}



let download = async ( url, a ) => {

	return new Promise( async ( resolve, reject ) => {

		meta.parts[ a ] = {
			size: content.content.video.scenes[ a ].size,
			loaded: 0
		}

		var req = new XMLHttpRequest()
		req.open( 'GET', url, true )

		req.responseType = USE_BROADWAY ? 'arraybuffer' : 'blob'

		req.onload = function() {

			// Onload is triggered even on 404
			// so we need to check the status code

			if ( this.status === 200 ) {

				if ( USE_BROADWAY ) {
		
					// output for broadway webassembly mp4 decoding
					videos_out[ a ] = this.response

				}
				else {
					
					// output for html5 decoding
					let v = this.response
					let vurl = URL.createObjectURL( v )  // IE10+
					videos_out[ a ] = vurl

				}

				// Video part is now downloaded
				resolve()
					
			}
		}

		req.onerror = function( e ) {
			
			console.log( a, e )

		}

		req.onprogress = ( e ) => {

			if ( e.lengthComputable ) {

				meta.parts[ a ].loaded = e.loaded / ( 1024 * 1024 )

				tally_progress()

			}
			else {
				// console.log('length uncomputable')
			}
		
		}

		req.send()

	} )

}



let tally_progress = () => {

	// let final_size_computed = true
	
	let t = 0
	let p = 0

	for ( let a in meta.parts ) {

		t += meta.parts[ a ].size

		p += meta.parts[ a ].loaded

	}

	nodes.loading_bar.style.transform = `scaleX( ${ p / t } )`
	gsap.set( nodes.loading_bar, { scaleX: p / t, transformOrigin: "0% 50%" } )

}



let tween_in = () => {

	let t = 0.3

	let tl = gsap.timeline({
		paused: true,
		onStart: () => {
			
		},
		onComplete: () => {

		}
	} )


	tl.add( gsap.fromTo( nodes.loading_modal, t, { opacity: 0 }, { opacity: 1 } ), 0 )

	tl.play()

	nodes.loading_modal.style.opacity = 1

}




let tween_out = ( resolve ) => {

	let t = 0.3

	let tl = gsap.timeline({
		paused: true,
		onStart: () => {
			
		},
		onComplete: () => {
			
			// resolve()

			nodes.loading_modal.style.display = 'none'
			nodes.body.classList.add('loaded')

			// delay to prevent black flash
			setTimeout( resolve, 10 )

		}
	} )


	tl.add( gsap.to( nodes.loading_label, t, { opacity: 0 } ), 0 )

	tl.add( gsap.to( nodes.loading_wrap, t, { 
		scaleX: 0, 
		transformOrigin: "100% 50%", 
		ease: CustomEase.create("custom", "M0,0,C0.2,0,0.6,0.3,1,1") 
	} ), 0 )

	tl.play()

}
