import Layzr from 'layzr.js'

import * as utils from './utils.js'
import { nodes } from './nodes.js'
import { sync_heights, is_mobile } from './../app/controller.js'

import { build_image_grid } from './../sections/project.js'
import insert_video from './insert-video.js'
import * as qs from 'querystring-es3'

export let instance

let BOT_MODE = false

const video_extensions = [ 'mp4', 'mov' ]



export let parse_embedly = () => {

	let embeds = utils.qsa('a.embedly-card')

	embeds.forEach( el => {

		let inline = el.classList.contains( 'plays-inline' ) 
		let no_focus = false
		let autoplay = 1

		if ( el.href.includes('?') ) {

			let base_url = el.href.split( '?' )[0]
			let params = el.href.split( '?' )[1]

			let p = qs.parse( params )

			el.href = base_url

			// console.log( el.href )

			// disable out-inliner via cms
			inline = inline && ( p.inline!=0 )

			if ( !inline ) {
				no_focus = true
				autoplay = 0
			}


		}

		el.href = el.href.replace('https://vimeo.com/','https://player.vimeo.com/video/')


		insert_video( el.parentElement, el.href, 'vimeo', autoplay, inline, no_focus )

	} )

}

export let update = () => {

	// parse_embedly()

	instance
		.update()
		.check()
}

export let check = () => {
	setTimeout( () => {
		instance.check()
	}, 200)
}

export let build = () => {

	let BOT_MODE = 
		window.location.search.includes('bot_mode') ||
		document.body.classList.contains('pre-rendered')

	// console.log( document.body.classList.contains('pre-rendered'))

	instance = Layzr({
		threshold: is_mobile ? 0.1 : 1.25,
		container: nodes.blend_mode_clip,
		bot_mode: BOT_MODE
	} )

	instance
		.update()
		.check()
		.handlers( true )
		.on( 'src:after', ( element ) => {

			let path_ = element.dataset.normal
			let type = element.tagName.toLowerCase()

			if ( type==='div' ) {

				// DIV with css defined background image
				element.dataset.loading = true
				let temp = document.createElement( 'img' )
				temp.src = path_
				temp.style.width = '0px'
				temp.style.height = '0px'

				temp.addEventListener( 'load', x => {
					element.style.backgroundImage = `url('${path_}')`
					element.parentElement.classList.remove('loading')
					element.classList.remove('loading')
					element.dataset.loaded = true
					element.dataset.loading = false
					temp.remove()
				} )

				element.appendChild( temp )

			}
			else if ( type === 'iframe' ) {
				
				element.src = path_
				element.dataset.loading = true

				let load_fn = () => {

					if ( element.parentElement && element.parentElement.classList ) {
						element.parentElement.classList.remove('loading')
					}

					element.classList.remove('loading')
					element.style.opacity = 1
					element.dataset.loaded = true
					element.dataset.loading = false

					element.removeEventListener( 'load', load_fn )
				
				}

				element.addEventListener( 'load', load_fn )

			}
			else {

				let asset_url = "" + element.dataset.normal
				let file_type = utils.file_extension( asset_url ).toLowerCase()
				let is_video = video_extensions.indexOf( file_type ) >= 0
				
				let is_vimeo = asset_url.includes('vimeo')

				// embedly vimeo ?
				if ( is_vimeo ) {

					let parent = element.parentElement

					element.remove()

					parent.innerHTML = `<a class="embedly-card plays-inline" href="${ asset_url }">`

					parse_embedly()

					parent.style.paddingBottom = '0px'

					// this just parses out a vimeo embed template and it comes back again as iframe

				}

				// Inline Video
				else if ( is_video ) {

					// create video wrapper
					let video_div = document.createElement( 'div' )
					video_div.classList.add('video-outer')

					video_div.innerHTML = 
						`<video muted loop playsinline data-height="${ element.getAttribute('height') }"" data-width="${  element.getAttribute('width')  }">
							<source src="" type="video/${ file_type }"/>
						</video>`

					// make sure video element is super hidden
					let video = video_div.querySelector( 'video' )
					// video.style.height='0px'
					// video.style.width='0px'
					video.style.opacity='0'

					// manipulate the dom, remove the img element and swap for video element
					let parent = element.parentElement
					element.remove()
					parent.appendChild( video_div )

					video_div.dataset.normal = path_
					video_div.dataset.loaded = false
					video_div.dataset.loading = true

					// set src
					// video.src = asset_url

					let load_fn = async (p) => {

						if ( String( video_div.dataset.loading ) == 'false' ) return

						// video.style.removeProperty('height')
						// video.style.removeProperty('width')

						video_div.dataset.normal = path_
						video_div.dataset.loading = false
						video_div.dataset.loaded = true

						if ( is_mobile ) {
							await utils.sleep( 500 )
						}

						if ( video_div.parentElement && video_div.parentElement.classList ) {
							video_div.parentElement.classList.remove('loading')
						}
						video_div.classList.remove('loading')
						// video.style.removeProperty('opacity')
						video.style.opacity = 1.0;

						video.play()

						video.removeEventListener( 'loadeddata', load_fn )

					}

					// video.muted = true
					video.addEventListener( 'loadeddata', (p) => {
						load_fn( p )
					} )

					// Fetch the whole asset as blob so that iOS uses the damn cache

					let load_attempt = async () => {

						const videoRequest = fetch( asset_url )
							.then( response => response.blob() )

						videoRequest.then( blob => {
							video.src = window.URL.createObjectURL( blob )
							video.load()
						} )
					}

					try {
						load_attempt()
					}
					catch ( e ) {
						video_div.dataset.loading = false
						video_div.dataset.loaded = false
					}

				}


				// Just a plain ol image 
				else {

					element.style.height='0px'
					element.style.width='0px'
					element.style.opacity='0'


					element.dataset.loading = true
					element.src = element.dataset.normal

					let load_fn = () => {
						
						element.dataset.loaded = true
						element.dataset.loading = false

						element.style.removeProperty('height')
						element.style.removeProperty('width')
						element.style.removeProperty('padding-bottom')
						element.parentElement.style.removeProperty('padding-bottom')
						element.style.removeProperty('opacity')
						element.parentElement.classList.remove('loading')
						element.classList.remove('loading')

						element.removeEventListener( 'load', load_fn )

					}

					element.addEventListener( 'load', load_fn )

				}

			}

			requestAnimationFrame( () => {
				sync_heights()
			} )

			setTimeout( () => {
				sync_heights() // sticky on mobile
			}, 100 )

		} )


		.on( 'src:remove', ( element ) => {

			let path_ = element.dataset.normal
			let type = element.tagName.toLowerCase()

			if ( type==='div' && element.style.backgroundImage && element.style.backgroundImage !== '' ) {

				// DIV with css defined background image
				element.style.removeProperty('background-image')
				element.classList.add('loading')

				element.dataset.loaded = false
				element.dataset.loading = false

			}
			else if ( type === 'iframe' ) {

				// dont unload

			}	
			else {

				let asset_url = "" + element.dataset.normal

				let file_type = ( '' + utils.file_extension( asset_url ) ).toLowerCase()
				let is_video = video_extensions.indexOf( file_type ) >= 0
				
				let is_vimeo = asset_url.includes('vimeo')

				// embedly vimeo
				if ( is_vimeo ) {
					// these are just iframes now
				}

				// Inline Video
				else if ( is_video ) {

					// create empty img
					let img = document.createElement('img')
					img.dataset.normal = element.dataset.normal
					img.dataset.loaded = false
					element.dataset.loading = false

					img.classList.add('loading')

					let parent = element.parentElement
					element.remove()

					parent.classList.add( 'loading' )

					parent.appendChild( img )

				}

				// Just a plain ol image 
				else {

					element.removeAttribute('src')
					element.classList.add('loading')

					element.parentNode.classList.add( 'loading' )

					element.dataset.loaded = false
					element.dataset.loading = false

				}

			}
			
		} )


		.on( 'src:unload', ( element ) => {

			let path_ = element.dataset.normal
			let type = element.tagName.toLowerCase()

			if ( type==='div' && element.style.backgroundImage && element.style.backgroundImage !== '' ) {

				// dont unload

			}
			else if ( type === 'iframe' ) {

				// dont unload

			}	
			else {

				let asset_url = "" + element.dataset.normal

				let file_type = ( '' + utils.file_extension( asset_url ) ).toLowerCase()
				let is_video = video_extensions.indexOf( file_type ) >= 0
				
				let is_vimeo = asset_url.includes('vimeo')

				// embedly vimeo
				if ( is_vimeo ) {
					// these are just iframes now
				}

				// Inline Video
				else if ( is_video ) {

					let video = element.querySelector('video')

					if ( !video ) return

					// set unloaded flag
					element.dataset.unloaded = true

					// store the devices local path to blob
					element.dataset['blob'] = video.src

					// free up for garbage collection
					video.src = ''
					video.load()

					// revert to loading 
					video.style.opacity='0'
					element.classList.add('loading')
					element.parentElement.classList.add('loading')

				}

				// Just a plain ol image 
				else {



				}

			}

		} )


		.on( 'src:reload', ( element ) => {

			let path_ = element.dataset.normal
			let type = element.tagName.toLowerCase()

			if ( type==='div' && element.style.backgroundImage && element.style.backgroundImage !== '' ) {

				// dont unload these

			}
			else if ( type === 'iframe' ) {

				// dont unload these

			}	
			else {

				let asset_url = "" + element.dataset.normal

				let file_type = ( '' + utils.file_extension( asset_url ) ).toLowerCase()
				let is_video = video_extensions.indexOf( file_type ) >= 0
				
				let is_vimeo = asset_url.includes('vimeo')

				// embedly vimeo
				if ( is_vimeo ) {
					// these are just iframes now
				}

				// Inline Video
				else if ( is_video ) {

					element.dataset.unloaded = false
					element.dataset.loading = true

					let v = element.querySelector('video')
					let asset_url = element.dataset['blob']
					
					let load_fn = async () => {

						if ( String( v.dataset.loading ) == 'false' ) return

						element.dataset.loading = false

						// wait bc low power devices take a sec to render the video element
						if ( is_mobile ) {
							await utils.sleep( 500 )
						}
						
						element.classList.remove('loading')
						element.parentElement.classList.remove('loading')
						v.style.opacity = 1.0
						
						v.play()

						v.removeEventListener( 'loadeddata', load_fn )

					}

					v.addEventListener( 'loadeddata', ( p ) => {
						load_fn( p )
					} )

					// already a blob
					v.src = asset_url
					v.load()
				}

				// Just a plain ol image 
				else {

					// these dont get unloaded

				}

			}

		} )

}