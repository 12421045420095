export let nodes = {
	home: {},
	about: {},
	projects: {},
	project: {},
	project_outgoing: {}
}


export let build = ( section ) => {



	if ( section === 'home' ) {
		
		nodes.home.title_wrap = qs('.container-inner.animate-in .section.home .title-wrap')
		nodes.home.footer_wrap = qs('.container-inner.animate-in .section.home .footer-wrap')
		nodes.home.projects_link = qs('.container-inner.animate-in .section.home .projects-link-wrap')
		nodes.home.header = qs('.container-inner.animate-in .section.home .header')
		nodes.home.blurb = qs('.container-inner.animate-in .section.home .blurb')
		nodes.home.footer_email = qs('.blend-mode-inner>.footer-wrap .footer-icon.email-icon')

		nodes.home.page_blakerutledge = qs('.header-wrap .title')
		nodes.home.page_header = qs('.header-wrap .subtitle')

		nodes.home.loading_bar = qs('.container-inner.animate-in .section.home .defer-loader .bar')
		nodes.home.loading_label = qs('.container-inner.animate-in .section.home .defer-loader .label')
		nodes.home.projects_label = qs('.container-inner.animate-in .section.home .projects-link div')

	}
	else if ( section === 'projects' ) {
		
		nodes.projects.modules = qsa( '.section.projects .project-module' )
		nodes.projects.names = qsa( '.section.projects .project-module .project-name' )
		nodes.projects.descs = qsa( '.section.projects .project-module .project-desc' )
		nodes.projects.wrap = qs( '.section.projects .projects-wrap' )
		nodes.projects.section = qs( '.section.projects' )

	}
	else if ( section === 'about' ) {
		
		nodes.about.wrap = qs('.section.about .about-wrap')
		nodes.about.image = qs('.section.about .image-container')
		nodes.about.intro = qs('.section.about .intro')
		nodes.about.blurb = qs('.section.about .about-blurb')
		nodes.about.lists = qsa('.section.about .list-wrap')

	}
	else if ( section === 'project' ) {
		
		// Project to project navigation, cache outgoing nodes for tweens
		if ( Object.keys( nodes.project ).length > 0 ) {
			nodes.project_outgoing.section = qs('.container-inner.animate-out .section.project')
			nodes.project_outgoing.body_wrap = qs('.container-inner.animate-out .section.project .project-body-wrap')
			nodes.project_outgoing.images = qsa('.container-inner.animate-out .section.project .project-body-wrap .img-wrap')
			nodes.project_outgoing.ps = qsa('.container-inner.animate-out .section.project .project-body-wrap p')
			nodes.project_outgoing.hero_wrap = qs('.container-inner.animate-out .section.project .hero-wrap')
			nodes.project_outgoing.intro_wrap = qs('.container-inner.animate-out .section.project .intro-wrap')
			nodes.project_outgoing.body = qs('.container-inner.animate-out .section.project .project-body')
			nodes.project_outgoing.next_wrap = qs('.container-inner.animate-out .section.project .next-wrap')
			nodes.project_outgoing.next_module = qs('.container-inner.animate-out .section.project .next-project')
			nodes.project_outgoing.info_wrap = qs('.container-inner.animate-out .section.project .info-wrap')
			// nodes.project_outgoing.modal_wrap = qs('.container-inner.animate-out .section.project .focus-wrap')
			// nodes.project_outgoing.modal_inner = qs('.container-inner.animate-out .section.project .focus-inner')
			// nodes.project_outgoing.modal_parent = qs('.container-inner.animate-out .section.project .focus-parent')
			// nodes.project_outgoing.modal_close = qs('.container-inner.animate-out .section.project .focus-wrap .close-wrap')
		}

		nodes.project.section = qs('.container-inner.animate-in .section.project')
		nodes.project.body_wrap = qs('.container-inner.animate-in .section.project .project-body-wrap')
		nodes.project.images = qsa('.container-inner.animate-in .section.project .project-body-wrap .img-wrap')
		nodes.project.ps = qsa('.container-inner.animate-in .section.project .project-body-wrap p')
		nodes.project.hero_wrap = qs('.container-inner.animate-in .section.project .hero-wrap')
		nodes.project.intro_wrap = qs('.container-inner.animate-in .section.project .intro-wrap')
		nodes.project.body = qs('.container-inner.animate-in .section.project .project-body')
		nodes.project.next_wrap = qs('.container-inner.animate-in .section.project .next-wrap')
		nodes.project.next_module = qs('.container-inner.animate-in .section.project .next-project')
		nodes.project.info_wrap = qs('.container-inner.animate-in .section.project .info-wrap')
		// nodes.project.modal_wrap = qs('.container-inner.animate-in .section.project .focus-wrap')
		// nodes.project.modal_inner = qs('.container-inner.animate-in .section.project .focus-inner')
		// nodes.project.modal_parent = qs('.container-inner.animate-in .section.project .focus-parent')
		// nodes.project.modal_close = qs('.container-inner.animate-in .section.project .focus-wrap .close-wrap')

	}
	else {

		nodes.body = qs('body')
		nodes.header = qs('.blend-mode-inner>.header-wrap')
		nodes.footer = qs('.blend-mode-inner>.footer-wrap')
		nodes.container_outer = qs('.container')
		nodes.container = qs('.container-inner')
		nodes.background_outer = qs('.background')
		nodes.background = qs('.background-inner')
		nodes.blend_mode_parent = qs('.blend-mode-parent')
		nodes.blend_mode_clip = qs('.blend-mode-clip')
		nodes.blend_mode_wrap = qs('.blend-mode-wrap')
		nodes.blend_mode_inner = qs('.blend-mode-inner')

		nodes.overlay = {}
		nodes.overlay.wrap = qs('.overlay-wrap')
		nodes.overlay.svg = qs('.overlay-wrap svg')
		nodes.overlay.group = qs('.overlay-wrap .overlay-g')

		nodes.loading_modal = qs('.loading-modal')
		nodes.loading_label = qs('.loading-modal .label')
		nodes.loading_wrap = qs('.loading-modal .progress-wrap')
		nodes.loading_bar = qs('.loading-modal .progress-bar')

		nodes.broadway = {}
		nodes.broadway.wrap = qs( '.broadway-wrap' )

		nodes.perf = {}
		nodes.perf.wrap = qs('.perf-wrap')
		nodes.perf.frame = qs('.perf-wrap .frame')
		nodes.perf.video = qs('.perf-wrap .video')

		nodes.copy_tooltip = qs('.copied-popup')

		nodes.focus = {}
		nodes.focus.modal_wrap = qs('.focus-wrap')
		nodes.focus.modal_inner = qs('.focus-wrap .focus-inner')
		nodes.focus.modal_parent = qs('.focus-wrap .focus-parent')
		nodes.focus.modal_close = qs('.focus-wrap .close-wrap')
		nodes.focus.label_project = qs('.focus-wrap .label-project')

		// Disable Context Menu (appears on long press sometimes)
		/*nodes.container.oncontextmenu = (event) => {
			event.preventDefault()
			event.stopPropagation()
			return false
		}*/

		// console.log( '%c nodes ', 'background: #009999; color: #fff', nodes )

	}

}


export let update = ( section ) => {


	if ( section == 'overlay' ) {
		nodes.overlay = {}
		nodes.overlay.wrap = qs('.overlay-wrap')
		nodes.overlay.svg = qs('.overlay-wrap svg')
		nodes.overlay.group = qs('.overlay-wrap .overlay-g')
		nodes.overlay.group_ps = qs('.overlay-wrap .overlay-g-ps')
	}


}



// Some syntactic sugar, my neighbor

let qs = (selector) => {
	return document.querySelector(selector)
}

let qsa = (selector) => {
	return Array.prototype.slice.call(document.querySelectorAll(selector))
}