import { Vec2 } from 'ogl'

import * as utils from './../components/utils.js'
import { nodes } from './../components/nodes.js'
import * as world from './../sections/world.js'
import { data_tree as routes } from './../components/routes.js'
import mobile from './../components/mobile.js'
import * as image_modal from './../components/image-modal.js'

import { build_image_grid } from './../sections/project.js'
import { resize_handler as overlay_resize_handler } from './../sections/overlay.js'
import * as transitions from './../components/transitions.js'

import * as project from './../sections/project'


export let scroll_cache = 0
export let scroll_diff = 0

export let is_mobile = true

export let build = () => {

	window.addEventListener('resize', resize_handler)

	/*
	console.log(
		'%c state ', 'background: #D865C7; color: #fff',
		state
	)
	*/

	is_mobile = mobile()

	image_modal.init()



	nodes.blend_mode_clip.addEventListener('scroll', (e) => {

		let s = nodes.blend_mode_clip.scrollTop / state.window_size.h

		if (s === 0) {

			// reset
			scroll_cache = s

		}

		else {

			scroll_diff = s - scroll_cache
			scroll_cache = s

		}

	})


	if (is_mobile) {

		nodes.body.classList.add('mobile')

		let lastTouch = null

		// ADD listeners to fix some jank scrolling in iOS safari & chrome, and android chrome

		nodes.blend_mode_parent.addEventListener('touchmove', (e) => {

			let valid =
				(utils.walking_class_test(e.target, 'blend-mode-wrap') !== false)
				&&
				(nodes.blend_mode_wrap.clientHeight > nodes.blend_mode_clip.clientHeight)

			if (!valid) {
				e.preventDefault()
				let currentTouch = event.changedTouches[0]
				if (lastTouch) {
					nodes.blend_mode_clip.scrollTop -= currentTouch.screenY - lastTouch.screenY
				}
				lastTouch = currentTouch
			}

			nodes.blend_mode_inner.click()

		}, { passive: false })

		nodes.blend_mode_parent.addEventListener('click', (e) => {
			let valid =
				(utils.walking_class_test(e.target, 'blend-mode-wrap') !== false)

			if (!valid) {
				e.preventDefault()

			}

			nodes.blend_mode_inner.click()

		})

		nodes.blend_mode_parent.addEventListener('touchstart', (e) => {

			lastTouch = event.touches[0]

			let valid =
				(utils.walking_class_test(e.target, 'blend-mode-wrap') !== false)

			if (!valid) {
				e.preventDefault()

			}

		}, { passive: false })

		nodes.blend_mode_parent.addEventListener('touchend', (e) => {
			lastTouch = null
		}, { passive: false })

		nodes.blend_mode_parent.addEventListener('toucancel', (e) => {
			lastTouch = null
		}, { passive: false })



		window.addEventListener('orientationchange', (e) => {

			resize_handler()

		})



		window.addEventListener('focus', () => {

			world.ensure_video_playing()

			project.ensure_video_playing()

		})



	}

	// Loads from bfcache

	window.addEventListener('pageshow', () => {

		world.ensure_video_playing()

		project.ensure_video_playing()

	})

}


export let read_scroll_diff = () => {

	const d = scroll_diff
	scroll_diff = 0.0
	return d

}


export let sync_body_class = (template) => {

	for (let t in routes) {
		nodes.body.classList.remove(`section-${t}`)
	}

	nodes.body.classList.add(`section-${template}`)

}


export let sync_heights = (force) => {

	if (transitions.status.transitioning && !force) {
		// console.log('blocked')
		return
	}

	// console.error( 'sync heights' )

	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);

	let container = utils.qsa('.container-inner')
	if (container.length === 1) {
		nodes.blend_mode_wrap.style.height = container[0].clientHeight + 'px'
	}
	else if (container.length === 2) {
		nodes.blend_mode_wrap.style.height = container[1].clientHeight + 'px'
	}
	else {
		console.error('failed to sync heights')
	}


}


export let clip_heights = () => {

	nodes.blend_mode_wrap.style.height = state.window_size.h + 'px'
	console.log(nodes.blend_mode_wrap.style.height)

}


let resize_handler = () => {

	sync_heights()

	sync_window_size_state()

	world.resize_handler()

	build_image_grid()

	overlay_resize_handler()

}


export let sync_window_size_state = () => {

	let w = window.innerWidth // nodes.background_outer.clientWidth
	let h = window.innerHeight // nodes.background_outer.clientHeight
	let s = window.getComputedStyle(nodes.blend_mode_clip)
	let b = Math.max(
		Number(s.marginTop.replace('px', '')),
		Number(s.borderWidth.replace('px', ''))
	)

	state.window_size = {
		w: w,
		h: h,
		aspect: w / h,
		d: (new Vec2(w, h).len()),
		px: Math.max(1, window.devicePixelRatio),
		u_px_norm: new Vec2(w, h),
		aspect_vec: new Vec2(1.0, w / h),
		x: 0,
		y: 0,
		u: 1.0 / w,
		v: 1.0 / h,
		b: b
	}

}


export let toggle_blendmode = (boo) => {
	let action = !boo ? 'add' : 'remove'
	nodes.blend_mode_clip.classList[action]('no-blend')
}

export let prep_page_header = () => {

	let el = utils.qs('.container-inner.animate-in .page-header[data-header-replace="true"]')

	// CREATE new incoming page header
	if (el) {
		let d = document.createElement('div')
		d.classList.add('page-header-inner')
		d.classList.add('incoming')
		d.innerHTML = el.innerHTML
		nodes.home.page_header.appendChild(d)
	}
	else {
		console.error('cannot populate page header, not found in template')
	}

}


export let finalize_page_header = () => {

	let _old = utils.qs('.page-header-inner.existing')
	let _new = utils.qs('.page-header-inner.incoming')

	// FIRST time 
	if (_new && !_old) {
		_new.classList.remove('incoming')
		_new.classList.add('existing')
	}
	// ALL SUBSEQUENT times
	else if (_old && _new) {
		_old.remove()
		_new.classList.remove('incoming')
		_new.classList.add('existing')
	}

}


export let reset_scroll = () => {

	nodes.blend_mode_clip.scrollTop = 0

}



// SIMPLE state object for tracking certain states across the app

export let state = {

	is_mobile: false,

	window_size: {}

}