var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var knot = function knot() {
  var extended = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  var events = Object.create(null);

  function on(name, handler) {
    events[name] = events[name] || [];
    events[name].push(handler);
    return this;
  }

  function once(name, handler) {
    handler._once = true;
    on(name, handler);
    return this;
  }

  function off(name) {
    var handler = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

    handler ? events[name].splice(events[name].indexOf(handler), 1) : delete events[name];

    return this;
  }

  function emit(name) {
    var _this = this;

    for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }

    // cache the events, to avoid consequences of mutation
    var cache = events[name] && events[name].slice();

    // only fire handlers if they exist
    cache && cache.forEach(function (handler) {
      // remove handlers added with 'once'
      handler._once && off(name, handler);

      // set 'this' context, pass args to handlers
      handler.apply(_this, args);
    });

    return this;
  }

  return _extends({}, extended, {

    on: on,
    once: once,
    off: off,
    emit: emit
  });
};

export default (function () {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  // options

  var settings = {
    normal: options.normal || 'data-normal',
    // retina: options.retina || 'data-retina',
    // srcset: options.srcset || 'data-srcset',
    threshold: options.threshold || 0,
    container: options.container || document.body,
    bot_mode: options.bot_mode || false
  };

  // private

  var prevLoc = getLoc();
  var ticking = void 0;

  var nodes = void 0;
  var loaded_nodes = void 0;
  var unloaded_nodes = void 0;

  var windowHeight = void 0;

  // feature detection
  // https://github.com/Modernizr/Modernizr/blob/master/feature-detects/img/srcset.js

  var srcset = document.body.classList.contains('srcset') || 'srcset' in document.createElement('img');

  // device pixel ratio
  // not supported in IE10 - https://msdn.microsoft.com/en-us/library/dn265030(v=vs.85).aspx

  var dpr = window.devicePixelRatio || window.screen.deviceXDPI / window.screen.logicalXDPI;

  // instance

  var instance = knot({
    handlers: handlers,
    check: check,
    update: update
  });

  return instance;

  // location helper

  function getLoc() {
    return settings.container.scrollTop;
  }

  // debounce helpers

  function requestScroll() {
    prevLoc = getLoc();
    requestFrame();
  }

  function requestFrame() {
    if (!ticking) {
      window.requestAnimationFrame(function () {
        return check();
      });
      ticking = true;
    }
  }

  // offset helper

  function getOffset(node) {
    return node.getBoundingClientRect().top + prevLoc;
  }

  // in viewport helper

  function inViewport(node, log) {
    if ( settings.bot_mode ) {
      return true
    }

    var viewTop = prevLoc;
    var viewBot = viewTop + windowHeight;

    var nodeTop = getOffset(node);
    var nodeBot = nodeTop + node.parentElement.offsetHeight;

    var offset = settings.threshold * windowHeight;

    var in_view = ( nodeBot >= viewTop && nodeTop <= viewBot + offset );

    // if ( log ) {
    //   console.log( nodeBot, nodeTop, viewTop + offset, in_view ) 
    // }

    // if ( in_view && !node.classList.contains('in-viewport') )  {
    //   node.classList.add('in-viewport')
    // }
    // else if ( !in_view && node.classList.contains('in-viewport') ) {
    //   node.classList.remove('in-viewport')
    // }

    return in_view

  }

  // source helper

  function reloadSource(node) {
   
    instance.emit('src:reload', node )

    update();

  }


  function unloadSource(node) {
   
    instance.emit('src:unload', node )

    update();

  }


  function setSource(node) {

    // console.log( 'set', node )

    instance.emit('src:before', node)

    // node.setAttribute( 'src', node.getAttribute( settings.normal ) )

    // node.dataset.loaded = true

    instance.emit('src:after', node);

    update();

  }

  // API

  function handlers(flag) {
    var action = flag ? 'addEventListener' : 'removeEventListener';['scroll', 'resize'].forEach(function (event) {
      return settings.container[action](event, requestScroll);
    });
    return this;
  }

  function check() {
    windowHeight = settings.container.clientHeight;
    

    // console.log( 'unloaded:', unloaded_nodes.length )

    // nodes that loaded and need to unload
    unloaded_nodes.forEach(function (node) {

      let in_view = inViewport( node )

      if ( in_view ) {
        reloadSource( node )
      }

    });


    // console.log( 'loaded:', loaded_nodes.length )

    // nodes that loaded and need to unload
    loaded_nodes.forEach(function (node) {

      let in_view = inViewport( node )

      if ( !in_view ) {
        unloadSource( node )
      }

    });


    // console.log( 'brand new:', nodes.length )
    // brand new nodes
    nodes.forEach(function (node) {
      
      let in_view = inViewport(node)

      if ( in_view ) {
        setSource( node )
      }

    });




    ticking = false;
    return this;
  }

  function update() {

    nodes = Array.prototype.slice.call(document.querySelectorAll('[data-normal]'));
    loaded_nodes = Array.prototype.slice.call(document.querySelectorAll('[data-loaded="true"]'));
    unloaded_nodes = Array.prototype.slice.call(document.querySelectorAll('[data-unloaded="true"]'));

    // must be brand new nodes, not loading
    nodes = nodes.filter( n => {
      return ( n.dataset.loaded !== 'true' && n.dataset.loading !== 'true' )
    } )

    // must be not unloaded, or loading
    loaded_nodes = loaded_nodes.filter( n => {
      return ( n.dataset.unloaded !== 'true' && n.dataset.loading !== 'true' )
    } )

    return this;

  }
});