<div class="section project">
	
	<div class="page-header project-header" data-header-replace="true">
		<strong>{{ projectName }}</strong>
	</div>

	<div class="hero-wrap">
			
		{{#if projectHeroVideo }}		
		<a class="video-container embedly-card" href="{{ projectHeroVideo }}">

		</a>
		{{else}}
		<div class="image-container">
			<span class="img-wrap loading" data-width="{{ projectHero.width }}" data-height="{{ projectHero.height }}" style="padding-bottom: 56.25%;">
				<img width="0" height="0" data-normal="{{ projectHero.url }}"/>
			</span>
		</div>
		{{/if }}

	</div>

	<div class="info-wrap">

		<div class="info-module {{#unless projectAgency }}wide{{/unless }}">
			<div class="info-label"> Client </div>
			<div class="info-value"> {{ projectClient }} </div>
		</div>

		{{#if projectAgency }}
		<div class="info-module">
			<div class="info-label"> Agency </div>
			<div class="info-value"> {{ projectAgency }} </div>
		</div>
		{{/if }}

		<div class="info-module">
			<div class="info-label"> Role </div>
			<div class="info-value"> {{ projectRole }} </div>
		</div>

		<div class="info-module">
			<div class="info-label"> Duration </div>
			<div class="info-value"> {{ projectDuration }} </div>
		</div>

		<div class="info-module wide">
			<div class="info-label"> Technologies </div>
			{{#each projectTechnologies }}
			<div class="info-value multi"> {{ this }} </div>
			{{/each }}

		</div>

	</div>


	{{#if projectLink }}
	<div class="link-wrap">
		<div class="link project-link" data-external="{{ projectLink }}">
			<div> Visit {{ projectName }} </div>
		</div>
	</div>
	{{/if }}

	<div class="intro-wrap">
		<div class="intro"> {{{ projectIntro }}} </div>
	</div>


	<div class="project-body-wrap">
		<div class="project-body"> {{{ projectBody }}} </div>
	</div>


	<div class="next-wrap">
		
		<div class="cue-block next">

			<div class="next-label">
				Next project
			</div>

			<a class="link next-project" data-slug="{{ nextProject.projectSlug }}" data-link="true" href="/project/{{ nextProject.projectSlug }}">
				<p class="project-name"><span>{{ nextProject.projectName }}</span></p>
				<p class="project-desc"><span>{{ nextProject.projectClient }}{{#if nextProject.projectAgency }}, {{ nextProject.projectAgency }}{{/if }}</span></p>
			</a>

		</div>

		<div class="cue-block back">
			<a class="link back-projects" data-link="true" href="/projects"> 
				<div> Projects </div>
			</a>
		</div>

	</div>

</div>