// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - IMPORTS

import { gsap }				from 'gsap'

import { 
	Renderer, 
	Program, 
	Color, 
	Mesh, 
	Triangle, 
	Vec2, 
	Texture
}							from 'ogl'

// import * as Broadway		from 'broadway-player'
import * as Broadway		from 'broadway_mp4'

import { 
	state, 
	prep_page_header, 
	sync_window_size_state,
	read_scroll_diff
} 							from './../app/controller.js'

import { nodes }			from './../components/nodes.js'
import * as utils			from './../components/utils.js'
import Lag 					from './../components/lag'
import { all as content } 	from './../components/content.js'
import * as loader 			from './../components/loader.js'
import * as overlay 		from './../sections/overlay.js'
import bg_frag 				from './../../glsl/background/video-frag.glsl'
import bg_vert 				from './../../glsl/background/video-vert.glsl'
import is_mobile			from './../components/mobile.js'

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - GLOBALS


export let world = {

	videos: 			{},

	video_loaded:		false,
	renderer:			null,
	video_texture:		null,

	video_texture_y:	null,
	video_texture_u:	null,
	video_texture_v:	null,

	video_time_cache:	0.0,
	video_frame_cache:	0,
	program:			null,
	mesh:				null,
	active:				false,

	decoding: 			false

}


export let uniforms = {
	
	background: {

		u_time: { value: 0 },
		u_resolution: { value: [ 0, 0 ] },
		u_dpr: { value: 1 },
		u_mouse: { value: [ 0, 0 ] },
		u_mouse_zip: { value: [ 0, 0 ] },
		u_mouse_vel: { value: [ 0, 0 ] },
		u_border: { value: 0 },
		u_border_tween: { value: 1 },
		u_homepage: { value: 0 },
		u_projects: { value: 0 },
		u_project: { value: 0 },
		u_curtain: { value: 0 },
		u_use_broadway: { value: 0 },

		u_vertical_video: { value: is_mobile() ? 1 : 0 },

		u_video: { value: null },

		u_video_y: { value: null },
		u_video_u: { value: null },
		u_video_v: { value: null },

		u_project_colors: {
			value: [
				[ 0.0, 0.0, 0.0 ],
				[ 1.0, 0.0, 0.0 ],
				[ 0.0, 1.0, 0.0 ],
				[ 0.0, 0.0, 1.0 ],
				[ 1.0, 1.0, 0.0 ],
				// [ 0.0, 1.0, 1.0 ],
				// [ 1.0, 0.0, 1.0 ],
				// [ 1.0, 1.0, 1.0 ],
				// [ 0.2, 0.2, 0.2 ],
				// [ 0.7, 0.7, 0.7 ]
			]
		},

		u_num_project_colors: { value: 3 },

		u_project_scroll_pos: { value: 0 }
		

	}

}


export let mouse = {

	hover_item:		false,
	hover_world:	false,
	norm:			new Vec2( -1.0, -1.0 ),
	px:				new Vec2(),
	vel:			new Vec2(),
	down:			false,
	diff:			false,
	norm_lag:		new Lag( 
						{ x: -1.0, y: -1.0 },
						{
							format: 'tail',
							buffer_length: 240
						}
					),
	norm_lag_zip:	new Lag( 
						{ x: -1.0, y: -1.0 },
						{
							format: 'tail',
							buffer_length: 45
						}
					),
	vel_mag_lag:	new Lag (
						0,
						{
							format: 'tail',
							buffer_length: 60
						}
					)

}


let project_colors_lag = []

let project_colors_cur = []

let project_colors_count_cur = 3

let project_colors_count_lag = new Lag( 
	project_colors_count_cur,
	{
		format: 'average',
		buffer_length: 15
	}
)

for ( let i=0; i<5; i++ ) {

	project_colors_lag.push( 
		new Lag( 
			uniforms.background.u_project_colors.value[ i ],
			{
				format: 'average',
				buffer_length: 15
			}
		)
	)

	project_colors_cur.push(
		[...uniforms.background.u_project_colors.value[ i ] ]
	)



}


export let set_project_colors = ( project_arr ) => {

	let num = project_arr.length

	project_colors_count_cur = 5 //num


	for ( let i=0; i<5; i++ ) {

		// USE actual color
		if ( i<num ) {

			project_colors_cur[ i ] = utils.hex_to_rgba_array_norm( '#' + project_arr[ i ] )

		}

		// OVERWRITE with black
		else {

			project_colors_cur[ i ] = [ 0.0, 0.0, 0.0 ]

		}

	}

}




export let mouse_cache = utils.copy_obj( mouse )

mouse_cache.norm =	new Vec2( mouse_cache.norm[0],	mouse_cache.norm[1] )
mouse_cache.px =	new Vec2( mouse_cache.px[0],	mouse_cache.px[1]	)
mouse_cache.vel =	new Vec2( mouse_cache.vel[0],	mouse_cache.vel[1]	)



let bounds = {
	lo: 0,
	hi: 4.0
}

let f = 1 / 24.0

let video_frame = 0
let time_changed = performance.now()
let this_second = new Date().getSeconds()
let video_frame_count = 0
let render_frame_count = 0



// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  INITIALIZE

export let build_world = () => {

	let BOT_MODE = window.location.search.includes('bot_mode')
	if ( BOT_MODE ) {
		return
	} 


	// RENDERER
	world.renderer = new Renderer()
	nodes.background_outer.innerHTML = ''
	nodes.background_outer.appendChild( world.renderer.gl.canvas )
	world.renderer.gl.clearColor( 1, 1, 1, 1 )
	world.renderer.dpr = state.window_size.px

	// SINGLE fullscreen triangle mesh with shader texture
	world.triangle = new Triangle( world.renderer.gl )

	// FLAG for custom or standard decoder
	uniforms.background.u_use_broadway.value = loader.USE_BROADWAY
		? 1
		: 0

	// LOAD shaders into program
	world.program = new Program( world.renderer.gl, {
		vertex: bg_vert,
		fragment: bg_frag,
		uniforms: uniforms.background,
	} )

	// APPLY to mesh
	world.mesh = new Mesh( 
		world.renderer.gl,
		{
			geometry: world.triangle,
			program: world.program
		}
	)

	// APPLY window dimensions
	resize_handler()




	// BUILD offscreen html5 video element or custom Broadway decoder
	if ( loader.video_out ) {
		
		init_video()
	
	}
	else {

		// INIT dummy standard video textures until video is loaded
		world.video_texture = new Texture( 
			world.renderer.gl,
			{
				generateMipmaps: false,
				width: 192,
				height: 108,
			}
		)
		world.video_texture_y = new Texture( 
			world.renderer.gl,
			{ generateMipmaps: false, width: 192, height: 108 }
		)
		world.video_texture_u = new Texture( 
			world.renderer.gl,
			{ generateMipmaps: false, width: 192, height: 108 }
		)
		world.video_texture_v = new Texture( 
			world.renderer.gl,
			{ generateMipmaps: false, width: 192, height: 108 }
		)

		// ATTACH to shader uniforms
		uniforms.background.u_video_y.value = world.video_texture_y
		uniforms.background.u_video_u.value = world.video_texture_u
		uniforms.background.u_video_v.value = world.video_texture_v
		uniforms.background.u_video.value = world.video_texture

	}
	// ADD event listeners
	document.addEventListener( 'mousemove', 		mouse_move,		{ passive: false } )
	document.addEventListener( 'drag',				mouse_move,		{ passive: false } )
	document.addEventListener( 'touchmove', 		touchmove,		{ passive: false } )

	// ACTIVATE the update loop
	world.active = true
	gsap.ticker.add( update )

}


/*
let request_motion = async () => {

	if (
		DeviceOrientationEvent.requestPermission &&
		typeof DeviceMotionEvent.requestPermission === 'function'
	) {

		let permission = await DeviceOrientationEvent.requestPermission()

		if ( permission == 'granted' ) {
			window.addEventListener( 'deviceorientation', device_tilt, true )
		}

	}
	else {

		window.addEventListener( 'deviceorientation', device_tilt, true )

	}

}
*/



// - - - INIT VIDEO

let init_video = () => {


	if ( loader.USE_BROADWAY ) {

		world.decoder = new Broadway.Decoder({
			rgb: false,
			useWorker: true,
			workerFile: '/assets/Decoder.js'
		} )

		world.decoder.onPictureDecoded = ( buffer, width, height, infos ) => {

			decode_cache.buffer	= buffer
			decode_cache.width	= width
			decode_cache.height	= height

			world.decoding		= false

		}

		world.reader = new Broadway.MP4Reader( new Broadway.Bytestream( loader.video_out ) )
		world.reader.read()
		
		world.video_data_track = world.reader.tracks[ 1 ]

		
		let size = new Broadway.Size(
			world.video_data_track.trak.tkhd.width,
			world.video_data_track.trak.tkhd.height
		)

		// INIT YUV uniform textures
		world.video_texture_y = new Texture( 
			world.renderer.gl,
			{
				generateMipmaps: false,
				width: size.w,
				height: size.h,
				internalFormat: world.renderer.gl.LUMINANCE,
				format: world.renderer.gl.LUMINANCE,
				type: world.renderer.gl.UNSIGNED_BYTE
			}
		)

		world.video_texture_u = new Texture( 
			world.renderer.gl,
			{
				generateMipmaps: false,
				width: size.w * 0.5,
				height: size.h * 0.5,
				internalFormat: world.renderer.gl.LUMINANCE,
				format: world.renderer.gl.LUMINANCE,
				type: world.renderer.gl.UNSIGNED_BYTE
			}
		)

		world.video_texture_v = new Texture( 
			world.renderer.gl,
			{
				generateMipmaps: false,
				width: size.w * 0.5,
				height: size.h * 0.5,
				internalFormat: world.renderer.gl.LUMINANCE,
				format: world.renderer.gl.LUMINANCE,
				type: world.renderer.gl.UNSIGNED_BYTE
			}
		)

		// INIT dummy standard video texture
		world.video_texture = new Texture( 
			world.renderer.gl,
			{
				generateMipmaps: false,
				width: 192,
				height: 108,
			}
		)

		// EXTRACT track info to begin decode
		var avc = world.reader.tracks[ 1 ].trak.mdia.minf.stbl.stsd.avc1.avcC
		var sps = avc.sps[ 0 ]
		var pps = avc.pps[ 0 ]

		// Decode Sequence & Picture Parameter Sets
		world.decoder.decode( sps )
		world.decoder.decode( pps )

		decode_frame()

		world.video_loaded = true
		
	}
	else {

		// INIT standard video texture
		world.video_texture = new Texture( 
			world.renderer.gl,
			{
				generateMipmaps: false,
				width: 1920,
				height: 1080,
			}
		)

		// INIT dummy custom YUV textures
		world.video_texture_y = new Texture( 
			world.renderer.gl,
			{ generateMipmaps: false, width: 192, height: 108 }
		)
		world.video_texture_u = new Texture( 
			world.renderer.gl,
			{ generateMipmaps: false, width: 192, height: 108 }
		)
		world.video_texture_v = new Texture( 
			world.renderer.gl,
			{ generateMipmaps: false, width: 192, height: 108 }
		)

		if ( loader.USE_BUNDLE ) {
			
			// CREATE offscreen html video element
			let temp = document.createElement( 'div' )
			temp.innerHTML = 
				`<video autoplay muted loop playsinline>
					<source src="" type="video/mp4"/>
				</video>`

			// STORE reference to offscreen node
			world.video = temp.querySelector( 'video' )

			// ENFORCE playhead boundaries 
			loop_video_bounds()

			// SET source of html video element to preloaded data
			world.video.src = loader.video_out

			// ADD listener to begin video
			world.video.addEventListener( 'loadeddata', ( data ) => {

				// SET loaded flag
				world.video_loaded = true

				// BEGIN video playback
				world.video.play()

				// ATTACH video to the empty texture
				world.video_texture.image = world.video

			} )

		}

		else {

			// NOT USING
			// CREATE a video element for each project
			for ( let a in loader.videos_out ) {

				// CREATE offscreen html video element
				let temp = document.createElement( 'div' )
				temp.innerHTML = 
					`<video autoplay muted loop playsinline>
						<source src="" type="video/mp4"/>
					</video>`


				// STORE reference to offscreen node
				world.videos[ a ] = {
					el: temp.querySelector( 'video' ),
					loaded: false
				}

				world.videos[ a ].el.src = loader.videos_out[ a ]

				world.videos[ a ].el.addEventListener( 'loadeddata', ( data ) => {
					world.videos[ a ].loaded = true
					world.videos[ a ].el.pause()
					
					if ( active_video === 'home' ) { 
						set_video_bounds( a )
					}

				} )

			}

		}

	}

	// ATTACH to shader uniforms (do again if we only attached dummies)
	uniforms.background.u_video_y.value = world.video_texture_y
	uniforms.background.u_video_u.value = world.video_texture_u
	uniforms.background.u_video_v.value = world.video_texture_v
	uniforms.background.u_video.value = world.video_texture

}



export let video_downloaded_deferred = () => {

	// world.video.src = loader.video_out

	init_video()

}



let decode_debounce

const decode_frame = () => {

	// only do the video work if were on the projects page
	if ( video_frame_count > 0 && uniforms.background.u_projects.value == 0.0 ) return

	// let now = performance.now()
	if ( world.decoding ) return
	// console.log('decoding')
	world.decoding = true

	clearTimeout( decode_debounce )

	decode_debounce = setTimeout( () => {
		world.decoding = false
	}, 160 )

	world.video_data_track.getSampleNALUnits( world.video_frame_cache ).forEach( function ( nal ) {
		world.decoder.decode(nal);
	});

	video_frame_count++

}

let decode_cache = {
	buffer: null,
	width: null,
	height: null
}

const draw_frame = () => {

	if ( !decode_cache.buffer ) return

	let ylen = decode_cache.width * decode_cache.height;
	let uvlen = (decode_cache.width / 2) * (decode_cache.height / 2);

	let yData = decode_cache.buffer.subarray( 0, ylen )
	let uData = decode_cache.buffer.subarray( ylen, ylen + uvlen )
	let vData = decode_cache.buffer.subarray( ylen + uvlen, ylen + uvlen + uvlen )

	let yDataPerRow = decode_cache.width
	let yRowCnt     = decode_cache.height

	let uDataPerRow = decode_cache.width / 2
	let uRowCnt     = decode_cache.height / 2

	let vDataPerRow = uDataPerRow
	let vRowCnt     = uRowCnt

	world.video_texture_y.image = yData
	world.video_texture_u.image = uData
	world.video_texture_v.image = vData

	world.video_texture_y.needsUpdate = true
	world.video_texture_u.needsUpdate = true
	world.video_texture_v.needsUpdate = true

}


// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RUNTIME


/*
let device_tilt = ( e ) => {

	let x = utils.normalize( e.gamma, 	45, -45, true )
	let y = utils.normalize( e.beta,	45, -45, true )

	y = utils.mix( y, 0, state.window_size.h )
	x = utils.mix( x, 0, state.window_size.w )

	if ( !mouse_moving ) {

		let e = { 
			clientX: x,
			clientY: y
		}

		mouse_move( e, true )

	}


}
*/

let mouse_moving = false


let mouse_move_stop = () => {

	mouse_moving = false

}


let mouse_move_stop_timeout




let mouse_move = ( e, tilting ) => {

	if ( !tilting ) {
		
		clearTimeout( mouse_move_stop_timeout )
		mouse_move_stop_timeout = setTimeout( mouse_move_stop, 1000 )
		mouse_moving = true

	}

	let m = {
		x: 0,
		y: 0
	}

	if ( e.clientX !== undefined && e.clientY !== undefined ) {
		m.x = e.clientX
		m.y = e.clientY
	}
	else if ( e.x !== undefined && e.y !== undefined ) {
		m.x = e.x + state.window_size.x
		m.y = e.y + state.window_size.y
	}
	else {
		console.error( 'bad mousemove input:', e )
	}

	// PIXEL coords limited to webgl canvas
	mouse.px.x = utils.clamp( m.x - state.window_size.x, 0, state.window_size.w )
	mouse.px.y = utils.clamp( m.y - state.window_size.y, 0, state.window_size.h )

	// NORMALIZED, -1 to 1 in both dimensions
	mouse.norm.x =  1 * ( ( m.x - state.window_size.x ) / state.window_size.w * 2 - 1 )
	mouse.norm.y = -1 * ( ( m.y - state.window_size.y ) / state.window_size.h * 2 - 1 )

	// FLAG if user interacting with scene, including UI overlays
	mouse.hover_world = ( 
		-1 <= mouse.norm.x && mouse.norm.x <= 1 &&
		-1 <= mouse.norm.y && mouse.norm.y <= 1 
	)

	// CLAMP, only after testing for mouse.hover_world
	mouse.norm.x = utils.clamp( mouse.norm.x, -1, 1 )
	mouse.norm.y = utils.clamp( mouse.norm.y, -1, 1 )

	mouse.down = e.buttons > 0 ? 1 : 0

}


let touchmove = ( event ) => {

	let t = event.touches[ 0 ]

	let e = {
		x: t.clientX,
		y: t.clientY,
		buttons: 1
	}

	mouse_move( e )

	if ( event.scale !== 1 ) { 
		event.preventDefault()
		event.scale = 1
	}
}



let active_video = 'home'

export let set_video_bounds = ( which ) => {


	if ( !loader.USE_BUNDLE ) {

		// DIFF video selected
		if ( active_video !== which ) {

			if ( which !== 'home' ) {

				// PAUSE any playing videos
				for ( let a in world.videos ) {
					if ( !world.videos[ a ].el.paused && a !== which ) {
						world.videos[ a ].el.pause()
					}
				}

				// PLAY the new video
				if ( world.videos[ which ].el.paused ) {
					world.videos[ which ].el.play()
					world.video_texture.image = world.videos[ which ].el
				}				
				
				// UPDATE ref
				active_video = which

			}

		}
		
	}
	else if ( !loader.USE_BROADWAY ) {

		if ( which == 'home' ) {
			bounds.lo = 0
			bounds.hi = content.content.video.dur.sec
		}
		else {
			bounds.lo = content.content.video.scenes[ which ].in.sec
			bounds.hi = content.content.video.scenes[ which ].out.sec
		}

	}
	else {

		if ( which == 'home' ) {
			bounds.lo = 0
			bounds.hi = content.content.video.dur.frame
		}
		else {
			bounds.lo = content.content.video.scenes[ which ].in.frame
			bounds.hi = content.content.video.scenes[ which ].out.frame
		}

	}

} 




let loop_video_bounds = ( delta_time ) => {

	if ( !loader.USE_BUNDLE ) {

		// world.video.src = loader.videos_out[ 'veritas' ]

	}
	else if ( world.video_loaded && !loader.USE_BROADWAY ) {

		world.video_time_cache = world.video.currentTime + 0.0;
 


		if ( bounds.hi > bounds.lo ) {

			if ( 
				world.video_time_cache < bounds.lo + f * 0.0 ||
				world.video_time_cache > bounds.hi - f * 2.0
			) {
				
				world.video.currentTime = bounds.lo + f
			
			}

		}
		else {

			if ( 
				world.video_time_cache > bounds.hi - f * 2.0 &&
				world.video_time_cache < bounds.lo + f * 0.0
			) {
				
				world.video.currentTime = bounds.lo + f

			}

		}

	}
	else if ( loader.USE_BROADWAY ) {

		if ( world.decoding ) return

		// advance time delta
		world.video_time_cache += ( delta_time / 1000 )

		// convert to working frame index
		let ff = Math.floor( world.video_time_cache / f )

		// apply bounds
		if ( 
			ff < bounds.lo ||
			ff >= bounds.hi - 1
		) {

			ff = bounds.lo + 0
			world.video_time_cache = ff * f

		}

		// need to decode new frame
		if ( world.video_frame_cache != ff ) {

			world.video_frame_cache = ff + 0
			decode_frame()

		}

	}

}


// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  UPDATE HANDLERS

let update = ( time, delta_time, frame ) => {

	if ( !world.active ) return


	// - - - MOUSE step

	mouse.diff = (
		mouse.px.x !== mouse_cache.px.x || mouse.px.y !== mouse_cache.px.y
	)

	mouse.vel.x = mouse.px.x - mouse_cache.px.x
	mouse.vel.y = mouse.px.y - mouse_cache.px.y

	mouse.norm_lag.tick( {
		x: mouse.norm.x,
		y: mouse.norm.y
	} )

	mouse.norm_lag_zip.tick( {
		x: mouse.norm.x,
		y: mouse.norm.y
	} )



	// - - - PROJECT page step

	project_colors_lag.forEach( ( x, i ) => {
		x.tick( project_colors_cur[ i ] )
	} )

	project_colors_count_lag.tick( project_colors_count_cur )

	uniforms.background.u_project_scroll_pos.value += read_scroll_diff()

	// console.log( uniforms.background.u_project_scroll_pos.value )


	// let num_tails = 10
	// let tail_length = Math.floor( mouse.norm_lag.buffer_length / num_tails )
	
	// let ticks = []
	// for ( let i=0; i<num_tails; i++ ) {
	// 	uniforms.background.u_mouse_lag.value[ i ][ 0 ] = mouse.norm_lag.buffer[ i*tail_length ][ 0 ]
	// 	uniforms.background.u_mouse_lag.value[ i ][ 1 ] = mouse.norm_lag.buffer[ i*tail_length ][ 1 ]
	// }


	let vel_factor = mouse.vel_mag_lag.tick( mouse.vel.len() )

	mouse_cache = utils.copy_obj( mouse )
	mouse_cache.norm = new Vec2( mouse_cache.norm[0], mouse_cache.norm[1] )
	mouse_cache.px = new Vec2( mouse_cache.px[0], mouse_cache.px[1] )
	mouse_cache.vel = new Vec2( mouse_cache.vel[0], mouse_cache.vel[1] )

	mouse.down = 0

	overlay.update()


	// - - - SHADER uniforms 

	if ( world.mesh ) {

		uniforms.background.u_time.value +=
			( delta_time / 1000 ) + vel_factor * 0.004

		if ( state.window_size ) {

			uniforms.background.u_resolution.value = [ 
				state.window_size.w,
				state.window_size.h
			]

			uniforms.background.u_dpr.value = state.window_size.px

		}
		
		uniforms.background.u_mouse.value = [ 
			mouse.norm_lag.output.x,
			mouse.norm_lag.output.y
		]

		uniforms.background.u_mouse_zip.value = [ 
			mouse.norm_lag_zip.output.x,
			mouse.norm_lag_zip.output.y
		]

		uniforms.background.u_mouse_vel.value = [
			// mouse.vel.x,
			// mouse.vel.y
			vel_factor,
			0.0
		]

		uniforms.background.u_project_colors.value.forEach( ( x, i ) => {
			uniforms.background.u_project_colors.value[ i ] = project_colors_lag[ i ].output
		} )

		uniforms.background.u_num_project_colors.value = project_colors_count_lag.output


		uniforms.background.u_border.value = state.window_size.b


		// ONLY do video stuff if its ready
		if ( world.video_loaded ) {

			loop_video_bounds( delta_time )

			if ( !loader.USE_BUNDLE && active_video !== 'home' && world.videos[ active_video ].loaded ) {
				world.video_texture.needsUpdate = true
			}
			else if ( loader.USE_BUNDLE && world.video_loaded ) {
				world.video_texture.needsUpdate = true	
			}
			
			if ( loader.USE_BROADWAY ) {

				// optimized inside of this function, aborts if not needed
				draw_frame()

			}
			else {

				// optimize desktop video playback

				let should_pause = uniforms.background.u_projects.value == 0.0
				let is_paused = world.video.paused

				if ( should_pause !== is_paused ) {
					if ( should_pause ) {
						world.video.pause()
					}
					else {
						world.video.play()
					}
				}

			}
			
		}

	}


	// RENDER
	world.renderer.render( { scene: world.mesh } )


	// - - - PERFORMANCE debug stats

	/*
	if ( process.env.NODE_ENV === 'development' ) {

		render_frame_count++


		// let frame = Math.round( world.video_time_cache * 24.0 )

		// if ( world.video_frame_cache !== video_frame ) {
		// 	video_frame = frame + 0.0
		// 	video_frame_count++
		// }

		let s = new Date().getSeconds()
		let new_second =  s!== this_second
		if ( s !== this_second ) {

			nodes.perf.frame.innerHTML = `WebGL Render: ${ render_frame_count }ms`
			nodes.perf.video.innerHTML = `Video FPS: ${ video_frame_count }`

			this_second = s
			video_frame_count = 0
			render_frame_count = 0

		}

	}
	*/

}


export let resize_handler = () => {

	world.renderer.setSize( state.window_size.w, state.window_size.h )
	// console.log( 'set size ', world.renderer, state.window_size.px )
	
	// world.renderer.width = state.window_size.w * state.window_size.px
	// world.renderer.height = state.window_size.h * state.window_size.px


	// world.renderer.gl.canvas.width = state.window_size.w * state.window_size.px
	// world.renderer.gl.canvas.height = state.window_size.h * state.window_size.px

	// world.renderer.gl.canvas.style.width = state.window_size.w
	// world.renderer.gl.canvas.style.height = state.window_size.h


}


export let ensure_video_playing = () => {

	// VIDEO will stop playing if tab loses focus, make sure it is playing when
	// we come back
	if ( loader.USE_BUNDLE && world.video && world.video.paused && world.video_loaded ) {
		world.video.play()
	}

}