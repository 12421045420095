<div class="section home">


	<!-- hidden site header -->
	<div class="title-wrap hidden">

		<div class="title">
			<div> {{{ greeterTitle }}} </div>
		</div>

		<div class="subtitle">
			
		</div>

	</div>

	<div class="page-header home-header" data-header-replace="true">
		{{{ greeterSubtitle }}}
	</div>

	<!-- greeter body content -->
	<div class="body-wrap">

		<h1 class="header">
			{{{ greeterHeader }}}
		</h1>

	</div>


	<!-- projects link -->
	<div class="projects-link-wrap">

		<div class="defer-loader">
			<div class="bar"> </div>
			<div class="label"> Loading... </div>
		</div> 

		<a class="projects-link link" href="/projects" data-link="true"> 
			<div> {{ projectLink }} </div>
		</a>
		
		<a class="about-link link" href="/about" data-link="true"> 
			<div> {{ aboutLink }} </div>
		</a>

	</div>

	<!-- hidden site footer -->
	<div class="footer-wrap hidden">

		<div class="footer-wrap-inner">

			<div class="link footer-icon github-icon" data-external="{{ footerLinkGithub }}">
				<img width="20px" height="20px" src="/assets/svgs/github.svg" alt="github logo" title="github logo"/>
			</div>

			<div class="link footer-icon linkedin-icon" data-external="{{ footerLinkLinkedin }}">
				<img width="20px" height="20px" src="/assets/svgs/linkedin.svg" alt="linkedin logo" title="linkedin logo"/>
			</div>

			{{#if footerLinkInstagram }}
			<div class="link footer-icon instagram-icon" data-external="{{ footerLinkInstagram }}">
				<img width="20px" height="20px" src="/assets/svgs/instagram.svg" alt="instagram logo" title="instagram logo"/>
			</div>
			{{/if }}

			<div class="footer-icon email-icon">
				{{ footerLinkEmail }}
			</div>

		</div>
	
	</div>


</div>