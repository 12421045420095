import { gsap, CustomEase, Power2 } from 'gsap'
gsap.registerPlugin( CustomEase )

import { Vec2 } from 'ogl'

import { nodes as nodes } from './../components/nodes.js'
import { update as update_nodes } from './../components/nodes.js'
import * as utils from './../components/utils.js'
import { state } from './../app/controller.js'
import * as world from './../sections/world.js'
import Lag from './../components/lag.js'


export let build = () => {

	nodes.overlay.svg.innerHTML = ''

	init_system()

	check_group()

	resize_handler()

	init_particles()

}


export let resize_handler = () => {

	nodes.overlay.svg.setAttribute("viewBox", `0 0 ${ state.window_size.w } ${ state.window_size.h }` )

}

let eases = {
	zip_out: CustomEase.create("custom", "M0,0 C0.3,0 0.3,1 1,1"),
	zip_in: CustomEase.create("custom", "M0,0,C0.4,0,0.2,1,1,1"),
	zip_in_hard: CustomEase.create("custom", "M0,0,C0.8,0,0.7,1,1,1"),
	slide_in: CustomEase.create("custom", "M0,0,C0.3,0.6,0.4,1,1,1"),
	slide_out: CustomEase.create("custom", "M0,0,C0.3,0,0.6,0.2,1,1"),
}



let NUM_SQUARES = 72
let NUM_LINES = 72


let mouse_factor = null

let system

let init_system = () => {


	system = {
		spawn_count: 0,
		spawn_count_l: 0,
		config: {
			time: 0.45,
			dist: 0.2,
			spread: 20.0,
			spread_l: 35.0,
			stray: 20.0,
			spawn_rate: 10.0,
			spawn_rate_l: 14.0,
			vel_mag_max: 0.2,
			vel_mag_max_l: 0.10
		},
		squares:		[],
		lines: 			[],
		pool: {
			square: 	[],
			line: 		[]
		},
		request: ( _type ) => {

			if ( !( _type == 'square' || _type == 'line' ) ) return false

			if ( system.pool[ _type ].length > 0 ) {
				return system.pool[ _type ].shift()
			}

		},
		release: ( p ) => {
			system.pool[ p.type ].push( p )
		}
	}

}

let init_particles = () => {

	// CREATE pool of squares
	for ( let i=0; i<NUM_SQUARES; i++ ) {

		let s = new Rectangle( { 
			scale: { x: 0, y: 0 },
			is_particle: true
		} )

		let p = new Particle( {
			type: 'square',
			shape: s
		} )

		system.squares.push( s )
	} 


	for ( let i=0; i<NUM_LINES; i++ ) {

		let s = new Line( {
			is_particle: true,
			strokeWidth: 2
		} )

		let p = new Particle( {
			type: 'line',
			shape: s
		} )

		system.lines.push( s )

	}


	mouse_factor = new Lag( 0, { 
		format: 'kalman',
		buffer_length: 30
	} )

}


let default_hexes = [ 'AAAAAA',  '000000' ]
let hexes_list = [ 'AAAAAA',  '000000' ]

export let set_particle_colors = ( hexes ) => {

	// console.log( hexes )

	if ( hexes && hexes.length > 0 ) {
		hexes_list = [].concat( hexes )
	}
	else {
		hexes_list = [].concat( default_hexes )
	}

}



export let update = () => {

	if ( !system || system.squares.length <= 0 ) {
		return
	} 

	let m = 0
	if ( mouse_factor ) {
		m = mouse_factor.tick( world.mouse.diff ? 1 : 0 )
	}

	let h = world.uniforms.background.u_homepage.value	

	let vel_magn = world.mouse.vel.len()
	vel_magn = utils.normalize( 
		vel_magn, 
		0.012 * state.window_size.w, 
		0.032 * state.window_size.w, 
		true
	)


	// - - - SPAWN squares, manage counter

	let adjuster = Math.min( state.window_size.w / 1920, 1 );

	let step = ( system.config.spawn_rate ) * 1/60;

	system.spawn_count = Math.max( 
		system.spawn_count - step - vel_magn * 1/60 * 30, 
		-step
	)

	let to_do = system.spawn_count < 0 ? step : 0

	// console.log( vel_magn * 1/60)

	if ( m > 0.001 && to_do > 0 ) {

		system.spawn_count = system.spawn_count + Math.ceil( to_do )

		for ( let i=0; i < to_do; i++ ) {

			let p = system.request( 'square' )
			if ( p ) {
				// console.log( 'spwan')
				p.spawn()
			}
			else {
				console.log( 'square pool empty' )
			}

		}

	}



	// - - - SPAWN line particles, manage counter

	let step_l = system.config.spawn_rate_l * 1/60

	system.spawn_count_l = Math.max(
		system.spawn_count_l - step_l - vel_magn * 1/60 * 10,
		-step_l
	)

	let to_do_l = system.spawn_count_l < 0 ? step_l : 0

	if ( m > 0.001 && to_do_l > 0 ) {

		system.spawn_count_l = system.spawn_count + Math.ceil( to_do_l )

		for ( let i=0; i < to_do_l; i++ ) {

			let p = system.request( 'line' )
			if ( p ) {
				p.spawn()
			}
			else {
				console.log( 'line pool empty' )
			}

		}

	}


	// UPDATE each particle
	system.squares.forEach( x => { x.update() } )
	system.lines.forEach( x => { x.update() } )

}



class Particle {

	constructor( data ) {

		this.type = 			data.type
		this.shape = 			data.shape

		this.alive =			false
		this.tween = 			null

		this.dummy = 0

		if ( this.type == 'square' ) {
			this.shape.scale = { x: 0, y: 0 }
		}
		else if ( this.type == 'line' ) {
			this.x1 = 0
			this.y1 = 0
			this.x2 = 0
			this.y2 = 0
		}

		this.shape.update()

		system.release( this )

	}

	spawn() {

		this.tween = gsap.timeline( {
			paused: true,
			onComplete: 	() => { this.die() },
			onUpdate: 		() => { this.update() }
		} )

		let vel_norm = new Vec2()
		vel_norm.copy( world.mouse.vel )
		vel_norm.normalize()

		let vel_magn = world.mouse.vel.len()

		vel_magn = utils.normalize( vel_magn, 0.0, system.config.dist * state.window_size.w, true )
		vel_magn = Math.max( vel_magn, 0.075 )
		vel_magn = Math.min( vel_magn, this.type === 'line' ? system.config.vel_mag_max_l : system.config.vel_mag_max )

		let r1 = Math.random() * 2.0 - 1.0
		let r2 = Math.random() * 2.0 - 1.0
		let r3 = Math.random() * 2.0 - 1.0
		let r4 = Math.random() * 2.0 - 1.0

		let r5 = Math.random() * 0.5 + 0.5
		
		let h = hexes_list[ 
			Math.min(
				Math.floor( hexes_list.length * Math.random() ),
				hexes_list.length - 1
			)
		]
		
		if ( this.type === 'square' ) {

			this.shape._fillColor = '#' + h

			let origin = new Vec2()
			origin.copy( world.mouse.px )

			origin.x += r1 * system.config.spread
			origin.y += r2 * system.config.spread

			let target = {
				x: vel_norm.x * state.window_size.w * vel_magn + system.config.stray * r3,
				y: vel_norm.y * state.window_size.w * vel_magn + system.config.stray * r4
			}

			this.shape.position = origin

			this.tween.add( gsap.to(
				this.shape.position,
				system.config.time,
				{
					x: `+=${ target.x }`,
					y: `+=${ target.y }`,
					ease: Power2.easeOut
				}
			), 0 )

			this.tween.add( gsap.to(
				this.shape.scale,
				system.config.time * 0.25,
				{
					x: 20.0 * r5 * ( 0.5 + vel_magn * 2.0 ),
					y: 20.0 * r5 * ( 0.5 + vel_magn * 2.0 ),
					ease: Power2.easeOut
				}
			), 0 )

			this.tween.add( gsap.to(
				this.shape.scale,
				system.config.time * 0.25,
				{
					x: 0,
					y: 0,
					ease: Power2.easeOut
				}
			), system.config.time * 0.75 )

		}
		else if ( this.type === 'line' ) {

			this.shape._strokeColor = '#' + h

			let origin = new Vec2()
			origin.copy( world.mouse.px )

			origin.x += r1 * system.config.spread
			origin.y += r2 * system.config.spread

			let vel_dir = {
				x: Math.sign( vel_norm.x ),
				y: Math.sign( vel_norm.y )
			}

			if ( vel_dir.x == 0 ) vel_dir.x = Math.random() > 0.5 ? 1 : -1
			if ( vel_dir.y == 0 ) vel_dir.y = Math.random() > 0.5 ? 1 : -1

			let target = {
				x: vel_dir.x * state.window_size.w * vel_magn * ( 0.5 + r4 ) * 1.25,
				y: vel_dir.y * state.window_size.w * vel_magn * ( 0.5 + r4 ) * 1.25
			}


			this.shape.x1 = origin.x
			this.shape.y1 = origin.y

			this.shape.x2 = target.x + origin.x
			this.shape.y2 = target.y + origin.y

			gsap.set( this.shape._node, { drawSVG: '0% 0%' } )

			this.tween.add( gsap.to(
				this.shape._node,
				system.config.time * 0.5 * 2.0,
				{
					drawSVG: "0% 100%",
					ease: eases.zip_in
				}
			), 0 )

			this.tween.add( gsap.to(
				this.shape._node,
				system.config.time * 0.5 * 2.0,
				{
					drawSVG: "100% 100%",
					ease: eases.zip_out
				}
			), 0.5 * system.config.time * 2.0 )

		}

		this.shape.update()

		this.alive = true

		this.tween.play()

	}

	die() {

		this.alive =			false

		if ( this.type == 'square' ) {

			this.shape.scale = 		{ x: 0, y: 0 }
			this.shape.position = 	{ x: 0, y: 0 }
		
		}
		else if ( this.type == 'line' ) {

			this.x1 = 0
			this.x2 = 0
			this.y1 = 0
			this.y1 = 0

		}



		if ( this.tween ) {
			this.tween.pause()
			this.tween.kill()
		}

		this.tween =			null

		system.release( this )

	}


	update() {

		if ( this.alive ) {
			this.shape.update()
		}

	}

}


















export let create_transition_nodes_A = () => {

	clear_main_group()
	
	// let outline_width = Number( window.getComputedStyle( nodes.blend_mode_clip )[ 'outline-width' ].replace('px','') )
	// let box_padding = outline_width > 0
	// 	? outline_width - 2
	// 	: Number( nodes.blend_mode_clip.style.margin.replace( 'px','' ) ) - 2

	

	// - - - LINES
	
	let lines = []
	let num_lines = Math.round( 24 + ( Math.random() * 2 ) )
	let line_w = 2
	let box_padding = state.window_size.d / 12
	
	let line_spacing = ( state.window_size.h + state.window_size.w - box_padding * 4 ) / num_lines

	for ( let i = 0; i < num_lines; i++ ) {

		if ( Math.random() < 0.5 || lines.length < i * 0.3 ) {
		
			let l, pa, pb

			if ( line_spacing * ( i + 0.5 ) <= ( state.window_size.h - box_padding * 2 ) ) {
				pa = new Vec2( box_padding, line_spacing * ( i + 0.5 ) + box_padding )
				pb = ( pa.y > ( state.window_size.w - box_padding ) )
					? new Vec2( state.window_size.w - box_padding, pa.y - state.window_size.w + 2 * box_padding )
					: new Vec2( pa.y, box_padding )
			}
			else {
				pa = new Vec2( line_spacing * ( i + 0.5 ) - ( state.window_size.h - box_padding * 2 ) + box_padding, state.window_size.h - box_padding )
				pb = ( ( state.window_size.h - 2 * box_padding ) - ( ( state.window_size.w - box_padding ) - pa.x ) > 0 )
					? new Vec2( state.window_size.w - box_padding, state.window_size.h - ( state.window_size.w - pa.x ) )
					: new Vec2( state.window_size.w + ( state.window_size.h - ( state.window_size.w - pa.x + box_padding * 2 ) ), box_padding )
			}
		
			l = new Line( { pointA: pa, pointB: pb, strokeColor: "#000000", strokeWidth: line_w, strokeCap: 'none' } )

			lines.push( l )

		}
	}



	// - - - SQUARES

	let squares = []
	let num_squares = Math.round( 10 + ( Math.random() * 2 ) )
	let square_config = {
		padding: state.window_size.d / 8,
		s_min: 2 * ( Math.sqrt( state.window_size.w / 1280 ) + 1 ),
		s_max: 10 * ( Math.sqrt( state.window_size.w / 1280 ) + 1 ),
		s_width: 0
	}

	for ( let i=0; i<num_squares; i++ ) {

		if ( Math.random() < 0.8 ) {

			let pos = new Vec2( 
				( ( 1.0 * ( i + 1 ) / num_squares + Math.random() * 0.2 * utils.sign_num() ) * state.window_size.w - 2 * square_config.padding ) + square_config.padding,
				( ( 1.0 * ( i + 1 ) / num_squares + Math.random() * 0.2 * utils.sign_num() ) * state.window_size.h - 2 * square_config.padding ) + square_config.padding
			)

			let sca_x = Math.random() * ( square_config.s_max - square_config.s_min ) + square_config.s_min
			let sca = new Vec2( sca_x, sca_x )

			// CLAMP position now that know scale
			if ( ( pos.x - 0.5 * sca.x ) < square_config.padding ) 							
				pos.x = square_config.padding + 0.5 * sca.x
			if ( ( pos.x + 0.5 * sca.x ) > state.window_size.w - square_config.padding ) 	
				pos.x = state.window_size.w - square_config.padding - 0.5 * sca.x
			if ( ( pos.y - 0.5 * sca.y ) < square_config.padding ) 							
				pos.y = square_config.padding + 0.5 * sca.y
			if ( ( pos.y + 0.5 * sca.y ) > state.window_size.h - square_config.padding ) 	
				pos.y = state.window_size.h - ( square_config.padding + 0.5 * sca.y )

			let r1 = new Rectangle({
				position: pos,
				scale: sca.scale( 1.000 ),
				fillColor: "#000"
			} )

			let r2 = new Rectangle({
				position: pos,
				scale: sca.scale( 0.666 ),
				fillColor: "#000" 
			} )

			let r3 = new Rectangle({
				position: pos,
				scale: sca.scale( 0.333 ),
				fillColor: "#000"
			} )

			squares.push( [ r1, r2, r3 ] )

		}
	}





	let objects = {
		lines: lines,
		squares: squares
	}

	return objects

}




export let create_transition_nodes_B = () => {

	clear_main_group()


	// - - - SQUARES

	let squares = []
	let num_squares = Math.round( 10 + ( Math.random() * 2 ) )
	let square_config = {
		padding: state.window_size.d / 8,
		s_min: 2 * ( Math.sqrt( state.window_size.w / 1280 ) + 1 ),
		s_max: 10 * ( Math.sqrt( state.window_size.w / 1280 ) + 1 ),
		s_width: 0
	}

	for ( let i=0; i<num_squares; i++ ) {

		if ( Math.random() < 0.8 ) {

			let pos = new Vec2( 
				( ( 1.0 * ( i + 1 ) / num_squares + Math.random() * 0.2 * utils.sign_num() ) * state.window_size.w - 2 * square_config.padding ) + square_config.padding,
				( ( 1.0 * ( i + 1 ) / num_squares + Math.random() * 0.2 * utils.sign_num() ) * state.window_size.h - 2 * square_config.padding ) + square_config.padding
			)

			let sca_x = Math.random() * ( square_config.s_max - square_config.s_min ) + square_config.s_min
			let sca = new Vec2( sca_x, sca_x )

			// CLAMP position now that know scale
			if ( ( pos.x - 0.5 * sca.x ) < square_config.padding ) 							
				pos.x = square_config.padding + 0.5 * sca.x
			if ( ( pos.x + 0.5 * sca.x ) > state.window_size.w - square_config.padding ) 	
				pos.x = state.window_size.w - square_config.padding - 0.5 * sca.x
			if ( ( pos.y - 0.5 * sca.y ) < square_config.padding ) 							
				pos.y = square_config.padding + 0.5 * sca.y
			if ( ( pos.y + 0.5 * sca.y ) > state.window_size.h - square_config.padding ) 	
				pos.y = state.window_size.h - ( square_config.padding + 0.5 * sca.y )

			let r1 = new Rectangle({
				position: pos,
				scale: sca.scale( 1.000 ),
				fillColor: "#000"
			} )

			let r2 = new Rectangle({
				position: pos,
				scale: sca.scale( 0.666 ),
				fillColor: "#000" 
			} )

			let r3 = new Rectangle({
				position: pos,
				scale: sca.scale( 0.333 ),
				fillColor: "#000"
			} )

			squares.push( [ r1, r2, r3 ] )

		}
	}





	let objects = {
		squares: squares
	}

	return objects

}



let check_group = () => {

	if ( nodes.overlay.group && nodes.overlay.group_ps ) return

	let g = document.createElementNS(
		'http://www.w3.org/2000/svg',
		'g'
	)

	g.classList.add( 'overlay-g' )

	nodes.overlay.svg.innerHTML = ''

	nodes.overlay.svg.appendChild( g )


	let gps = document.createElementNS(
		'http://www.w3.org/2000/svg',
		'g'
	)

	gps.classList.add( 'overlay-g-ps' )

	nodes.overlay.svg.appendChild( gps )	


	update_nodes( 'overlay' )

}

let clear_main_group = () => {

	let els = Array.prototype.slice.call( nodes.overlay.group.children )
	els.forEach( el => {
		el.remove()
	})

}






// - - - - - - - - - - - - - - - - - - - - - - SIMPLE ABSTRACTED SVG GEOMETRIES


// - - LINE 

class Line {

	constructor( data ) {

		// POINT A
		this._pointA = ( data.pointA && !( data.pointA.x === undefined || data.pointA.y === undefined ) ) 
			? data.pointA
			: new Vec2( 0, 0 )

		// POINT B
		this._pointB = ( data.pointB && !( data.pointB.x === undefined || data.pointB.y === undefined ) ) 
			? data.pointB
			: new Vec2( 0, 0 )

		// STROKE WIDTH
		this._strokeWidth = ( 
			data.strokeWidth !== undefined && 
			typeof data.strokeWidth === 'number'
		)
			? data.strokeWidth
			: 2

		// STROKE COLOR
		this._strokeColor = ( data.strokeColor !== undefined )
			? data.strokeColor
			: "#000"

		// STROKE CAP
		this._strokeCap = ( data.strokeCap === 'round' || data.strokeCap === 'square' )
			? data.strokeCap
			: 'none'

		// DOM NODE
		this._node = document.createElementNS(
			'http://www.w3.org/2000/svg',
			'line'
		)

		// APPLY INTERNALS TO NODE
		this.update()

		// PLACE IN GROUP
		if ( data.is_particle ) {
			nodes.overlay.group_ps.appendChild( this._node )
		}
		else {
			nodes.overlay.group.appendChild( this._node )
		}
	}


	// - - - GETTERS

	get pointA() { return this._pointA }

	get pointB() { return this._pointB }

	get strokeWidth() { return this._strokeWidth }

	get strokeColor() { return this._strokeColor }

	get node() { return this._node }


	// - - - SETTERS

	set x1( val ) {
		this._pointA.x = val
		this._node.setAttribute( 'x1', this._pointA.x )
	}

	set y1( val ) {
		this._pointA.y = val
		this._node.setAttribute( 'y1', this._pointA.y )
	}

	set x2( val ) {
		this._pointB.x = val
		this._node.setAttribute( 'x2', this._pointB.x )
	}

	set y2( val ) {
		this._pointB.y = val
		this._node.setAttribute( 'y2', this._pointB.y )
	}


	// - - - HELPERS

	update() {

		this._node.setAttribute( 'x1', this._pointA.x )
		this._node.setAttribute( 'y1', this._pointA.y )
		this._node.setAttribute( 'x2', this._pointB.x )
		this._node.setAttribute( 'y2', this._pointB.y )

		this._node.setAttribute( 'stroke', this._strokeColor )
		this._node.setAttribute( 'stroke-width', this._strokeWidth )
		this._node.setAttribute( 'stroke-linecap', this._strokeCap )
	}
	
	destroy() {
		this._node.remove()
	}

}


// - - SQUARE 

class Rectangle {

	constructor( data ) {

		// POSITION
		this._position = ( data.position && !( data.position.x === undefined || data.position.y === undefined ) ) 
			? data.position
			: new Vec2( 0, 0 )

		// SCALE
		this._scale = ( data.scale && !( data.scale.x === undefined || data.scale.y === undefined ) )
			? data.scale
			: new Vec2( 10, 10 )

		// BOUNDING BOX overrides
		if ( data.position === undefined && data.scale === undefined && data.boundingBox && 
			this.boundingBox.x !== undefined &&
			this.boundingBox.y !== undefined &&
			this.boundingBox.w !== undefined &&
			this.boundingBox.h !== undefined
		) {
			this._position = new Vec2( 
				data.boundingBox.x + ( data.boundingBox.w * 0.5 ),
				data.boundingBox.y + ( data.boundingBox.h * 0.5 ),
			)
			this._scale = new Vec2( 
				( data.boundingBox.w ),
				( data.boundingBox.h )
			)
		}

		// STROKE WIDTH
		this._strokeWidth = ( 
			data.strokeWidth !== undefined && 
			typeof data.strokeWidth === 'number'
		)
			? data.strokeWidth
			: 0


		// FILL COLOR
		this._fillColor = ( data.fillColor !== undefined )
			? data.fillColor
			: "#000"

		// STROKE COLOR
		this._strokeColor = ( data.strokeColor !== undefined )
			? data.strokeColor
			: "#000"

		// STROKE JOIN
		this._strokeJoin = ( data.strokeJoin === 'round' || data.strokeJoin === 'bevel' )
			? data.strokeJoin
			: 'none'


		// DOM NODE
		this._node = document.createElementNS(
			'http://www.w3.org/2000/svg',
			'rect'
		)

		if ( data.is_particle ) {
			// this._strokeWidth = 4
			this._fillColor = ( Math.random() > 0.5 )
				? "#AAA"
				: "#000"
		}

		// APPLY INTERNALS TO NODE
		this.update()

		// PLACE IN GROUP
		if ( data.is_particle ) {
			nodes.overlay.group_ps.appendChild( this._node )
		}
		else {
			nodes.overlay.group.appendChild( this._node )
		}
	}


	// - - - GETTERS

	get position() { return this._position }
	get x() { return this._position.x }
	get y() { return this._position.y }	

	get scale() { return this._scale }

	get strokeWidth() { return this._strokeWidth }

	get fillColor() { return this._fillColor }

	get strokeColor() { return this._strokeColor }

	get boundingBox() { 
		return {
			x: this._x,
			y: this._y,
			w: this._w,
			h: this._h
		}
	}

	get node() { return this._node }


	// - - - SETTERS

	set position( val ) {
		this._position.x = val.x
		this._position.y = val.y
	}

	set scale( val ) {
		this._scale.x = val.x
		this._scale.y = val.y
	}


	// - - - HELPERS

	update() {

		this.updateBoundingBox()

		this._node.setAttribute( 'x', this._x )
		this._node.setAttribute( 'y', this._y )
		this._node.setAttribute( 'width', this._w )
		this._node.setAttribute( 'height', this._h )

		this._node.setAttribute( 'fill', this._fillColor )
		this._node.setAttribute( 'stroke', this._strokeColor )
		this._node.setAttribute( 'stroke-width', this._strokeWidth )
		this._node.setAttribute( 'stroke-linejoin', this._strokeJoin )
	}
	
	updateBoundingBox() {
		this._x = this._position.x - 0.5 * this._scale.x
		this._y = this._position.y - 0.5 * this._scale.y
		this._w = this._scale.x
		this._h = this._scale.y
	}

	destroy() {
		this._node.remove()
	}

}
