import { gsap, CustomEase, Power0, Power2, Power3, Circuluar, Sine } from 'gsap'

import * as utils from './../components/utils.js'



// BUILD dom elements and tweens for loading motion per each asset
export let build_loader_animations = () => {

	let eases = {
		zip_out: CustomEase.create("custom", "M0,0 C0.3,0 0.3,1 1,1"),
		zip_in: CustomEase.create("custom", "M0,0,C0.4,0,0.2,1,1,1"),
		zip_in_hard: CustomEase.create("custom", "M0,0,C0.8,0,0.7,1,1,1"),
		slide_in: CustomEase.create("custom", "M0,0,C0.3,0.6,0.4,1,1,1"),
		slide_out: CustomEase.create("custom", "M0,0,C0.3,0,0.6,0.2,1,1")
	}



	let assets_to_load = [ ...utils.qsa('.img-wrap.loading'), ...utils.qsa('.video-container.loading') ]

	assets_to_load.forEach( asset => {
		
		let loader_wrap = document.createElement( 'div' )
		loader_wrap.classList.add( 'loading-animation-wrap' )

		let loader_inner = document.createElement( 'div' )
		loader_inner.classList.add( 'loader-inner' )

		let dots = []

		let num_dots = 6
		for ( let i=0; i<num_dots; i++ ) {
			
			let dot = document.createElement( 'div' )
			dot.classList.add( 'loader-dot' )

			loader_inner.appendChild( dot )
			dots.push( dot )

		}

		loader_wrap.appendChild( loader_inner )
		asset.appendChild( loader_wrap )
		
		asset.classList.add('loading')

		
		let s = 22			// square size
		let x = s * 0.5

		let t = 0.25			// time unit		
		let p = 0.5 		// pause time unit
		let r = -0.045		// stagger

		let tls = []		// all timelines

		let e = eases.zip_in

		dots.forEach( ( d, i ) => {

			let tl = gsap.timeline( {
				paused: true,
				repeat: -1,
				onStart: ()  => {

				},
				onComplete: () => {

				}
			} )

			// start in the left center
			gsap.set( d, { x: -s, y: 0 } )

			// move around the square
			tl.add( gsap.to( d, t, { x:	-x, y: -x, ease: e } ), ( t + p ) * 0 )
			tl.add( gsap.to( d, t, { x:	 0, y: -s, ease: e } ), ( t + p ) * 1 )
			tl.add( gsap.to( d, t, { x:	 x, y: -x, ease: e } ), ( t + p ) * 2 )
			tl.add( gsap.to( d, t, { x:	 s, y:  0, ease: e } ), ( t + p ) * 3 )
			tl.add( gsap.to( d, t, { x:	 x, y:  x, ease: e } ), ( t + p ) * 4 )
			tl.add( gsap.to( d, t, { x:	 0, y:  s, ease: e } ), ( t + p ) * 5 )
			tl.add( gsap.to( d, t, { x:	-x, y:  x, ease: e } ), ( t + p ) * 6 )
			tl.add( gsap.to( d, t, { x:	-s, y:  0, ease: e } ), ( t + p ) * 7 )
			tl.call( () => { }, null,							( t + p ) * 8 )

			let f = Math.sin( ( i / num_dots ) * Math.PI * 2.0 * 3.0 ) * 0.5 + 0.5

			tl.progress( i / ( num_dots ) + f * r )

			tls.push( tl )

		} )

		tls.forEach( tl => tl.play() )

	} )

}
