import { build as nodes_build } from './../components/nodes.js'
import { nodes as nodes } from './../components/nodes.js'
import { build_loader_animations } from './../components/loading-motion.js'
import { toggle_blendmode, prep_page_header, reset_scroll } from './../app/controller.js'
import * as overlay from './overlay.js'
import * as image_modal from './../components/image-modal.js'

export let build = () => {

	// COLLECT nodes within template
	nodes_build( 'about' )

	// toggle_blendmode( false )

	prep_page_header()

	build_loader_animations()

	overlay.set_particle_colors( [
		'FFD086',
		'FF703E',
		'FF1665',
		'C476E8',
		'4A48D4'
	] )


	image_modal.update( {
		content_wrap: nodes.about.wrap,
		selector: 'img-wrap',
		project_name: 'OMG it me'
	} )

}
