import { gsap, CustomEase, Power2, Power0 }	from 'gsap'
import { build as nodes_build } from './../components/nodes.js'
import * as world from './world.js'
import { build as build_overlay } from './overlay.js'
import { nodes } from './../components/nodes.js'
import * as utils from './../components/utils.js'
import { state, prep_page_header, sync_window_size_state, sync_heights, reset_scroll } from './../app/controller.js'
import * as overlay from './overlay.js'

import * as navigation from './../components/navigation.js'
import * as templates from './../components/templates.js'
import { all as content } from './../components/content.js'
import * as copypaste from './../components/copypaste.js'
import * as transitions from './../components/transitions.js'

import { toggle_blendmode, is_mobile } from './../app/controller.js'
import * as loader from './../components/loader.js'

let status = {
	init: false
}

export let build = () => {

	// COLLECT nodes within template
	nodes_build( 'home' )

	if ( !status.init ) {
		sync_window_size_state()

		nodes.header.innerHTML = nodes.home.title_wrap.innerHTML
		nodes.footer.innerHTML = nodes.home.footer_wrap.innerHTML

		nodes_build( 'home' )

		nodes.header.children[ 0 ].addEventListener( 'click', () => {
			navigation.link_click( window.location.origin + '/home' )
		})

		world.build_world()

		build_overlay()
		
		copypaste.add_handler( nodes.home.footer_email, nodes.home.footer_email.innerHTML )

	}

	overlay.set_particle_colors()

	prep_page_header()

	status.init = true

	// toggle_blendmode( true )
	
	utils.prevent_widows( nodes.home.header, 2 )

	// sync_heights()

	world.set_video_bounds( 'home' )

	// is mobile, video still loading
	if ( loader.DEFER_LOAD && !loader.video_out ) {

		mobile_loader_ui()

	}
	
}


// USED when an non-top level page is navigated to directly

export let pre_build = () => {

	if ( !status.init ) {

		sync_window_size_state()

		let temp_wrap = document.createElement('div')
		let temp_content = templates.default.home( content.content.home )
		temp_wrap.innerHTML = temp_content

		let title_wrap = temp_wrap.children[ 0 ].children[ 0 ]
		let footer_wrap = temp_wrap.children[ 0 ].children[ 4 ]

		nodes.header.innerHTML = title_wrap.innerHTML
		nodes.footer.innerHTML = footer_wrap.innerHTML

		nodes_build( 'home' )

		nodes.header.children[ 0 ].addEventListener( 'click', () => {
			navigation.link_click( window.location.origin + '/home' )
		})

		world.build_world()

		build_overlay()

		// sync_heights()

		copypaste.add_handler( nodes.home.footer_email, nodes.home.footer_email.innerHTML )

	}

	status.init = true

}



export let mobile_loader_ui = () => {

	nodes.home.projects_link.classList.add('deferring')

}


export let set_loader = ( p ) => {

	if ( world.world && world.world.video && world.world.video.src ) return

	if ( p < 1 && nodes.home.loading_bar ) {
		
		gsap.set( nodes.home.loading_bar, { scaleX: p, transformOrigin: "0% 50%" } )

	}
	else {

		gsap.set( nodes.home.loading_bar, { scaleX: 1.0, transformOrigin: "0% 50%" } )

		world.video_downloaded_deferred()
		
		setTimeout( () => {
		
			tween_out()
		
		// wait for the css transition to complete
		}, 500 )


	}


}



let tween_out = ( resolve ) => {

	let t = 0.4

	let tl = gsap.timeline({
		paused: true,
		onStart: () => {
			
		},
		onComplete: () => {
	
		}
	} )

	nodes.home.loading_bar.style.transition = 'none'
	
	let x_slide = 5

	gsap.set( nodes.home.projects_label, { opacity: 0.0, x: -x_slide } )

	tl.add( gsap.to( nodes.home.loading_label, t * 0.7, { opacity: 0 } ), t * 0.2 )
	tl.add( gsap.to( nodes.home.loading_label, t * 0.7, { x: x_slide, ease: transitions.eases.slide_in } ), t * 0.2 )

	tl.add( gsap.to( nodes.home.loading_bar, t,
		{ 
			scaleX: 0, 
			transformOrigin: "100% 50%", 
			ease: transitions.eases.zip_out
		}
	), 0 )

	tl.call( () => {
		nodes.home.projects_link.classList.remove('deferring')
	}, null, t )

	tl.add( gsap.to( nodes.home.projects_label, t * 0.5, { opacity: 1 } ), t * 1.1 )
	tl.add( gsap.to( nodes.home.projects_label, t * 0.5, { x: 0, ease: transitions.eases.zip_out, clearProps: 'x' } ), t * 1.1 )

	tl.play()

}