import KalmanFilter from 'kalmanjs' 

/*

	Lag class - use to smooth inputnumerical values, by averaging across a first-in, 
	last-out buffer.


	_vals = 
	
		Shallow object of numbers or...
		{
			key0: 0.0,
			key1: 1.0,
			key2: 2.0
		}

		Array of numbers or...
		[ 0.0, 1.0, 2.0 ]

		Number
		0.0
	

	options = {
		buffer_length: 10,
		lag_format: 'avg', or 'tail'
	}

*/


export default class Lag {


	constructor( _vals, options ) {
		

		// - - - VALIDATE

		// Merge number object & array input types to array of numbers

		this.valid = true
		this.typeof = this.typeof_deluxe( _vals )

		let vals = []

		if ( this.typeof === 'number' ) {

			vals.push( _vals )

		}
		else if ( this.typeof === 'object' ) {

			this.buffer_keys = []

			for ( let key in _vals ) {
				if ( typeof _vals[ key ] === 'number' ) {
					this.buffer_keys.push( key )
					vals.push( 0 + _vals[ key ] )
				}
				else {
					this.valid = false
				}
			}

		}
		else if ( this.typeof === 'array' ) {
			_vals.forEach( v => {
				if ( typeof v === 'number' ) {
					vals.push( 0 + v )
				}
				else {
					this.valid = false
				}
			})
		}
		else {
			this.valid = false
		}

		if ( !this.valid ) {
			console.log( 'Lag: Error: Cant create lag instance, must provide number, obj, or array of numerical values:', _vals )
			return
		}


		// - - - FORMAT

		this.valid_formats = [ 'avg', 'tail', 'kalman' ]

		this.format = ( options.format !== undefined && this.valid_formats.indexOf( options.format ) > -1 )
			? options.format
			: 'avg'

		if ( this.format === 'kalman' ) {
			this.kf = new KalmanFilter( options.kf_options ? options.kf_options : undefined )
		}

		// - - - BUFFER

		this.buffer_width = vals.length
		this.buffer_length = typeof options.buffer_length === 'number' ? options.buffer_length : 10
		this.buffer_tail = 0

		this.buffer = []

		this.top = vals
		this.sum = vals
		this.lag = vals

		for (let i=0; i<this.buffer_length; i++ ) {
			this.buffer_tail += ( i + 1 )
			this.buffer.push( vals )
		}

	}


	tick( _new_set ) {

		if ( this.buffer.length === 0 || !this.valid ) return

		// TO-DO: efficiently validate input here ?

		let new_set = this.import( _new_set )

		this.top = new_set

		this.buffer.shift()
		this.buffer.push( this.top )

		// INIT
		this.sum = []
		this.lag = []
		for ( let i=0; i < this.buffer_width; i++ ) {
			this.sum.push( 0 )
			this.lag.push( 0 )
		}

		if ( this.format === 'kalman' ) {

			// SPLIT buffers into 1d arrays
			let temp_buffers = []
			for ( let i=0; i < this.buffer_width; i++ ) {
				temp_buffers.push( [ ] )
			}
			this.buffer.forEach( ( set, i ) => {
				set.forEach( ( x, j ) => {
					temp_buffers[ j ].push( x )
				} )
			} )

			temp_buffers.forEach( ( arr, j ) => {
				let filtered = temp_buffers[ j ].map( ( val ) => {
					return this.kf.filter( val )
				} )
				this.lag[ j ] = filtered[ this.buffer_length - 1 ]
			} )		

		}
		else if ( this.format === 'avg' ) {

			// SUM
			this.buffer.forEach( ( set, i ) => {
				set.forEach( ( x, j ) => {
					this.sum[ j ] += x
				} )
			} )

			// AVG
			this.sum.forEach( ( s, i ) => {
				this.lag[ i ] = s / this.buffer_length
			} )

		}
		else if ( this.format === 'tail' ) {

			// SUM
			this.buffer.forEach( ( set, i ) => {
				set.forEach( ( x, j ) => {
					this.sum[ j ] += x * ( i + 1 )
				} )
			})

			// AVG
			this.sum.forEach( ( s, i ) => {
				this.lag[ i ] = s / this.buffer_tail
			} )

		}

		return this.output

	}


	force_tick( new_set ) {

		// IMPORT to top of stack
		this.top = this.import( new_set )

		// REPLACE entire buffer
		for (let i=0; i<this.buffer_length; i++ ) {
			this.buffer[ i ] = this.import( new_set )
		}

		// REPLACE avg
		this.avg = this.import( new_set )

		return this.tick( new_set )

	}


	get output() {

		if ( this.typeof === 'array' ) {
			return this.lag
		}
		else if ( this.typeof === 'object' ) {
			let data = {}
			this.buffer_keys.forEach( ( k, i ) => { data[ k ] = this.lag[ i ] } )
			return data
		}
		else if ( this.typeof === 'number' ) {
			return this.lag[ 0 ]
		}

	}


	import( data ) {

		let set = []

		if ( this.typeof === 'array' ) {
			set = this.process_array_tick( data )
		}
		else if ( this.typeof === 'object' ) {
			set = this.process_object_tick( data )
		}
		else if ( this.typeof === 'number' ) {
			set = [ data ]
		}
		else {
			console.error( 'unhandled type', this.typeof )
		}

		return set

	}

	process_object_tick( obj ) {

		let val = []
		this.buffer_keys.forEach( k => {
			val.push( obj[ k ] )
		} )
		return val

	}


	process_array_tick( arr ) {

		return [...arr]

	}


	typeof_deluxe( data ) {

		let type = typeof data

		// Be more specific between objects and arrays, please javascript
		if ( type === 'object' ) {
			type = ( data instanceof Object && data instanceof Array )
				? 'array'
				: 'object'
		}

		// Dont return object as type of null, please javascript
		if ( data == undefined && data !== null ) {
			type = 'undefined'
		}

		return type
	}

}