<div class="section about">


	<div class="page-header about-header" data-header-replace="true">
		<strong>About</strong>
	</div>


	<!-- greeter body content -->
	<div class="about-wrap">

		<div class="image-container">
			<span class="img-wrap loading" data-width="{{ aboutImage.width }}" data-height="{{ aboutImage.height }}">
				<img width="{{ aboutImage.width }}" height="{{ aboutImage.height }}" data-normal="{{ aboutImage.url }}" alt="blake-rutledge-interactive" data-loaded="false" draggable="false"/>
			</span>
		</div>

		<div class="skinny">

			<div class="intro">
				{{{ aboutGreeter }}}
			</div>

			<div class="about-blurb">
				{{{ aboutBlurb }}}
			</div>

			<div class="list-wrap technologies">
				<div class="list-header"> Technologies & Skills </div>
				{{#each aboutTechnologies }} 
				<div class="list-item multi"> {{ this }} </div>
				{{/each }}
			</div>

			<div class="list-wrap awards">
				<div class="list-header"> Awards & Features </div>
				{{#each aboutAwards }} 
				<div class="list-item"> {{ this }} </div>
				{{/each }}
			</div>

			<div class="list-wrap facts">
				<div class="list-header"> Fun Facts </div>
				{{#each aboutFacts }} 
				<div class="list-item"> {{ this }} </div>
				{{/each }}
			</div>


			<div class="list-wrap facts">
				<div class="list-header"> Still want more? </div>
				<a class="list-item link" target="_blank" href="{{ resumePdf }}"> 
					<div> Here's my resume </div>
				</a>
			</div>

		</div>


	</div>


</div>