import { gsap, CustomEase, Power2 } from 'gsap'


import { state, reset_scroll } from './../app/controller.js'
import * as utils from './utils.js'
import * as lazyload from './lazyload.js'

import { nodes as nodes } from './../components/nodes.js'

import { is_mobile } from './../app/controller.js'


import PinchZoom from './pinch-zoom.js'


export let eases = {
	zip_out: CustomEase.create("custom", "M0,0 C0.3,0 0.3,1 1,1"),
	zip_in: CustomEase.create("custom", "M0,0,C0.4,0,0.2,1,1,1"),
	zip_in_hard: CustomEase.create("custom", "M0,0,C0.8,0,0.7,1,1,1"),
	slide_in: CustomEase.create("custom", "M0,0,C0.3,0.6,0.4,1,1,1"),
	slide_out: CustomEase.create("custom", "M0,0,C0.3,0,0.6,0.2,1,1")
}



let m

export let init = ( config ) => {

	m = new Modal( config )

}



export let update = ( data ) => {

	m.update( data )

}

export let close_modal = () => {

	m.close()

}



class Modal {

	constructor( data ) {

		this.is_open = false

		this.modal_wrap = nodes.focus.modal_wrap
		this.modal_parent = nodes.focus.modal_parent
		this.modal_close = nodes.focus.modal_close

		this.modal_close.addEventListener( 'click', ( e ) => { this.modal_handler( e ) } )

		this.modal_wrap.addEventListener( 'touchmove', ( e ) => {
			e.preventDefault()
		} )

	}


	update( data ) {

		this.selector = data.selector
		this.content_wrap = data.content_wrap
		this.project_name = data.project_name

		nodes.focus.label_project.innerHTML = this.project_name

		this.content_wrap.addEventListener( 'click', ( e ) => { this.content_handler( e ) } )

		this.valid = true

	}


	async content_handler( e ) {

		let x = ( e.touches && e.touches[ 0 ].target )
			? e.touches[ 0 ].target
			: e.target

		let el = utils.walking_class_test( e.target, this.selector )

		if ( el ) {

			if ( el.classList.contains('loading') ) {
				return
			}

			if ( el.classList.contains('no-focus') ) {
				return
			}

			/*
			// slow to duplicate video nodes on mobile
			if ( el.querySelector('.video-outer') && is_mobile ) {
				// console.log( 'no videos for mobile' )
				return
			}
			*/

			await this.populate( el )

			this.populating = false

			this.open()

		}

	}

	modal_handler() {

		this.close()

	}





	async populate( node ) {

		// Guard
		if ( this.populating ) return

		this.modal_parent.innerHTML = ''
		this.populating = true

		return new Promise( async ( resolve, reject ) => {
			
			// the node in the content layout
			this.node = node
			this.parent_node = node.parentElement

			let iframe = node.querySelector( 'iframe' )
			let video = node.querySelector( 'video' )
			let image = node.querySelector( 'img' )


			let clone
			/*
			let asset_url
			let blob_url
			*/

			if ( video ) {

				this.video_to_move = node.querySelector('video')
				this.video_parent = node.querySelector('.video-outer')

				/*
				blob_url = video.src
				asset_url = video.parentElement.dataset['normal']

				let s = document.createElement( 'span' )
				s.dataset['width'] = node.dataset['width']
				s.dataset['height'] = node.dataset['height']
				s.classList.add('img-wrap')
				s.classList.add('loading')
				s.style = node.style

				let d = document.createElement( 'div' )
				d.classList.add( 'video-wrap' )
				d.classList.add( 'video-wrap' )

				d.innerHTML = `<video autoplay muted loop playsinline><source src type='video/mp4'></video>`

				s.appendChild( d )

				let v = d.querySelector('video')
				*/

				// do this later on during animation
				// video element literally removed from DOM body page
				// clone = this.node

			}
			else {

				clone =	this.node.cloneNode( true )
			
			}

			if ( clone ) {

				iframe = clone.querySelector( 'iframe' )
				video = clone.querySelector( 'video' )
				image = clone.querySelector( 'img' )


				// clean up
				clone.style = ''
				clone.classList.remove( this.selector )
				let loader = clone.querySelector( '.loading-animation-wrap' )
				if ( loader ) loader.remove()


				if ( iframe ) {
					
					// temp.appendChild( iframe )

					//cant get into iframe for timestamp of vimeo video element
					iframe.addEventListener( 'load', () => {
						requestAnimationFrame( ()=> {
							resolve()
						} )
					} )

				}
				else if ( video ) {

					// DOESNT GET HERE

					/*

					video.currentTime = this.node.querySelector('video').currentTime

					// or just cached blob
					video.src = blob_url
					
					*/

					// do this right away so the modal opens on click... video loads
					// when it decides its good and ready, device dependent
					resolve()

				}
				else if ( image ) {

					// temp.appendChild( image )

					image.addEventListener( 'load', () => {
						requestAnimationFrame( ()=> {
							resolve()
						} )
					} )

				}
				else {

					let src = clone.dataset['normal']
					let span = document.createElement('span')
					let img = document.createElement('img')
					img.src = src

					span.appendChild( img )
					clone = span

					img.addEventListener( 'load', () => {
						requestAnimationFrame( ()=> {
							resolve()
						} )
					} )

					resolve()
				}

				this.modal_parent.appendChild( clone )



			}
			else {

				resolve()

			}

		} )


	}


	calc_boxes() {

		if ( !this.node ) {
			this.valid = false
			return
		}

		this.valid = true

		// dimensions of content in document
		this.content_rect = this.node.getBoundingClientRect()

		// dimensions of modal inner container
		let modal_rect = this.modal_parent.getBoundingClientRect()
		let container_aspect = modal_rect.width / modal_rect.height

		let w = Number( this.node.getAttribute('width') ) || Number( this.node.dataset['width'] ) 
		let h = Number( this.node.getAttribute('height') ) || Number( this.node.dataset['height'] )
		let content_aspect = w / h

		// fill height or fill width, based on object-fit css property
		this.fill_height = container_aspect >= content_aspect

		// ratio of the content dimensions in the page to the zoomed dimensions
		let display_scale = this.fill_height
			? this.content_rect.height / modal_rect.height
			: this.content_rect.width / modal_rect.width

		this.start_position = {
			x: this.content_rect.x + this.content_rect.width * 0.5,
			y: this.content_rect.y + this.content_rect.height * 0.5
		}

		this.start_scale = {
			x: display_scale,
			y: display_scale
		}

		this.finish_position = {
			x: modal_rect.x + modal_rect.width * 0.5,
			y: modal_rect.x + modal_rect.height * 0.5
		}

		this.finish_scale = {
			x: 1.0,
			y: 1.0
		}

	}


	open() {

		// Guard
		if ( !this.valid || this.tl || this.is_open ) return

		this.is_open = true

		// the nodes will render in the dom, but with opacity 0
		this.modal_wrap.classList.add( 'active' )

		this.calc_boxes()


		// intrinsic dimensions of content
		this.content = 
			this.modal_parent.querySelector( 'span img' ) ||
			this.modal_parent.querySelector( 'span iframe' )

		this.is_video = !this.content


		let span = document.createElement('span')
		span.dataset['width'] = this.node.dataset['width']
		span.dataset['height'] = this.node.dataset['height']

		let el = ( this.is_video ) ? span : this.content

		this.tl = gsap.timeline({
			paused: true,
			onStart: () => {
				
				if ( !this.is_video ) {
					gsap.set( this.node, { opacity: 0 } )
				}
				else {

					
					this.modal_parent.appendChild( span )
					span.appendChild( this.video_to_move )
					span.style = ''
					this.video_to_move.style = ''

				}
				
				// Stitch
				this.modal_wrap.classList.add( 'visible' )

			},
			onComplete: () => {
	
				this.tl.kill()
				this.tl = null

				this.apply_pinchers()

			}
		} )


		this.tl.add(

			gsap.fromTo(
				el,
				0.2,
				{
					scale: this.start_scale.x / this.finish_scale.x,
					x: -1 * ( this.finish_position.x - this.start_position.x ),
					y: -1 * ( this.finish_position.y - this.start_position.y ),
					transformOrigin: "50% 50%"
				},
				{
					scale: 1.0,
					x: 0,
					y: 0,
					transformOrigin: "50% 50%",
					ease: eases.slide_in,
					clearProps: 'all'
				}
			),
			0.0

		)

		this.tl.add(

			gsap.fromTo(
				this.modal_wrap,
				0.2,
				{
					backgroundColor: 'rgba(0.0,0.0,0.0,0.0)'
				},
				{
					backgroundColor: 'rgba(0.0,0.0,0.0,1.0)'
				}
			),
			0.0

		)

		this.tl.play()

	}

	close() {

		// Guard
		if ( !this.valid || this.tl || !this.is_open ) return

		this.is_open = false

		this.calc_boxes()

		if ( this.pinch ) {
			this.pinch.disable()
		}

		let el = ( this.is_video ) ? this.video_to_move : this.content

		this.tl = gsap.timeline({
			paused: true,
			onStart: () => {
				
			},
			onComplete: () => {
			
				if ( this.is_video ) {

					// Video stitch
					this.video_parent.appendChild( this.video_to_move )
					// this.node.style = this.style_cache
				}
				else {

					// Normal stitch
					gsap.set( this.node, { opacity: 1 } )

				}

				this.modal_wrap.classList.remove( 'visible' )

				// remove
				this.modal_parent.innerHTML = ''

				this.tl.kill()
				this.tl = null

				if ( this.pinch ) {
					this.pinch.destroy()
					this.pinch = null
				}

			}
		} )

		this.tl.add(

			gsap.to(
				el,
				0.2,
				{
					scale: this.start_scale.x,
					x: -1 * ( this.finish_position.x - this.start_position.x ),
					y: -1 * ( this.finish_position.y - this.start_position.y ),
					transformOrigin: "50% 50%",
					ease: eases.slide_in,
					clearProps: 'all'
				}
			),
			0.0

		)

		this.tl.add(

			gsap.to(
				this.modal_wrap,
				0.2,
				{
					backgroundColor: 'rgba(0.0,0.0,0.0,0.0)'
				}
			),
			0.0

		)

		this.tl.play()

	}


	apply_pinchers() {

		if ( !this.valid ) return

		if ( this.pinch ) {
			this.pinch.destroy()
			this.pinch = null
		}

		let options = {

			draggableUnzoomed: true,
			horizontalPadding: 0,
			verticalPadding: 0,
			zoomOutFactor: 1.3,
			use2d: false,
			onEscape: () => {
				this.close()
			},
			onDragEnd: () => {
				if ( this.pinch.discard ) {
					this.close()
				}
			},
			onDragUpdate: ()=> {

				gsap.set(
					this.modal_wrap,
					{
						backgroundColor: `rgba(0.0,0.0,0.0,${ this.pinch.discardFactor })`
					}
				)

			}

		}

		// video 
		let el = ( this.is_video ) ? this.video_to_move : this.content

		gsap.set( el, { x: 0, y: 0, scale: 1 } )

		this.pinch = new PinchZoom( el, options )

		// this.pinch.enable()


	}

}