precision highp float;

const float P_NORM = 0.000001;
const float PI = 3.14159265359;

mat4 YUV2RGB = mat4(
	1.16438,  0.00000,  1.59603, -0.87079,
	1.16438, -0.39176, -0.81297,  0.52959,
	1.16438,  2.01723,  0.00000, -1.08139,
	0.0, 0.0, 0.0, 1.0
);


// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - HELPERS


// ANTI-ALIAS STEP helper
float aastep_thick(float threshold, float value, float afwidth) {
    return smoothstep(threshold-afwidth, threshold+afwidth, value);
}

vec2 aastep_thick(vec2 threshold, vec2 value, float afwidth) {
    return smoothstep(threshold-afwidth, threshold+afwidth, value);
}

float normalizer( float lo, float hi, float val ) {
	return clamp( ( val - lo ) / ( hi - lo ), 0.0, 1.0 );
}

// REMAP helper
float remap(
	in float value, in float low1, in float high1, in float low2, in float high2
) {
	return float(low2 + (value - low1) * (high2 - low2) / (high1 - low1));
}

vec2 remap(
	in vec2 value, in vec2 low1, in vec2 high1, in vec2 low2, in vec2 high2
) {
	return vec2(low2 + (value - low1) * (high2 - low2) / (high1 - low1));
}

vec3 remap(
	in vec3 value, in float low1, in float high1, in float low2, in float high2
) {
	return low2 + (value - low1) * (high2 - low2) / (high1 - low1);
}
vec4 remap(
	in vec4 value, in float low1, in float high1, in float low2, in float high2
) {
	return low2 + (value - low1) * (high2 - low2) / (high1 - low1);
}

vec4 dist_factor( vec2 coord, vec2 anchor, vec4 color ) {

	vec4 c = vec4( 0.0 );

	float dist = distance( coord, anchor );

	dist = normalizer( 0.2, 0.7, dist );

	float dist_factor = 1.0 - dist;

	c += ( dist_factor * color );

	return c;

}

float get_theta( vec2 uuvv, vec2 point )
{
  vec2 uv = uuvv - point;
  float is_left = step( 0.0, uv.x );
  float a = 0.5*acos(dot(normalize(uv),normalize(vec2(0.0,1.0))))/PI;
  a = (is_left*a+(1.0-is_left)*(1.0-a));
  return a;
}

// ROTATION helper
mat2 rotate2d(float _angle)
{
    return mat2(cos(_angle),-sin(_angle),sin(_angle),cos(_angle));
}


// Integer Equality Helper
float eq( int i, int j )
{
	return
		step( float( j ) - P_NORM, float( i ) ) *
		step( float( i ), float( j ) + P_NORM );
}

// SCALED sin
float ssin( float val, float min, float max )
{
	return ( max - min ) * ( sin( val ) * 0.5 + 0.5 ) + min;
}

// SCALED cos
float scos( float val, float min, float max )
{
	return ( max - min ) * ( cos( val ) * 0.5 + 0.5 ) + min;
}

float stri( float val, float min, float max )
{
  return abs( 2.0 * fract( val / ( 2.0 * PI ) - P_NORM ) - 1.0 ) * ( max - min ) + min;
}


vec3 hsv_to_rgb( float h, float s, float v )
{

	vec3 rgb = vec3( 0.0 );
	
	float i = float( floor( 0.5 + h * 6.0 ) );
	float f = ( h * 6.0 ) - i;
	float p = float( ( v * ( 1.0 - s ) ) );
	float q = float( ( v * ( 1.0 - s * f ) ) );
	float t = float( ( v * ( 1.0 - s * ( 1.0 - f ) ) ) );

	i = mod( i, 6.0 );

	rgb = 
		eq( int( s ), 0 ) * vec3( v ) +
		eq( int( i ), 0 ) * vec3( v, t, p ) +
		eq( int( i ), 1 ) * vec3( q, v, p ) +
		eq( int( i ), 2 ) * vec3( p, v, t ) +
		eq( int( i ), 3 ) * vec3( p, q, v ) +
		eq( int( i ), 4 ) * vec3( t, p, v ) +
		eq( int( i ), 5 ) * vec3( v, p, q );

	return rgb;

}


//branchless, seamless ?
vec3 hsv2rgb(vec3 c)
{
    vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
    vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
    return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
}


vec3 hsv_to_rgb( vec3 hsv )
{

	float h = hsv.x;
	float s = hsv.y;
	float v = hsv.z;

	vec3 rgb = hsv_to_rgb( h, s, v );
	
	return rgb;

}


vec3 rgb_to_hsv(vec3 color)
{
  vec3 hsl; // init to 0 to avoid warnings ? (and reverse if + remove first part)

  float fmin = min(min(color.r, color.g), color.b);    //Min. value of RGB
  float fmax = max(max(color.r, color.g), color.b);    //Max. value of RGB
  float delta = fmax - fmin;             //Delta RGB value

  hsl.z = (fmax + fmin) / 2.0; // Luminance

  if (delta == 0.0)   //This is a gray, no chroma...
  {
    hsl.x = 0.0;  // Hue
    hsl.y = 0.0;  // Saturation
  }
  else                                    //Chromatic data...
  {
    if (hsl.z < 0.5)
      hsl.y = delta / (fmax + fmin); // Saturation
    else
      hsl.y = delta / (2.0 - fmax - fmin); // Saturation

    float deltaR = (((fmax - color.r) / 6.0) + (delta / 2.0)) / delta;
    float deltaG = (((fmax - color.g) / 6.0) + (delta / 2.0)) / delta;
    float deltaB = (((fmax - color.b) / 6.0) + (delta / 2.0)) / delta;

    if (color.r == fmax )
      hsl.x = deltaB - deltaG; // Hue
    else if (color.g == fmax)
      hsl.x = (1.0 / 3.0) + deltaR - deltaB; // Hue
    else if (color.b == fmax)
      hsl.x = (2.0 / 3.0) + deltaG - deltaR; // Hue

    if (hsl.x < 0.0)
      hsl.x += 1.0; // Hue
    else if (hsl.x > 1.0)
      hsl.x -= 1.0; // Hue
  }

  return hsl;
}

float luma(vec3 color) {
  return dot(color, vec3(0.299, 0.587, 0.114));
}

float luma(vec4 color) {
  return dot(color.rgb, vec3(0.299, 0.587, 0.114));
}



// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - EASES

float cubicInOut(float t) {
  return t < 0.5
    ? 4.0 * t * t * t
    : 0.5 * pow(2.0 * t - 2.0, 3.0) + 1.0;
}

float cubicIn(float t) {
  return t * t * t;
}

float cubicOut(float t) {
  float f = t - 1.0;
  return f * f * f + 1.0;
}

float exponentialInOut(float t) {
  return t == 0.0 || t == 1.0
    ? t
    : t < 0.5
      ? +0.5 * pow(2.0, (20.0 * t) - 10.0)
      : -0.5 * pow(2.0, 10.0 - (t * 20.0)) + 1.0;
}

float exponentialIn(float t) {
  return t == 0.0 ? t : pow(2.0, 10.0 * (t - 1.0));
}

float exponentialOut(float t) {
  return t == 1.0 ? t : 1.0 - pow(2.0, -10.0 * t);
}

float quadraticInOut(float t) {
  float p = 2.0 * t * t;
  return t < 0.5 ? p : -p + (4.0 * t) - 1.0;
}

float quadraticIn(float t) {
  return t * t;
}

float quadraticOut(float t) {
  return -t * (t - 2.0);
}

float circularInOut(float t) {
  return t < 0.5
    ? 0.5 * (1.0 - sqrt(1.0 - 4.0 * t * t))
    : 0.5 * (sqrt((3.0 - 2.0 * t) * (2.0 * t - 1.0)) + 1.0);
}

float circularIn(float t) {
  return 1.0 - sqrt(1.0 - t * t);
}

float circularOut(float t) {
  return sqrt((2.0 - t) * t);
}



// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  COLORS


vec3 color_a = vec3( 0.2901960784313726, 0.2823529411764706, 0.8313725490196079 );
vec3 color_b = vec3( 1.0, 0.8156862745098039, 0.5254901960784314 );
vec3 color_c = vec3( 0.9764705882352941, 0.4392156862745098, 0.2941176470588235 );
vec3 color_d = vec3( 1.0, 0.08627450980392157, 0.396078431372549 );
vec3 color_e = vec3( 0.7686274509803922, 0.4627450980392157, 0.9098039215686274 );

// blue:		#4A48D4
// tan:			#FFD086
// orange:	#F9704B
// pink:		#FF1665
// purple:	#C476E7

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  UNIFORMS

// Animation floats
uniform int u_use_broadway;
uniform float u_border;				// border size in pixels
uniform float u_border_tween;
uniform float u_homepage;
uniform float u_time;
uniform float u_dpr;
uniform float u_curtain;
uniform vec2 u_resolution;
uniform float u_vertical_video;
uniform vec2 u_mouse;
uniform vec2 u_mouse_zip;
uniform vec2 u_mouse_vel;

uniform vec3 u_project_colors[ 10 ];
uniform float u_num_project_colors;
uniform float u_project;
uniform float u_project_scroll_pos;

// Default Decoder
uniform sampler2D u_video;

// Custom Broadway Decoder
uniform sampler2D u_video_y;
uniform sampler2D u_video_u;
uniform sampler2D u_video_v;

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  UNIFORMS

void main() {


	// - - - INIT

	vec4 pixel = vec4( 0.0, 0.0, 0.0, 1.0 );



	// - - - UV helpers

	vec2 uv = gl_FragCoord.xy / ( u_resolution * u_dpr );	// uv baby
	float aspect = u_resolution.y / u_resolution.x;			// screen aspect ratio
	vec2 buv = uv * 2.0 - 1.0;								// bi-unit uv
	vec2 acuv = vec2( uv.x, uv.y * aspect );				// aspect corrected uv
	vec2 bacuv = vec2( buv.x, buv.y * aspect );				// bi-unit aspect corrected uv
	float hypot = pow( 1.0 + pow( aspect, 2.0 ), 0.5 );		// length of diagonal across screen, normalized against width


	// - - - VIDEO BACKGROUND

	// VIDEO background uv, fill width or height
	vec2 video_uv = uv;
	
	// float v_w = mix( 16.0, 9.0, step( 0.5, u_vertical_video ) );
	// float v_h = mix( 9.0, 16.0, step( 0.5, u_vertical_video ) );

	if ( u_vertical_video > 0.5 ) {

		// taller than 16:9, crop width
		if ( ( 16.0 / 9.0 ) < ( u_resolution.y / u_resolution.x ) ) {
			float f = ( u_resolution.x / u_resolution.y ) * ( 16.0 / 9.0 );
			float g = ( 1.0 - f ) * 0.5;
			video_uv.x = remap( video_uv.x, 0.0, 1.0, g, 1.0 - g );
		}
		
		// wider than 16:9, crop height
		else {
			float f = ( u_resolution.y / u_resolution.x ) * ( 9.0 / 16.0 );
			float g = ( 1.0 - f ) * 0.5;
			video_uv.y = remap( video_uv.y, 0.0, 1.0, g, 1.0 - g );
		}

		// video_uv = fract( video_uv );

	}
	else {

		// taller than 16:9, fit height
		if ( ( 9.0 / 16.0 ) < ( u_resolution.y / u_resolution.x ) ) {
			float f = ( u_resolution.x / u_resolution.y ) * ( 9.0 / 16.0 );
			float g = ( 1.0 - f ) * 0.5;
			video_uv.x = remap( video_uv.x, 0.0, 1.0, g, 1.0 - g );
		}
		
		// wider than 16:9, fit width
		else {
			float f = ( u_resolution.y / u_resolution.x ) * ( 16.0 / 9.0 );
			float g = ( 1.0 - f ) * 0.5;
			video_uv.y = remap( video_uv.y, 0.0, 1.0, g, 1.0 - g );
		}


	}
	
	video_uv = clamp( video_uv, 0.0, 1.0 );


	// INIT pixel
	vec4 video_px = vec4( 0.0 );

	// FORK depending on decoder mode
	if ( u_use_broadway > 0 ) {

		// SAMPLE yuv textures
		float y = texture2D( u_video_y, video_uv ).r;
		float u = texture2D( u_video_u, video_uv ).g;
		float v = texture2D( u_video_v, video_uv ).b;

		// CONVERT to rgb
		video_px = vec4( y, u, v, 1.0 ) * YUV2RGB;

	}
	else {

		// SAMPLE video texture
		video_px = texture2D( u_video, video_uv );

	}

	// CLAMP video content to keep UI visible
	video_px.rgb = clamp( video_px.rgb, 0.1, 0.9 );




	// - - - BORDER

	float on_border = max(
		
		max( 
			step( uv.x, ( u_border / u_resolution.x ) ),
			step( 1.0 - uv.x, ( u_border / u_resolution.x ) )
		),
		
		max( 
			step( uv.y, ( u_border / u_resolution.y ) ),
			step( 1.0 - uv.y, ( u_border / u_resolution.y ) )
		)
	
	);

	float on_border_tween = max(
		
		max( 
			step( uv.x, u_border_tween * ( u_border / u_resolution.x ) ),
			step( 1.0 - uv.x, u_border_tween * ( u_border / u_resolution.x ) )
		),
		
		max( 
			step( uv.y, u_border_tween * ( u_border / u_resolution.y ) ),
			step( 1.0 - uv.y, u_border_tween * ( u_border / u_resolution.y ) )
		)
	
	);

	float on_border_edge = max(
		
		max( 
			step( uv.x, ( ( u_border + 2.0 ) / u_resolution.x ) ),
			step( 1.0 - uv.x, ( ( u_border + 2.0 ) / u_resolution.x ) )
		),
		
		max( 
			step( uv.y, ( ( u_border + 2.0 ) / u_resolution.y ) ),
			step( 1.0 - uv.y, ( ( u_border + 2.0 ) / u_resolution.y ) )
		)
	
	) * ( 1.0 - on_border );

	float on_border_edge_tween = on_border_edge * u_border_tween;




	// - - -  HOMEPAGE (and About page) animations

	vec4 hpx = vec4( vec3( 0.0 ), u_homepage );


	const float circ_num = 10.0;
	const int circ_num_i = int( circ_num );

	float speed = -0.2;
	float t = ( u_time * speed );
	float f = fract( t );
	float inner_circ_f = floor( mod( t, circ_num ) );



	float circ_f = 0.0;

	/*
		iterate over 10 levels of lagginess
		smaller circles have higher "index" and appear on top
		bigger circles use laggier mouse positions
	*/

	for ( int lag_i=0; lag_i<circ_num_i; lag_i++ ) {

		// normalized lag blend
		float lag_factor = ( float( lag_i ) + f ) / circ_num;

		// snappy mouse position
		vec2 p1 = vec2( u_mouse_zip.x, u_mouse_zip.y * aspect );

		// lagged mouse position
		vec2 p2 = vec2( u_mouse.x, u_mouse.y * aspect );

		// blend between the snappy and lagged mouse positions
		vec2 p = mix( p2, p1, quadraticOut( pow( lag_factor, 1.25 ) ) );
		
		float d = distance( bacuv, p );

		// normalize distance to point
		d = clamp( d / ( 2.0 * hypot + aspect * 0.5), 0.0, 1.0 );

		float d_raw = d;

		// shape distance curve
		float d_fun = mix(
			pow( exponentialInOut( pow( d_raw, 0.5 ) ), 0.75 ), // + 0.002 * u_mouse_vel.x ),
			pow( exponentialInOut( pow( d_raw, 0.75 ) ), 0.95 ), // - 0.002 * u_mouse_vel.x ),
			// quadraticInOut( ( ssin( -u_time * 0.05 + 0.25 * PI, 0.0, 1.0 ) ) )
			0.0
		);


		float d_d = mix(
				
			mix(
				circularOut( clamp( d_raw * 2.0, 0.0, 1.0 ) ) * 0.5,
				circularIn( clamp( d_raw * 2.0 - 1.0, 0.0, 1.0 ) ) * 0.5 + 0.5,
				step( 0.5, d_raw )
			),

			d_raw,

			0.8

		);



		d = mix(
			d_fun,
			d_d,
			quadraticInOut( ( ssin( -u_time * 0.05 + 0.25 * PI, 0.0, 1.0 ) ) )
		);


		float in_circle = step( 
			( ( circ_num - 1.0 ) * d + f ),
			float( circ_num_i - lag_i )
		);

		float this_index = float( lag_i ) * in_circle;

		in_circle *= eq( lag_i, int( this_index ) );

		circ_f = mix( circ_f, this_index, in_circle );

	}

	// loop the color index stack
	circ_f = mod( inner_circ_f - circ_f, circ_num );

	int circ_i = int( circ_f );

	hpx = vec4(
		(
			eq( circ_i, 0 ) * color_a +
			eq( circ_i, 1 ) * color_b +
			eq( circ_i, 2 ) * color_c +
			eq( circ_i, 3 ) * color_d +
			eq( circ_i, 4 ) * color_e + 
			eq( circ_i, 5 ) * color_a +
			eq( circ_i, 6 ) * color_b +
			eq( circ_i, 7 ) * color_c +
			eq( circ_i, 8 ) * color_d +
			eq( circ_i, 9 ) * color_e
		),
		1.0
	);
	



	// - - - PROJECTS page

	// animate a circle wipe to transition from home to project(s)

	float dd = distance( bacuv, vec2( u_mouse.x, u_mouse.y * aspect ) );
	
	float in_project_circ = aastep_thick( 
		quadraticIn( 1.0 - ( dd / ( hypot * 2.0 ) ) ),
		u_homepage,
		1.0 / u_resolution.x
	);

	video_px = mix( 
		clamp( video_px, 0.0, 1.0 ),
		clamp( video_px + 0.8, 0.0, 1.0 ),
		on_border_edge_tween
	);




	// - - - PROJECT page

	// animate a bunch of colors that move with the scroll

	vec4 project_px = vec4( u_project_colors[ 0 ], 1.0 );

	float in_project_wipe = u_project;

	float p_y = ( ( 1.0 - uv.y ) + u_project_scroll_pos * 0.2 + u_time * 0.04 + ssin( ( u_time * -0.025 + buv.x ) * PI, 0.0, 0.5 ) ) * 2.0;

	int proj_i = int( mod( floor( p_y ), ( u_num_project_colors ) ) );

	project_px.rgb = 
		eq( proj_i, 0 ) * u_project_colors[ 0 ] +
		eq( proj_i, 1 ) * u_project_colors[ 1 ] +
		eq( proj_i, 2 ) * u_project_colors[ 2 ] +
		eq( proj_i, 3 ) * u_project_colors[ 3 ] +
		eq( proj_i, 4 ) * u_project_colors[ 4 ] + 
		eq( proj_i, 5 ) * u_project_colors[ 5 ] +
		// eq( proj_i, 6 ) * u_project_colors[ 6 ] +
		// eq( proj_i, 7 ) * u_project_colors[ 7 ] +
		// eq( proj_i, 8 ) * u_project_colors[ 8 ] +
		// eq( proj_i, 9 ) * u_project_colors[ 9 ] +
		vec3( 0.0 );








	// - - - CURTAIN

	// tween to black so we dont have a hard cut when css blend mode goes away
	// on some pages (project, about)

	vec4 cpx = vec4( 0.0, 0.0, 0.0, 1.0 );

	float in_curtain = 
		// step( 1.0 - uv.y, u_curtain ) * ( 1.0 - on_border );
		u_curtain * ( 1.0 - on_border );

	// BLEND between scenes





	// - - - COMPOSITE

	vec4 pixel_out = mix( 
		video_px,
		hpx,
		in_project_circ
	);


	pixel_out = mix(
		pixel_out,
		project_px,
		in_project_wipe
	);

	pixel_out = mix(
		pixel_out,
		mix( vec4( 1.0 ), pixel_out, on_border_tween ),
		on_border
	);


	pixel_out = mix(
		pixel_out,
		cpx,
		in_curtain
	);








	// - - - OUTPUT

	gl_FragColor = pixel_out;

}