<div class="section projects">

	<div class="page-header projects-header" data-header-replace="true">
		<strong>Projects</strong>
	</div>
	

	<div class="projects-wrap">

		{{#each projects_ordered }}

		<a class="link project-module" data-slug="{{ this.slug }}" data-link="true" href="/project/{{ this.slug }}">

			<p class="project-name">
				<span> {{#with ( lookup ../projects this.slug ) }} {{ projectName }} {{/with}} </span>
			</p>
			
			<p class="project-desc">
				<span>
					{{#with ( lookup ../projects this.slug ) }} {{ projectClient }} {{/with}}
					{{#with ( lookup ../projects this.slug ) }} {{#if projectAgency }}, {{ projectAgency }} {{/if }} {{/with}}
				</span>
			 </p>

			
		</a>
		
		{{/each }}

	</div>


</div>