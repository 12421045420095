import { gsap, Power2 } from 'gsap'

import { nodes } from './../components/nodes.js'

import copy from 'copy-to-clipboard'


export let add_handler = ( el, content ) => {

	// el.setAttribute( 'data-clipboard-text', content )

	el.addEventListener( 'click', ( e ) => {
		
		// vanilla, doesnt work on ios
		// navigator.clipboard.writeText( content )

		copy( content )

		let dims = el.getBoundingClientRect()
		show_tooltip( dims.left + dims.width * 0.5, dims.top )
	
	} )

}

let timer = null

let show_tooltip = ( x, y ) => {

	clearTimeout( timer )

	let offset = nodes.copy_tooltip.getBoundingClientRect()
	offset.width * -0.5
	gsap.set( 
		nodes.copy_tooltip, 
		{ 
			x: x + offset.width * -0.5, 
			y: y - offset.height - 10,
			opacity: 0,
			scaleX: 0.7,
			scaleY: 0.7
		}
	)

	gsap.to(
		nodes.copy_tooltip,
		0.15,
		{
			opacity: 1,
			scaleX: 1,
			scaleY: 1,
			transformOrigin: "50% 100%",
			ease: Power2.easeOut,
			onComplete: () =>{
				timer = setTimeout( hide_tooltip, 500 )
			}
		}
	)

}


let hide_tooltip = () => {

	gsap.to(
		nodes.copy_tooltip,
		0.15,
		{
			opacity: 0,
			scaleX: 0.7,
			scaleY: 0.7,
			transformOrigin: "50% 100%",
			ease: Power2.easeIn,
			onComplete: () => {
				gsap.set( nodes.copy_tooltip, { x: 0, y: 0, opacity: 0 } )
			}
		}
	)

}