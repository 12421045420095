import { gsap, CustomEase, SteppedEase, DrawSVGPlugin, Power0, Power2, Sine } from 'gsap'
gsap.registerPlugin( CustomEase, SteppedEase, DrawSVGPlugin, Power0, Power2, Sine )

import * as nodes from './components/nodes.js'
import * as navigation from './components/navigation.js'
import * as lazyload from './components/lazyload.js'
import * as content from './components/content.js'
import * as loader from './components/loader.js'
import * as controller from './app/controller.js'
import templates from './components/templates.js'

const main = async () => {


	/*console.log(
		'%c env ', 'background: #D865C7; color: #fff',
		{ env: process.env.NODE_ENV }
	)
	*/

	if ( !content.all.loaded ) {

		// Build nav
		nodes.build()
		controller.build()
		navigation.build()
		lazyload.build()

		// Content has not been loaded yet. Go get it!
		let response = await fetch( '/assets/content.json' )
		let data = await response.json()

		content.store( data )

		await loader.start()

		navigation.locate()

		// unhide some hidden nodes during load
		nodes.nodes.focus.modal_wrap.style.removeProperty('display')
		nodes.nodes.overlay.wrap.style.removeProperty('display')

		// load the italics fonts
		// let geograph_ital_400 = new FontFace( 'geograph', 'url(/assets/fonts/geograph/geograph-web-medium-italic.woff2)', { style: 'italic', weight: 500 } )
		// let geograph_ital_500 = new FontFace( 'geograph', 'url(/assets/fonts/geograph/geograph-web-regular-italic.woff2)', { style: 'italic', weight: 400 } )

		// await geograph_ital_400.load()
		// await geograph_ital_500.load()

	}

}

document.addEventListener('DOMContentLoaded', main)