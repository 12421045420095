import * as utils from './../components/utils.js'
import { state, is_mobile, sync_heights, prep_page_header, reset_scroll } from './../app/controller.js'
import { build as nodes_build } from './../components/nodes.js'
import { parse_embedly } from './../components/lazyload.js'
import { build_loader_animations } from './../components/loading-motion.js'
import { nodes as nodes } from './../components/nodes.js'
import * as world from './world.js'
import * as overlay from './overlay.js'
import * as image_modal from './../components/image-modal.js'

import { toggle_blendmode } from './../app/controller.js'


let config = {
	break_out: 720
}

export let current_project = 'home'

export let build = ( content ) => {

	// COLLECT nodes within template
	nodes_build( 'project' )

	// ENSURE blend mode is set to false
	// toggle_blendmode( false )

	if ( !document.body.classList.contains('pre-rendered') ) {
		
		strip_breaks()
		
		// ENSURE video containers have proper wrappers
		parse_embedly()

		// LAYOUT images in horizontal grids
		build_image_grid()

		// ADD loading animations to asset wraps
		build_loader_animations()

		// PREP page header
		prep_page_header()

		let todo_list = utils.qsa( "ul input[type='checkbox']", nodes.project.body_wrap )

		if ( todo_list ) {
			todo_list.forEach( el => {
				el.disabled = false
			} )
		}

	}


	// WE do this alot
	// sync_heights()

	current_project = content.projectSlug

	overlay.set_particle_colors( content.projectColors )

	world.set_project_colors( content.projectColors )

	world.set_video_bounds( current_project )


	image_modal.update( {
		content_wrap: nodes.project.section,
		selector: 'img-wrap',
		project_name: content.projectName
	} )


	if ( !is_mobile ) {

		nodes.project.section.addEventListener( 'mousemove', mousemove_handler, { passive: false } )
	
	}
	else {

		nodes.project.section.addEventListener( 'touchmove', touchmove_handler, { passive: false } )
		nodes.project.section.addEventListener( 'touchstart', touchmove_handler, { passive: false } )
	
	}

	// setInterval( ensure_video_playing, 1000 )

}


let strip_breaks = () => {

	let brs = utils.qsa( 'br' )

	// console.log( brs )
	brs.forEach( b => {
		b.remove()
	} )

}


let mousemove_handler = ( e ) => {

	parse_helper( e.composedPath()[ 0 ] )

}

let touchmove_handler = ( e ) => {

	if ( e.touches[ 0 ] && e.touches[ 0 ].target ) {
		parse_helper( e.touches[ 0 ].target )
	}

}

let parse_helper = ( target ) => {


	let el = utils.walking_class_test( target, 'next-project' )

	if ( el ) {

		world.set_video_bounds( el.dataset['slug'] )
		clear_hovers()
		el.classList.add('hover')

	}
	else {

		world.set_video_bounds( current_project )
		clear_hovers()
		
	}

}

let clear_hovers = () => {

	nodes.project.next_module.classList.remove( 'hover' )

}


export let ensure_video_playing = () => {

	// console.log('ensure')

	let videos = utils.qsa( 'video', nodes.project.body )
	
	videos.forEach( v => {
		// console.log( v.src )
		if ( v.src ) {
			v.play().catch()
		}
	} )

}



// FIND & BUILD all images on a project page, formatted 
export let build_image_grid = () => {

	if ( 
		nodes.project.images === undefined
		// || document.body.classList.contains('pre-rendered')
	) return

	let rows = []

	nodes.project.images.forEach( i => {
		if ( rows.indexOf( i.parentElement ) === -1 ) 
			rows.push( i.parentElement )
	} )

	if ( rows.length > 0 ) {

		rows.forEach( x => {
			x.classList.add('image-container')
			if ( state.window_size.w < config.break_out ) {
				x.style.display = 'block'
				let parent = x.offsetWidth
				let images = Array.prototype.slice.call( x.children ) // includes videos
				images.forEach( img => { 
					let i = img.querySelector('img')
					if ( i ) i.draggable = false
				} )
				let last = rows.indexOf( x ) === rows.length - 1
				remove_height_from_wraps()
				unpack_inline( images, parent, last )

				requestAnimationFrame( () => {
					// apply_height_to_wraps()
				})
			}
			else {
				x.style.display = 'flex'
				let parent = x.offsetWidth
				let images = Array.prototype.slice.call( x.children ) // includes videos
				images.forEach( img => { 
					let i = img.querySelector('img')
					if ( i ) i.draggable = false
				} )
				let last = rows.indexOf( x ) === rows.length - 1
				requestAnimationFrame( () => {
					// remove_height_from_wraps()
					apply_height_to_wraps()
				})
				pack_inline( images, parent, last )
			}
		} )

	}

	nodes.project.ps.forEach( p => {
		let is_text = !( p.classList.contains('video-container') || p.classList.contains('image-container') )
		if ( is_text ) {
			p.classList.add('text-container')
		}
	} )

}



// - - - APPLY height to parents 
/* 
	for clipping - padding remains on image until loading completes, 
	but the img's css height applies immediately
*/

let apply_height_to_wraps = () => {

	let wraps = utils.qsa( '.project-body .image-wrap' )
	wraps.forEach( x => {
		if ( x.children[ 0 ] ) {
			x.style.height = x.children[ 0 ].clientHeight + 'px'
			// console.log( x.children[ 0 ].clientHeight, x.style.height )
		}
	})

	let containers = utils.qsa( '.project-body .image-containers' )
	containers.forEach( x => {
		if ( x.children[ 0 ] ) {
			x.style.height = x.children[ 0 ].children[ 0 ].clientHeight + 'px'
		}
	})

}

let remove_height_from_wraps = () => {

	let wraps = utils.qsa( '.project-body .image-wrap' )
	wraps.forEach( x => {
		x.style.height = 'initial'
	} )

	let containers = utils.qsa( '.project-body .image-container' )
	containers.forEach( x => {
		x.style.height = 'initial'
	} )

}


// PACK set of images into a single row that fills the container width,
// and matches all image heights
let pack_inline = ( images, max, last ) => {

	let gutter = 10
	let gutters = ( images.length - 1 ) * gutter
	let width_ratio = 0

	images.forEach( x => {
		width_ratio += ( x.dataset.width / x.dataset.height )
	} )

	let target_height = ( max - gutters ) / width_ratio

	images[ 0 ].parentElement.style.height = target_height + 'px'

	images.forEach( x => {

		// console.log( x )

		x.style.height = target_height + 'px'

		let target_width = target_height * ( x.dataset.width / x.dataset.height )
		x.style.width = target_width + 'px'
		
		if ( x.children[ 0 ] ) {
			x.children[ 0 ].style.paddingBottom = 0
		}
		// console.log( x.children[ 0 ] )

		if ( images.indexOf( x ) === images.length - 1 ) {
			x.style.margin = '0 0 0 0'
		}
		else {
			x.style.margin = `0 ${ gutter }px 0 0`
		}

	} )

	if ( last ) {
		sync_heights()
	}

}

// CLEAR a previously packed set of inline images, and break out each image
// into a single column, for mobile-sized screens
let unpack_inline = ( images, max, last ) => {

	images.forEach( x => {

		let target_height = max / ( x.dataset.width / x.dataset.height )
		x.style.height = target_height + 'px'
		x.style.width = '100%'
		x.style.margin = '0 0 5px 0'

		x.children[ 0 ].style.paddingBottom = 0

	} )


	// need to remove height from parent
	// images[ 0 ].parentElement.style.height = target_height + 'px'



	requestAnimationFrame( () => {
		// let total = -5
		// let margin = 5

		// images.forEach( x => {
		// 	total += x.clientHeight + 5
		// } )

		// images[ 0 ].parentElement.style.height = total + 'px'
		// console.log( )
	})

	if ( last ) {
		sync_heights()
	}


}