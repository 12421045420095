import * as content from './content.js'
import { build as home_build } from './../sections/home.js'
import { build as projects_build } from './../sections/projects.js'
import { build as project_build } from './../sections/project.js'
import { build as about_build } from './../sections/about.js'


// Update this with all category template distributions
export let data_tree = {
	'home': {
		template: 'home',
		data: 'home'
	},
	'projects': {
		template: 'projects',
		data: null
	},
	'project': {
		template: 'project',
		data: 'projects'
	},
	'about': {
		template: 'about',
		data: 'home'
	}
}


// Update this with content structure
export let tree = [
	[ 'home', 'projects', 'about' ],
	[ 'project' ]
]

export let section_builders = {
	'home': home_build,
	'projects': projects_build,
	'project': project_build,
	'about': about_build
}


// Helpers be helpin'

export let get_template = ( path ) => {
	return ( data_tree[ path ] ) 
		? data_tree[ path ].template
		: '404'
}

export let get_data = ( path, query ) => {

	// DOES not exist in data tree
	if ( data_tree[ path ] === undefined ) {
		return content.all
	}

	let _path = data_tree[ path ].data

	let data = content.get( _path, query )

	if ( query ) {
		data.title = query
	}

	return data

}