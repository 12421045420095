import { copy_obj } from './utils.js' 

export let all = { 
	loaded: false
}


export let get = ( key, query ) => {

	if ( key === null ) {

		return all.content

	}
	else if ( query === undefined ) {

		return copy_obj( all.content[ key ] )

	}
	else {

		return copy_obj( all.content[ key ][ query ] )

	}

}


// - - - - - - -  - - - - - - - - - - - - - - - - - - ORGANIZE incoming content

export let store = ( data ) => {

	all = data

	all.loaded = true

	/*
	console.log( 
		'%c content ', 'background: #06C7B3; color: #fff', 
		all
	)
	*/

}